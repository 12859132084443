import {
  Component,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnChanges,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Screen } from '@core/models';
import { FilesService } from '@core/services';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsListComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  // Actions
  @Input() editDocument = false;
  @Input() detailsDocument = false;
  @Input() deleteDocument = false;
  @Input() downloadFile = false;

  @Input() screen: Screen;
  @Input() displayedColumns: string[] = [];
  @Input() documents: any;
  @Input() selectedDocument: any;
  @Input() selectedFiles: Set<string>;
  @Input() pageSize: number;

  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
  @Output() selectItem: EventEmitter<any> = new EventEmitter();

  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() details: EventEmitter<any> = new EventEmitter();
  @Output() download: EventEmitter<any> = new EventEmitter();
  @Output() stream: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  // HTML Elements for Table Pagination and Sorting
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$: Subject<boolean> = new Subject<boolean>();

  dataSource = new MatTableDataSource<any>();
  selection: SelectionModel<string> = new SelectionModel<string>(true, []);

  constructor(private fileService: FilesService) {}

  ngOnChanges() {
    if (this.documents) {
      this.dataSource.data = this.documents;
    }
    if (this.selectedFiles.size > 0) {
      this.selectedFiles.forEach((file) => this.selection.select(file));
    } else {
      this.selection.clear();
    }
  }

  // Use AfterViewInit to wire up <mat-table>
  ngAfterViewInit() {
    // Configure sortingDataAccessor because
    this.dataSource.sortingDataAccessor = (row, property) => {
      switch (property) {
        case 'docTypeName': {
          return row.type_name;
        }
        case 'displayName': {
          return row.display_name;
        }
        case 'fileSize': {
          return row.file_size;
        }
        case 'createdAt': {
          return row.created_at;
        }
        default:
          return row[property];
      }
    };
    this.dataSource.paginator = this.paginator;
    this.paginator.page
      .pipe(
        takeUntil(this.destroy$),
        map((val) => {
          if (val.pageSize !== this.pageSize) {
            this.pageSizeChanged.emit(val.pageSize);
          }
        })
      )
      .subscribe();
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isAnySelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach((row) => this.deselect(row));
    } else {
      this.dataSource.data.forEach((row) => {
        if (row.file_type === 'application/pdf') {
          this.select(row);
        }
      });
    }
  }

  toggle(row) {
    if (this.selection.isSelected(row.id)) {
      this.deselect(row);
    } else {
      this.select(row);
    }
  }

  select(row) {
    this.selection.select(row.id);
    this.selectedFiles.add(row.id);
  }

  deselect(row) {
    this.selection.deselect(row.id);
    this.selectedFiles.delete(row.id);
  }

  /**
   * Convert the document fileType to an <mat-icon> icon string
   */
  documentIcon(doc: any): string {
    let icon = 'insert_drive_file';
    if (typeof doc.file_type !== 'undefined') {
      if (doc.file_type.includes('pdf')) {
        icon = 'picture_as_pdf';
      } else if (doc.file_type.includes('image')) {
        icon = 'photo';
      }
    }
    return icon;
  }

  getDownloadCount(doc: any): number {
    return doc &&
      doc.download_histories_aggregate &&
      doc.download_histories_aggregate.aggregate.count
      ? doc.download_histories_aggregate.aggregate.count
      : null;
  }

  getDownloadCountToolTip(doc: any): string {
    let rc = null;
    const count = this.getDownloadCount(doc);
    if (count) {
      rc = `Downloaded ${count} ${count > 1 ? 'times' : 'time'}`;
    }
    return rc;
  }

  getTotalSize() {
    return this.dataSource.data
      .map((row) => row.file_size)
      .filter((amount) => !isNaN(amount))
      .reduce((acc, value) => acc + value, 0);
  }

  fileUrl(document, action) {
    if (document && document.id) {
      return this.fileService.getFileUrl(document.id, action);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
