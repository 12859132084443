import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './+state/preferences.reducer';
import { PreferencesEffects } from './+state/preferences.effects';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('preferences', reducer),
    EffectsModule.forFeature([PreferencesEffects])
  ]
})
export class PreferencesModule {}
