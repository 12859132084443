<h1 class="mat-h1">Transaction Information</h1>
<div class="form-container">
  <form [formGroup]="transactionForm">
    <mat-card>
      <mat-card-title> Transaction Details </mat-card-title>
      <mat-card-content class="flex-container">
        <mat-form-field class="item">
          <input
            matInput
            placeholder="Company"
            formControlName="company"
            [readonly]="isDetails"
          />
        </mat-form-field>
        <mat-form-field *ngIf="!isDetails" class="item">
          <mat-select
            placeholder="Payment Type"
            formControlName="type"
            (selectionChange)="setTypeName($event)"
            required
          >
            <mat-option
              *ngFor="let txType of transactionTypes"
              [value]="txType.id.toString()"
              [disabled]="isDetails"
              >{{ txType.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isDetails" class="item">
          <input
            matInput
            placeholder="Payment Type"
            formControlName="type_name"
            [readonly]="isDetails"
          />
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <a
          *ngIf="deleteTransaction"
          type="button"
          mat-icon-button
          color="warn"
          matTooltip="Delete"
          (click)="delete.emit(transaction)"
        >
          <mat-icon>delete</mat-icon>
        </a></mat-card-actions
      >
    </mat-card>
    <mat-card>
      <mat-card-title>Financial Information </mat-card-title>
      <mat-card-content class="form-container">
        <mat-form-field class="item">
          <input
            matInput
            placeholder="Description"
            formControlName="description"
            [readonly]="isDetails"
          />
        </mat-form-field>
        <div class="flex-container">
          <mat-form-field class="item-amount">
            <input
              matInput
              placeholder="Payment"
              formControlName="payment"
              [readonly]="isDetails"
            />
            <span matSuffix>$ &nbsp;</span>
            <mat-error
              *ngIf="
                transactionForm.get('payment').invalid &&
                !transactionForm.get('payment').pristine &&
                transactionForm.get('payment').touched
              "
              >Please enter an amount.</mat-error
            >
          </mat-form-field>
          <mat-form-field class="item-date">
            <input
              matInput
              formControlName="check_date"
              [matDatepicker]="checkDate"
              placeholder="Check Date"
              [readonly]="isDetails"
              dateMask
            />
            <mat-datepicker-toggle
              *ngIf="!isDetails"
              matSuffix
              [for]="checkDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #checkDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="item-check-number">
            <input
              matInput
              placeholder="Check Number"
              formControlName="check_number"
              [readonly]="isDetails"
            />
          </mat-form-field>
          <mat-checkbox formControlName="final">Final</mat-checkbox>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>

<button
  *ngIf="isEdit"
  mat-stroked-button
  type="button"
  color="primary"
  (click)="update.emit(transactionForm.value)"
  routerLink="/clients"
  [disabled]="!transactionForm.valid"
>
  SAVE
</button>
<button
  *ngIf="!isDetails"
  mat-stroked-button
  type="button"
  (click)="cancel.emit()"
>
  CANCEL
</button>
<button
  *ngIf="isDetails"
  mat-stroked-button
  type="button"
  (click)="cancel.emit()"
>
  BACK
</button>

<!-- <br />Client ID: {{ clientId }} <br />Transaction ID: {{ transactionId }} -->
<!-- <h2>Documents</h2> -->
<!-- <pre>{{ transactionDocuments | json }}</pre> -->

<!-- <h2>Transaction</h2> -->
<!-- <pre>{{ transaction | json }}</pre> -->

<!-- <h2>Transaction Form</h2> -->
<!-- Dirty:{{ transactionForm.dirty }} Valid:{{ transactionForm.valid }} -->
<!-- <pre>{{ transactionForm.value | json }}</pre> -->
