import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  AfterViewInit,
  OnDestroy, OnInit, HostListener ,
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil, map} from 'rxjs/operators';
import {MatSort, Sort} from '@angular/material/sort';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Screen} from '@core/models';
import {FilterService, PrimeNGConfig, LazyLoadEvent} from 'primeng/api';
import {ColumnFilter, Table} from 'primeng/table';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsListComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  constructor(private config: PrimeNGConfig, private filterService: FilterService) {
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

  @ViewChild('dt') dt: Table;

  destroy$: Subject<boolean> = new Subject<boolean>();

  // Actions
  @Input() clientEdit: boolean;
  @Input() clientDetails: boolean;
  @Input() clientShare: boolean;
  @Input() clientRefresh: boolean;
  @Input() clientDelete: boolean;

  @Input() isClientMerge: boolean;
  @Input() selectedMergeClients: any;

  @Input() screen: Screen;
  @Input() themeName: any;
  @Input() displayedColumns = [];
  @Input() clients: any[] = [];
  @Input() selectedClient: any = null;

  @Input() pageSize: number;
  @Input() clientsListTableInformation: PageEvent = null;
  @Input() clientsListTableSort: Sort = null;
  @Input() count = 0;

  @Input() roles = [];
  @Input() selectCaseStatuses: any;
  @Input() providersList: any;
  @Input() sharedOrganizationList: any;

  @Input() isProviderParent: any;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() details: EventEmitter<any> = new EventEmitter();
  @Output() share: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() tableInformation: EventEmitter<PageEvent> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
  @Output() tableSort: EventEmitter<Sort> = new EventEmitter();
  @Output() import: EventEmitter<void> = new EventEmitter();
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() selectMergeClient: EventEmitter<{ value, data }> = new EventEmitter();
  @Output() merge: EventEmitter<any> = new EventEmitter();
  @Output() cancelMerge: EventEmitter<any> = new EventEmitter();
  @Output() updateStatus: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Output() ngSort: EventEmitter<any> = new EventEmitter();
  @Input() resetFilters: EventEmitter<any> = new EventEmitter();


  dataSource = new MatTableDataSource<any>();
  isLazyLoad = true;
  lazyListening = true;

  finalizationRequest = 'Finalization Request';
  noLongerRepresented = 'No Longer Represented';
  closedNoRecovery = 'Closed – No Recovery';
  reductionNeeded = 'Reduction Needed';
  tooltipText = '';
  lightTheme = 'app-theme-light';
  darkTheme = 'app-theme-dark';

  filterDateOfBirth: Date;
  filterDateOfInjury: Date;

  // Detect click event anywhere on the page
  @HostListener('document:click', ['$event']) documentClick(event: MouseEvent) {
  }


  ngOnChanges() {
    if (this.clients) {
      this.dataSource.data = this.clients;
    }
    // this.showProviderColumn();
    // display statuses alert column
    this.showCaseStatusAlert();
    if (this.isClientMerge) {
      this.mergeSelection();
    } else {
      this.cancelMergeSelection();
    }
    // add Need Case Update in case status column filter's dropdown menu
    this.addNeedCaseUpdate();
  }

  mergeSelection() {
    if (this.displayedColumns && !this.displayedColumns.includes('check')) {
      const displayedColumn = [...this.displayedColumns];
      displayedColumn.unshift('check');
      this.displayedColumns = displayedColumn;
    }
  }

  cancelMergeSelection() {
    if (this.displayedColumns && this.displayedColumns.includes('check')) {
      let displayedColumn = [...this.displayedColumns];
      displayedColumn = displayedColumn.filter(c => c !== 'check');
      this.displayedColumns = displayedColumn;
    }
  }

  showProviderColumn() {
    // If logged in as attorney then show Provider column on the Clients List
    if (this.roles?.includes('user_attorney') &&
      this.displayedColumns &&
      !this.displayedColumns.includes('provider')) {
      const displayedColumn = [...this.displayedColumns];
      const index = displayedColumn.indexOf('sharedOrganizations');
      displayedColumn.splice(index, 1, 'provider');
      this.displayedColumns = [...displayedColumn];
    }
  }

  showCaseStatusAlert() {
    if (this.displayedColumns && this.displayedColumns.includes('statuses') &&
      this.roles.length > 0) {
      const displayedColumn = [...this.displayedColumns];
      if (this.roles.includes('user_attorney') &&
        !this.displayedColumns.includes('attorney-status')) {
        displayedColumn.splice((this.displayedColumns.length - 1), 0, 'attorney-status');
      }
      if (!this.roles.includes('user_attorney') &&
        !displayedColumn.includes('provider-status')) {
        displayedColumn.unshift('provider-status');
      }
      this.displayedColumns = [...displayedColumn];
    }
  }

  // add Need Case Update field in case status column filter's menu
  addNeedCaseUpdate() {
    if (this.roles.includes('user_attorney') && this.selectCaseStatuses) {
      // tslint:disable-next-line:variable-name
      const _statuses = [...this.selectCaseStatuses];
      if (_statuses && !(_statuses.find( v => v.value === 'Need Case Update'))) {
        _statuses.unshift({id: '', value: 'Need Case Update'});
      }
      this.selectCaseStatuses = [..._statuses];
    }
  }

  ngOnInit(): void {
    this.resetFilters.subscribe(() => {
      this.clearFilters();
    });
  }


  ngAfterViewInit() {
    // if (this.sort) {
    //   this.sort.sortChange
    //     .pipe(
    //       takeUntil(this.destroy$),
    //       map((sortInformation) => this.tableSort.emit(sortInformation))
    //     )
    //     .subscribe();
    //   this.paginator.page
    //     .pipe(
    //       takeUntil(this.destroy$),
    //       map((val) => {
    //         if (val.pageSize !== this.clientsListTableInformation.pageSize) {
    //           this.pageSizeChanged.emit(val.pageSize);
    //         }
    //         this.tableInformation.emit(val);
    //       })
    //     )
    //     .subscribe();
    // }
  }

  paginate(event) {
    const val = {
      length: this.count,
      pageIndex: event.page,
      pageSize: event.rows,
      previousPageIndex: event.first,
    };
    if (val.pageSize !== this.clientsListTableInformation.pageSize) {
      this.pageSizeChanged.emit(val.pageSize);
    }
    this.tableInformation.emit(val);
  }

  onChangeStatus(clientId, statudId) {
    const input = {client_id: clientId, status_id: statudId};
    this.updateStatus.emit(input);
  }

  getTooltipText(value, attorney) {
    switch (value) {
      case null :
      case '' : {
        // Set tooltip text when case status value is null.
        attorney ?
          this.tooltipText =
            'We will request an update every 120 days,  however, you are free to update statuses at any time!' :
          this.tooltipText =
            'We request a case update from the law firm every 120 days.';
        break;
      }
      case this.finalizationRequest: {
        // Set tooltip text when case status value is Finalization Request.
        attorney ?
          this.tooltipText =
            'We\'ll send an email to the provider letting them know you need all bills and records uploaded to ShareScape' :
          this.tooltipText =
            'The law firm needs all bills and records uploaded to ShareScape as soon as possible!';
        break;
      }
      case this.reductionNeeded: {
        // Set tooltip text when case status value is Reduction Needed.
        attorney ?
          this.tooltipText =
            'Please reach out to the medical provider to discuss the reduction needed' :
          this.tooltipText =
            'Please reach out to the law firm to discuss the reduction needed';
        break;
      }
      default: {
        this.tooltipText = value;
        break;
      }
    }
  }

  filterCallbackFun(filter: (a) => void, value: any, col: any) {
    this.lazyListening = false;
    filter(value);
    this.lazyListening = true;
  }

  loadLazy(event: LazyLoadEvent) {
    const filters = {...event.filters};
    if (!this.lazyListening) {
      return;
    }
    if (this.dt) {
      const field = event.sortField;
      const order = event.sortOrder === -1 ? 'desc' : 'asc';
      const value = {field, order};
      this.isLazyLoad = false;
      setTimeout(() => {
        this.isLazyLoad = true;
        this.filter.emit(filters);
        this.ngSort.emit(value);
      }, 0);
    }
  }

  clearFilters() {
    this.dt.reset();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
