import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
  Form,
  ValidationErrors,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreFacade } from '@core/+state';
import {AppFacade} from '@app/+state';

@Component({
  selector: 'app-refer-patient',
  template: `
    <form class="share-form" [formGroup]="form">
      <div mat-dialog-title>
        <!--        <mat-icon class="close" (click)="cancel()">close</mat-icon>-->
        <span class="title">
          <h2>Refer a Patient to a Medical Provider</h2>
        </span>
      </div>
      <mat-dialog-content class="content">
        <mat-form-field appearance="outline" class="form-width">
          <mat-label>Patient's Full Name</mat-label>
          <input matInput placeholder="" formControlName="contact_full_name" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-width">
          <mat-label>Patient's Phone Number</mat-label>
          <input matInput placeholder=""
                 phoneMask [preValue]="form.get('contact_phone_number').value"
                 maxlength="14"
                 formControlName="contact_phone_number">
        </mat-form-field>

        <mat-form-field class="form-width">
          <mat-label>Where does this patient need to be seen?</mat-label>
          <input
            matInput
            placeholder=""
            [(ngModel)]="searchControl"
            (input)="searchControlChange($event.target.value)"
            [ngModelOptions]="{ standalone: true }"
            [matAutocomplete]="autoSearch"
          />
          <mat-icon matSuffix *ngIf="(app.loading$ | async) === true">
            <mat-spinner [diameter]="20" style="margin-top: 5px"></mat-spinner>
          </mat-icon>
          <mat-icon matSuffix *ngIf="(app.loading$ | async) === false">
              search
          </mat-icon>
        </mat-form-field>

        <mat-autocomplete autoActiveFirstOption #autoSearch="matAutocomplete">
          <mat-option
            *ngFor="let org of core.searchOrganization$ | async"
            [value]="org.name"
            (onSelectionChange)="selectProvider(org)"
          >
            {{ org.name }}
          </mat-option>
          <mat-option
            (click)="
              newProvider('The provider I\\'m looking for is not listed')
            ">
            The provider I'm looking for is not listed
          </mat-option>
        </mat-autocomplete>

        <div formGroupName="provider_info" *ngIf="isNew" class="suggest">
          <span>Tell us about who you'd like to see on ShareScape:</span>
          <mat-form-field appearance="outline" class="form-width">
            <mat-label>Office / Facility Name</mat-label>
            <input matInput placeholder="" formControlName="faculty_name" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-width">
            <mat-label
              >Is there someone specific we can reach out to?
              (Optional)</mat-label
            >
            <input matInput placeholder="" formControlName="contact_name" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-width">
            <mat-label>Facility / Contact Email or Phone</mat-label>
            <input matInput placeholder="" formControlName="contact_info" />
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="form-width">
          <mat-label>Anything else we should know?</mat-label>
          <textarea
            rows="5"
            matInput
            placeholder=""
            formControlName="additional_info"
          ></textarea>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions style=" display: block;">
        <button
          [disabled]="form.invalid || !validSearch"
          mat-stroked-button
          color="primary"
          type="button"
          (click)="send()"
        >
          Submit
        </button>
        <button
          class="close"
          mat-stroked-button
          color="warn"
          type="button"
          (click)="cancel()"
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  `,
  styles: [
    `
      .share-form {
        min-width: 150px;
        max-width: 500px;
        width: 100%;
      }

      .form-width {
        width: 100%;
      }

      .close {
        cursor: pointer;
        float: right;
      }

      .title h2 {
        font-size: 18px;
        text-align: center;
      }

      .content {
        margin-top: 50px;
      }

      mat-selection-list {
        /*border-top: 2px solid red;*/
        /*border-left: 1px solid rgb(211,211,211);*/
        /*border-right: 1px solid rgb(211,211,211);*/
        /*border-bottom: 1px solid rgb(211,211,211);*/
      }

      .searchBox {
        border-top: 2px solid lightgray;
        border-left: 1px solid rgb(211, 211, 211, 0.3);
        border-right: 1px solid rgb(211, 211, 211, 0.3);
        border-bottom: 1px solid rgb(211, 211, 211, 0.3);
        margin-bottom: 5px;
        opacity: 0.7;
      }

      .suggest {
        text-align: center;
        opacity: 0.7;
      }
    `,
  ],
})
export class ReferPatientComponent implements OnInit, AfterViewInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  form: FormGroup;
  searchControl: any;
  isNew = false;
  debounce: any;
  validSearch = false;

  constructor(
    public dialogRef: MatDialogRef<ReferPatientComponent>,
    private fb: FormBuilder,
    public core: CoreFacade,
    public app: AppFacade
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      contact_full_name: ['', [Validators.required]],
      contact_phone_number: ['', [Validators.required, Validators.minLength(14)]],
      provider_id: [''],
      provider_info: this.fb.group({
        faculty_name: [''],
        contact_name: [''],
        contact_info: [''],
      }),
      additional_info: [''],
    });
  }

  ngAfterViewInit() {}

  searchControlChange(search) {
    this.validSearch = false;
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.core.searchOrganization({ search });
    }, 500);
  }

  get shareInviteData() {
    const data = { ...this.form.value };
    if (data.provider_id !== '') {
      data.provider_info = {};
    }
    return data;
  }

  cancel() {
    this.core.resetSearchResult(null);
    this.dialogRef.close(false);
  }

  send() {
    this.dialogRef.close(this.shareInviteData);
  }

  selectProvider(org) {
    this.form.patchValue({ provider_id: org.id });
    this.changeTab(false, org.name);
  }

  newProvider(value) {
    this.changeTab(true, value);
  }

  changeTab(isNew, searchValue) {
    this.isNew = isNew;
    this.searchControl = searchValue;
    this.validSearch = true;
    this.core.resetSearchResult(null);
    this.validations();
  }

  validations() {
    if (this.isNew) {
      this.form
        .get('provider_info.faculty_name')
        .setValidators([Validators.required]);
      this.form
        .get('provider_info.contact_info')
        .setValidators([Validators.required]);
    } else {
      this.form.get('provider_info.faculty_name').setValidators(null);
      this.form.get('provider_info.contact_info').setValidators(null);
    }
    this.form.get('provider_info.faculty_name').updateValueAndValidity();
    this.form.get('provider_info.contact_info').updateValueAndValidity();
  }
}
