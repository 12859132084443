import { createAction, props } from '@ngrx/store';

export const setLoading = createAction(
  '[App] Set Loading',
  props<{ loading: boolean }>()
);
export const setCompilingPDFs = createAction(
  '[App] Set Compiling PDFs',
  props<{ compilingPDFs: boolean }>()
);
export const setEnvironment = createAction(
  '[App] Set Environment',
  props<{ environment: string }>()
);

export const loadUICodesSuccess = createAction(
  '[App] Load UI Codes Success',
  props<{ response: any }>()
);

export const loadUICodesError = createAction(
  '[App] Load UI Codes Error',
  props<{ error: any }>()
);

export const setRedirectURL = createAction(
  '[App] Set Redirect URL',
  props<{ redirectURL: string }>()
);
export const clearState = createAction('[App] Clear State');
export const resetStore = createAction('[App] Reset Store');
