import { createAction, props } from '@ngrx/store';

export const createOrganizationPhoneNumber = createAction(
  '[Phone Numbers] Create Organization Phone Number',
  props<{ input: any }>()
);

export const createOrganizationPhoneNumberSuccess = createAction(
  '[Phone Numbers] Create Organization Phone Number Success',
  props<{ mutation: any }>()
);
export const createOrganizationPhoneNumberError = createAction(
  '[Phone Numbers] Create Organization Phone Number Error',
  props<{ error: any }>()
);

export const createClientPhoneNumber = createAction(
  '[Phone Numbers] Create Client Phone Number',
  props<{ input: any }>()
);

export const createClientPhoneNumberSuccess = createAction(
  '[Phone Numbers] Create Client Phone Number Success',
  props<{ mutation: any }>()
);
export const createClientPhoneNumberError = createAction(
  '[Phone Numbers] Create Client Phone Number Error',
  props<{ error: any }>()
);

export const createUserPhoneNumber = createAction(
  '[Phone Numbers] Create User Phone Number',
  props<{ input: any }>()
);

export const createUserPhoneNumberSuccess = createAction(
  '[Phone Numbers] Create User Phone Number Success',
  props<{ mutation: any }>()
);
export const createUserPhoneNumberError = createAction(
  '[Phone Numbers] Create User Phone Number Error',
  props<{ error: any }>()
);

export const updatePhoneNumber = createAction(
  '[Phone Numbers] Update Phone Number',
  props<{ input: any }>()
);

export const updatePhoneNumberSuccess = createAction(
  '[Phone Numbers] Update Phone Number Success',
  props<{ mutation: any }>()
);
export const updatePhoneNumberError = createAction(
  '[Phone Numbers] Update Phone Number Error',
  props<{ error: any }>()
);

export const deletePhoneNumber = createAction(
  '[Phone Numbers] Delete Phone Number',
  props<{ input: any }>()
);
export const deletePhoneNumberSuccess = createAction(
  '[Phone Numbers] Delete Phone Number Success',
  props<{ mutation: any }>()
);
export const deletePhoneNumberError = createAction(
  '[Phone Numbers] Delete Phone Number Error',
  props<{ error: any }>()
);
