import {
  Component,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnChanges,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { Screen } from '@core/models';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent implements AfterViewInit, OnChanges, OnDestroy {
  // Actions
  @Input() editUser = false;
  @Input() detailsUser = false;
  @Input() contactUser = false;

  @Input() screen: Screen;
  @Input() displayedColumns: Array<string>;
  @Input() users: any;
  @Input() selectedUser: any = null;
  @Input() pageSize: number;
  @Input() usersListTableSort: Sort = null;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() details: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() contact: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
  @Output() tableSort: EventEmitter<Sort> = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();

  // HTML Elements for Table Pagination and Sorting
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<any>();

  ngOnChanges() {
    if (this.users) {
      this.dataSource.data = this.users;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'organizations':
          return item.organization_users.length > 0
            ? item.organization_users[0].organization.name
            : '';
        case 'firstName':
          return item.first_name;
        case 'lastName':
          return item.last_name;
        case 'lastLogin':
          return item.last_login;
        case 'tier':
          return item.tier[0].group.description;
        case 'permissions':
          return item.user_configurations_aggregate.aggregate.count > 0
            ? 'Overridden'
            : 'Default';
        default:
          return item[property];
      }
    };

    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.sortChange
        .pipe(
          takeUntil(this.destroy$),
          map((sortInformation) => this.tableSort.emit(sortInformation))
        )
        .subscribe();
    }
    this.dataSource.paginator = this.paginator;
    this.paginator.page
      .pipe(
        takeUntil(this.destroy$),
        map((val) => {
          if (val.pageSize !== this.pageSize) {
            this.pageSizeChanged.emit(val.pageSize);
          }
        })
      )
      .subscribe();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
