<mat-vertical-stepper #stepper>
  <mat-step [stepControl]="typeFormGroup">
    <form [formGroup]="typeFormGroup">
      <ng-template matStepLabel>Choose transaction type</ng-template>
      <div class="form-group">
        <mat-form-field class="item">
          <input
            matInput
            formControlName="company"
            placeholder="Company Name"
          />
        </mat-form-field>
        <mat-form-field class="item">
          <mat-select
            placeholder="Payment Type"
            formControlName="type"
            (selectionChange)="setTypeName($event)"
            required
          >
            <mat-option
              *ngFor="let txType of transactionTypes"
              [value]="txType.id.toString()"
              >{{ txType.name }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              typeFormGroup.get('type').invalid &&
              !typeFormGroup.get('type').pristine &&
              typeFormGroup.get('type').touched
            "
            >Payment type is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div>
        <button mat-button color="primary" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="detailsFormGroup">
    <form [formGroup]="detailsFormGroup">
      <ng-template matStepLabel>Fill out transaction details</ng-template>
      <div class="form-group">
        <mat-form-field class="item">
          <input
            matInput
            placeholder="Description"
            formControlName="description"
          />
        </mat-form-field>
        <mat-form-field class="item-date">
          <input
            matInput
            formControlName="check_date"
            [matDatepicker]="checkDate"
            placeholder="Check Date"
            [readonly]="false"
            dateMask
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="checkDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #checkDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="item-date">
          <input
            matInput
            formControlName="date_posted"
            [matDatepicker]="datePosted"
            placeholder="Date Posted"
            [readonly]="false"
            dateMask
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datePosted"
          ></mat-datepicker-toggle>
          <mat-datepicker #datePosted></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="item-120px">
          <input
            matInput
            placeholder="Check Number"
            formControlName="check_number"
          />
        </mat-form-field>
        <mat-form-field class="item-120px">
          <input matInput placeholder="Payment" formControlName="payment" />
          <span matSuffix>$ &nbsp;</span>
          <mat-error
            *ngIf="
              detailsFormGroup.get('payment').invalid &&
              !detailsFormGroup.get('payment').pristine &&
              detailsFormGroup.get('payment').touched
            "
            >Please enter an amount.</mat-error
          >
        </mat-form-field>
        <div class="item-checkbox">
          <mat-checkbox formControlName="final">Final</mat-checkbox>
        </div>
      </div>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button color="primary" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <p>Add a Transaction.</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button color="warn" (click)="stepper.reset()">Reset</button>
      <button
        mat-button
        color="primary"
        matStepperPrevious
        (click)="done()"
        [disabled]="typeFormGroup.invalid || detailsFormGroup.invalid"
      >
        Add
      </button>
    </div>
  </mat-step>
</mat-vertical-stepper>
<button mat-stroked-button type="button" (click)="cancel.emit()">CANCEL</button>
