<app-profile-ui
  [roles]="auth.roles$ | async"
  [user]="profile.profile$ | async"
  [phoneTypes]="app.phoneTypes$ | async"
  [statusUpdate]="preferences.getPreferenceByType$('ClientStatusUpdate') | async"
  (update)="updateProfile($event)"
  (phoneNumberCreate)="phoneNumberCreate($event)"
  (phoneNumberUpdate)="phoneNumberUpdate($event)"
  (phoneNumberDelete)="phoneNumberDelete($event)"
  (caseUpdateAlertsToggle)="caseUpdateAlertsToggle($event)"
></app-profile-ui>
