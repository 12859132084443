<div *ngIf="core.screen$ | async as screen">
  <div mat-dialog-title>
    {{ screen.device === 'handset' ? data.titleShort : data.title }}
  </div>
  <div mat-dialog-content>
    <app-eula-verbiage></app-eula-verbiage>
  </div>
  <div mat-dialog-actions class="footer">
    <button mat-stroked-button (click)="accept()">ACCEPT</button>
  </div>
</div>
