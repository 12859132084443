import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as fromActions from './configuration.actions';
import { Theme } from '@core/models';

export const preferencesAdapter = createEntityAdapter<any>();
export interface State extends EntityState<any> {}

const themes: Array<Theme> = [
  {
    name: 'app-theme-light',
    primary: '',
    primaryText: '',
    accent: '',
    accentText: '',
    isDark: false,
  },
  {
    name: 'app-theme-dark',
    primary: '',
    primaryText: '',
    accent: '',
    accentText: '',
    isDark: false,
  },
];

export const initialState: State = preferencesAdapter.getInitialState({});

const configurationReducer = createReducer(
  initialState,
  on(fromActions.loadSuccess, (state, { configurations }) => {
    // Merge provider and user configuration overrides with the default configurations.
    // Start with the default configurations.
    let userConfigurations = configurations.default.map((d) => ({
      ...d,
      __typename: 'default_configuration',
    }));
    userConfigurations.push({
      type: 'Themes',
      body: themes,
      __typename: 'default_configuration',
    });

    // Loop over the provider configurations if they exist.
    if (configurations.provider) {
      configurations.provider.map((providerConfiguration) => {
        // Filter out the default configuration when it exists as a provider configuration.
        userConfigurations = userConfigurations.filter(
          (c) => c.type !== providerConfiguration.configuration.type
        );
        // Add the user preference to the list of userPreferences
        userConfigurations.push(providerConfiguration.configuration);
      });
    }
    // Loop over the user configurations if they exist.
    if (configurations.user) {
      configurations.user.map((userConfiguration) => {
        // Filter out the default configuration when it exists as a provider configuration.
        userConfigurations = userConfigurations.filter(
          (c) => c.type !== userConfiguration.configuration.type
        );
        // Add the user preference to the list of userPreferences
        userConfigurations.push(userConfiguration.configuration);
      });
    }
    // Loop over the group configurations if they exist.
    if (configurations.group) {
      configurations.group.map((groupConfiguration) => {
        // Filter out the default configuration when it exists as a provider configuration.
        userConfigurations = userConfigurations.filter(
          (c) => c.type !== groupConfiguration.configuration.type
        );
        // Add the user preference to the list of userPreferences
        userConfigurations.push(groupConfiguration.configuration);
      });
    }
    return preferencesAdapter.setAll(userConfigurations, state);
  }),
  on(
    fromActions.loadError,
    fromActions.insertConfigurationError,
    fromActions.updateConfigurationError,
    fromActions.deleteConfigurationError,
    (state, { error }) => ({ ...state, error })
  ),
  on(fromActions.clearState, (state) => ({ ...initialState }))
);

export function reducer(state: State | undefined, action: Action) {
  return configurationReducer(state, action);
}

// Create the default selectors
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = preferencesAdapter.getSelectors();
