import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppFacade } from '@app/+state';
import { AuthFacade } from '@auth/+state';
import { RouterFacade } from '@router/+state';
import {CoreFacade} from '@core/+state';

export interface LoginCredential {
  username: string;
  password: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  hidePassword = true;
  magicLink = true;
  needHelp = false;
  loginForm: FormGroup;
  magicLinkForm: FormGroup;

  constructor(
    private auth: AuthFacade,
    public app: AppFacade,
    private core: CoreFacade,
    private router: RouterFacade,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    this.magicLinkForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
    });
  }

  login() {
    const username = this.loginForm.get('username').value;
    const password = this.loginForm.get('password').value;
    this.auth.authenticate(username, password);
  }

  sendMagicLink() {
    const username = this.magicLinkForm.get('username').value;
    this.auth.sendMagicLink(username);
  }

  ngAfterViewInit() {
    combineLatest([
      this.auth.isAuthenticated$,
      this.auth.roles$,
      this.app.redirectURL$,
    ])
      .pipe(
        filter(([auth, roles, redirectUrl]) => !!auth && !!roles),
        takeUntil(this.destroy$)
      )
      .subscribe(([auth, roles, redirectUrl]) => {
        if (redirectUrl === '') {
          if (roles.includes('admin')) {
            this.router.go({ path: ['clients'] });
          } else {
            this.router.go({ path: ['clients'] });
          }
        } else {
          // this.router.go({ path: [redirectUrl] });
          this.router.go({ path: ['/clients'] });
        }
      });
  }

  switchLink() {
    this.magicLink = !this.magicLink;
    this.magicLink ?
      this.magicLinkForm.patchValue({username: this.loginForm.value.username}) :
      this.loginForm.patchValue({username: this.magicLinkForm.value.username});
  }

  magicLinkInfoDialog() {
    this.core.openMagicLinkInfoDialog();
  }

  onOpenFeedback() {
    this.core.openFeedback();
  }

  onClickHelp() {
    this.needHelp = true;
  }

  onLeaveHelp() {
    this.needHelp = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
