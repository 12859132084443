import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MagicLinkInfoComponent} from '@core/containers';

@Component({
  selector: 'app-send-magic-link',
  templateUrl: './send-magic-link.component.html',
  styleUrls: ['./send-magic-link.component.scss']
})
export class SendMagicLinkComponent implements OnInit {

  constructor(private ref: MatDialogRef<MagicLinkInfoComponent>) { }

  ngOnInit(): void {
  }

  done() {
    this.ref.close(true);
  }

}
