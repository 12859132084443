import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, filter, catchError, tap } from 'rxjs/operators';
import { AuthFacade } from '@auth/+state';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppService } from './app.service';
import { AppFacade } from './app.facade';
import { LoggerService } from '@core/services';
import * as fromActions from '@app/+state/app.actions';

@Injectable()
export class AppEffects {
  @Effect()
  authStateChange$: Observable<Action> = this.afAuth.authState.pipe(
    filter((user) => !!user),
    tap(() => this.app.setLoading(true)),
    switchMap(() =>
      this.appService.getUICodes$.pipe(
        map((response) => fromActions.loadUICodesSuccess({ response })),
        catchError((error) => of(fromActions.loadUICodesError(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  loadUICodesSuccess$ = this.actions$.pipe(
    ofType(fromActions.loadUICodesSuccess),
    map(() => this.app.setLoading(false))
  );

  @Effect({ dispatch: false })
  loadUICodesError$ = this.actions$.pipe(
    ofType(fromActions.loadUICodesError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred loading codes.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  logout$: Observable<Action> = this.auth.isAuthenticated$.pipe(
    filter((authenticated) => !authenticated),
    switchMap((_) => [fromActions.resetStore(), fromActions.clearState()])
  );

  @Effect({ dispatch: false })
  resetStore$ = this.actions$.pipe(
    ofType(fromActions.resetStore),
    switchMap((_) => this.appService.resetStore())
  );

  constructor(
    private actions$: Actions,
    private afAuth: AngularFireAuth,
    private auth: AuthFacade,
    private app: AppFacade,
    private appService: AppService,
    private logger: LoggerService
  ) {}
}
