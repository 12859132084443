import { Component } from '@angular/core';
import { Params } from '@angular/router';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthFacade } from '../../+state';
import { RouterFacade } from '../../../router/+state';
import { first } from 'rxjs/operators';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent {
  token = '';
  hideNew = true;
  hideConfirm = true;
  matcher = new MyErrorStateMatcher();
  myForm: FormGroup;

  constructor(
    public auth: AuthFacade,
    private router: RouterFacade,
    private fb: FormBuilder
  ) {
    this.router.queryParams$.pipe(first()).subscribe((params) => {
      if (params) {
        this.token = params.t ? params.t : '';
      }
    });

    this.createForm();
  }

  createForm() {
    this.myForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirm: '',
      },
      { validator: this.checkPasswords }
    );
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.passwordConfirm.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  get password() {
    return this.myForm.get('password').value;
  }
  get passwordConfirm() {
    return this.myForm.get('passwordConfirm').value;
  }

  onResetPassword(password: string) {
    const body = {
      new_password: password,
      token: this.token,
    };
    this.auth.passwordReset(body);
  }
}
