import { Action } from '@ngrx/store';
import { RouterUrl } from './router.reducer';

export enum RouterActionTypes {
  Go = '[Router] Go',
  Back = '[Router] Back',
  Forward = '[Router] Forward'
}

export class GoAction implements Action {
  readonly type = RouterActionTypes.Go;
  constructor(public payload: RouterUrl) {}
}

export class BackAction implements Action {
  readonly type = RouterActionTypes.Back;
}

export class ForwardAction implements Action {
  readonly type = RouterActionTypes.Forward;
}

export type Actions = GoAction | BackAction | ForwardAction;
