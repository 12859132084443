import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-verbiage',
  templateUrl: './privacy-policy-verbiage.component.html',
  styleUrls: ['./privacy-policy-verbiage.component.scss'],
})
export class PrivacyPolicyVerbiageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
