/**
 * Function that convert UTC date string to local date without time
 *
 * @param dateString - The date in string format: '1999-12-29' or '1999/12/29'
 * @param dateSeparator - The date separator: '-' (dash for '1999-12-29' format) or '/' (slash for '1999/12/29' format)
 * @return  dateObj - The date object: 'Wed Dec 29 1999 00:00:00 GMT-0600 (Central Standard Time)'
 */


export function convertUTCStringToLocalDate(dateString: string, dateSeparator: string): Date {
  const dateObj = new Date();
  const datePart = dateString.split('T')[0];
  dateObj.setFullYear(Number(datePart.split(dateSeparator)[0]));
  dateObj.setMonth(Number(datePart.split(dateSeparator)[1]) - 1);
  dateObj.setDate(Number(datePart.split(dateSeparator)[2]));
  dateObj.setHours(0, 0, 0, 0);
  return dateObj;
}
