import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AuthFacade } from '@auth/+state';
import * as fromActions from './configuration.actions';
import * as fromReducer from './configuration.reducer';
import * as fromSelectors from './configuration.selector';
export {
  insertConfigurationSuccess,
  updateConfigurationSuccess,
  deleteConfigurationSuccess,
} from './configuration.actions';

export { selectConfigurationByType } from './configuration.selector';
@Injectable({ providedIn: 'root' })
export class ConfigurationFacade {
  constructor(
    private store: Store<fromReducer.State>,
    private auth: AuthFacade
  ) {}

  loadConfigurationById(id: string) {
    return this.store.dispatch(fromActions.loadByOrganizationId({ id }));
  }

  getConfigurationByType$(type: string): Observable<any> {
    return this.store.pipe(
      select(fromSelectors.selectConfigurationByType(type))
    );
  }

  saveConfigurationByType(type: string, body: any) {
    this.getConfigurationByType$(type)
      .pipe(withLatestFrom(this.auth.uid$), first())
      .subscribe(([configuration, userId]) => {
        if (configuration.__typename === 'default_configuration') {
          this.insertConfiguration(type, body, userId);
        } else {
          this.updateConfiguration(configuration.id, body);
        }
      });
  }

  insertUserConfiguration(type: string, body: any, userId: string) {
    this.insertConfiguration(type, body, userId);
  }

  updateUserConfiguration(id: string, body: any) {
    this.updateConfiguration(id, body);
  }

  insertConfiguration(type: string, body: string, userId: string) {
    const input: any = {
      object: {
        user_id: userId,
        configuration: {
          data: {
            type,
            body,
          },
        },
      },
    };
    this.store.dispatch(fromActions.insertConfiguration({ input }));
  }

  updateConfiguration(id: string, body: string) {
    const input: any = {
      id,
      body,
    };
    this.store.dispatch(fromActions.updateConfiguration({ input }));
  }

  deleteConfiguration(id: string) {
    const input: any = {
      id,
    };
    this.store.dispatch(fromActions.deleteConfiguration({ input }));
  }
}
