import { ConstantPool } from '@angular/compiler';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import {first} from 'rxjs/operators';
import {OrganizationsService} from '@organizations/services';

@Component({
  selector: 'app-organization-details-ui',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationDetailsComponent implements OnInit, OnChanges {
  @Input() isNew = true;
  @Input() isReadOnly: boolean;
  @Input() organization: any;
  @Input() providerId: string;
  @Input() states: any;
  @Input() phoneTypes: any;
  @Input() organizationType: string;

  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  @Output() addressCreate: EventEmitter<any> = new EventEmitter();
  @Output() addressUpdate: EventEmitter<any> = new EventEmitter();
  @Output() addressDelete: EventEmitter<any> = new EventEmitter();

  @Output() phoneNumberCreate: EventEmitter<any> = new EventEmitter();
  @Output() phoneNumberUpdate: EventEmitter<any> = new EventEmitter();
  @Output() phoneNumberDelete: EventEmitter<any> = new EventEmitter();

  organizationForm: FormGroup;
  phoneNumbers: FormGroup;
  addresses: FormGroup;

  phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

  constructor(private fb: FormBuilder, private organizationsService: OrganizationsService) {}

  ngOnInit(): void {
    this.createForms();
  }

  ngOnChanges() {
    if (this.organization && this.organizationForm) {
      this.organizationForm.patchValue(this.organization);

      if (this.organization.addresses) {
        this.addresses.patchValue(this.organization.addresses);
        this.displayAddresses();
      }

      if (this.organization.phone_numbers) {
        this.phoneNumbers.patchValue(this.organization.phone_numbers);
        this.displayPhoneNumbers();
      }
    }
  }

  createForms() {
    this.organizationForm = this.fb.group({
      id: '',
      name: ['', Validators.required],
      type: this.organizationType,
    });

    this.addresses = this.fb.group({
      addresses: this.fb.array([]),
    });

    this.phoneNumbers = this.fb.group({
      phoneNumbers: this.fb.array([]),
    });
  }

  ////////////////////////////////////
  // Addresses Form
  ////////////////////////////////////
  get addressesForm() {
    return this.addresses.get('addresses') as FormArray;
  }

  displayAddresses() {
    for (let i = this.addressesForm.length; i >= 0; i--) {
      this.onDeleteAddressItem(i);
    }

    this.organization.addresses.forEach((address) => {
      this.onAddAddressItem(address.address);
    });
  }

  onAddAddressItem(address?: any) {
    const newAddress = this.fb.group({
      __typename: address ? address.__typename : '',
      id: address ? address.id : '',
      street_address_1: [
        address ? address.street_address_1 : '',
        Validators.required,
      ],
      street_address_2: address ? address.street_address_2 : '',
      city: [address ? address.city : '', Validators.required],
      state: [address ? address.state : '', Validators.required],
      zip: [address ? address.zip : '', Validators.required],
    });

    this.addressesForm.push(newAddress);
  }

  onDeleteAddressItem(i: number) {
    this.addressesForm.removeAt(i);
  }

  ////////////////////////////////////
  // Phone Numbers Form
  ////////////////////////////////////
  get phoneNumbersForm() {
    return this.phoneNumbers.get('phoneNumbers') as FormArray;
  }

  displayPhoneNumbers() {
    for (let i = this.phoneNumbersForm.length; i >= 0; i--) {
      this.onDeletePhoneNumberItem(i);
    }

    this.organization.phone_numbers.forEach((phone) => {
      this.onAddPhoneNumberItem(phone.phone_number);
    });
  }

  onAddPhoneNumberItem(phoneNumber?: any) {
    const newNumber = this.fb.group({
      __typename: phoneNumber ? phoneNumber.__typename : '',
      id: [phoneNumber ? phoneNumber.id : ''],
      type: [phoneNumber ? phoneNumber.type : 'work', Validators.required],
      number: [
        phoneNumber ? phoneNumber.number : '',
        [Validators.required, Validators.pattern(this.phoneRegex)],
      ],
    });

    this.phoneNumbersForm.push(newNumber);
  }

  onDeletePhoneNumberItem(i: number) {
    this.phoneNumbersForm.removeAt(i);
  }

  /**
   * onCreate() - Create an Organization with nested objects
   *
   * Create a deep object and call the mutation to create
   * the Organization entity and its nested objects.
   */
  //  Example Deep Client Input Object:
  // const example_input = {
  // {
  //   "object": {
  //     "provider_id": "9170567f-4465-4104-90be-7655e60fc5c3",
  //     "organization": {
  //       "data": {
  //         "name": "AAA",
  //         "type": "corporate",
  //         "phone_numbers": {
  //           "data": {
  //             "phone_number": {
  //               "data": {
  //                 "number": "(987) 654-3210",
  //                 "type": "work"
  //               }
  //             }
  //           }
  //         },
  //         "addresses": {
  //           "data": {
  //             "address": {
  //               "data": {
  //                 "street_address_1": "100 Main Street",
  //                 "street_address_2": "Suite 100",
  //                 "city": "Denver",
  //                 "state": "CO",
  //                 "zip": "81111"
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // TODO make provider_id take an arry
  onCreate() {
    this.organizationsService
      .getRootOrganization(this.providerId)
      .subscribe((org) => {
        const rootOrg = org[0]?.root_organization?.root;
        if (!rootOrg.id) { return; }
        // Manufacture a mutation input object
        const input: any = {
          object: {
            provider_id: rootOrg.id,
            organization: {data: {}},
          },
        };
        // Add top organization details.
        Object.keys(this.organizationForm.controls).forEach((key) => {
          const val = this.organizationForm.get(key).value;
          if (val) {
            input.object.organization.data[key] = val;
          }
        });

        // Add any addresses.
        if (this.addressesForm.value.length > 0) {
          input.object.organization.data.addresses = {};
          input.object.organization.data.addresses.data = {};
          input.object.organization.data.addresses.data = [
            ...this.addressesForm.value.map((address) => ({
              address: {
                data: {
                  street_address_1: address.street_address_1,
                  street_address_2: address.street_address_2,
                  city: address.city,
                  state: address.state,
                  zip: address.zip,
                },
              },
            })),
          ];
        }

        // Add any phone numbers.
        if (this.phoneNumbersForm.value.length > 0) {
          input.object.organization.data.phone_numbers = {};
          input.object.organization.data.phone_numbers.data = {};
          input.object.organization.data.phone_numbers.data = [
            ...this.phoneNumbersForm.value.map((phoneNumber) => ({
              phone_number: {
                data: {
                  number: phoneNumber.number,
                  type: phoneNumber.type,
                },
              },
            })),
          ];
        }
        this.create.emit(input);
      });
  }

  /**
   * onSave() - Persist all changes to the following entities
   *
   * 1. Organization
   * 2. Addresses
   * 3. Phone Numbers
   *
   * For the Organization: check if the entity is modified then update.
   *
   * For the dependent objects:
   *
   * Create: if the form's array element doesn't exist in the Object.
   * Update: if the form's array element is different than the element in the Object
   * Delete: if an object element exists but does not exist on the form.
   */
  onSave() {
    // Organization
    if (this.organizationForm.dirty) {
      this.update.emit(this.organizationForm.value);
    }

    ////////////////////////////////////////////////////////////
    // Addresses
    //
    //  1.  Iterate over the Addresses Form and check if the
    //      address is new or has been modified.
    //
    //  2.  Iterate over the original array of addresses
    //      and delete any that don't exist in the form.
    //
    ////////////////////////////////////////////////////////////
    const addresses = this.organization.addresses
      ? this.organization.addresses.map((address) => address.address)
      : [];
    const addressChanges =
      this.addressesForm.dirty ||
      this.addressesForm.value.length !== addresses.length;

    if (addressChanges) {
      // Iterate over the form's addresses:
      // 1. If the address doesn't exist or isn't the same => upsert
      const fromForm = this.addressesForm.value;
      fromForm.map((address) => {
        const found = addresses.find((a) => a.id === address.id);
        const isEqual = _.isEqual(address, found);
        // If the object isn't found => insert
        // If the object is found and has changed => update
        if (!found) {
          this.addressCreate.emit({
            organization_id: this.organization.id,
            address,
          });
        } else if (found && !isEqual) {
          // Remove __typename property for update object
          const updateAddress = address;
          if (updateAddress.hasOwnProperty('__typename')) {
            delete updateAddress.__typename;
          }
          this.addressUpdate.emit(address);
        }
      });
      // Iterate over the original array of phone phone numbers:
      // 2. If the phone number in the array doesn't exist
      //    in the form => DELETE
      addresses.map((address) => {
        const found = fromForm.find((e) => e.id === address.id);
        if (!found) {
          // Manufacture delete mutation object
          this.addressDelete.emit(address);
        }
      });
    }

    ////////////////////////////////////////////////////////////
    // Phone Numbers
    //
    //  1.  Iterate over the Phone Numbers Form and check if the
    //      phone number is new or has been modified.
    //
    //  2.  Iterate over the original array of phone numbers
    //      and delete any that don't exist in the form.
    //
    ////////////////////////////////////////////////////////////
    const phoneNumbers = this.organization.phone_numbers
      ? this.organization.phone_numbers.map(
          (phoneNumber) => phoneNumber.phone_number
        )
      : [];
    const phoneNumberChanges =
      this.phoneNumbersForm.dirty ||
      this.phoneNumbersForm.value.length !== phoneNumbers.length;
    if (phoneNumberChanges) {
      // Iterate over the form's phone numbers:
      // 1. If the phone number doesn't exist or isn't the same => upsert
      const numbers = this.phoneNumbersForm.value;
      numbers.map((phoneNumber) => {
        const found = phoneNumbers.find((ph) => ph.id === phoneNumber.id);
        const isEqual = _.isEqual(phoneNumber, found);
        // If the object isn't found or the object in the form is different
        // from the object in the original array => UPSERT
        if (!found) {
          this.phoneNumberCreate.emit({
            organization_id: this.organization.id,
            phoneNumber,
          });
        } else if (found && !isEqual) {
          if (phoneNumber.hasOwnProperty('__typename')) {
            delete phoneNumber.__typename;
          }
          this.phoneNumberUpdate.emit(phoneNumber);
        }
      });
      // Iterate over the original array of phone numbers:
      // 2. If the phone number in the array doesn't exist
      //    in the form => DELETE
      phoneNumbers.map((phoneNumber) => {
        const found = numbers.find((e) => e.id === phoneNumber.id);
        if (!found) {
          this.phoneNumberDelete.emit(phoneNumber);
        }
      });
    }
  }

  onDelete() {
    this.delete.emit(this.organization);
  }
}
