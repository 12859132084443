<mat-card>
  <mat-card-title>
    <div class="header mat-elevation-z6">
      <div class="center-title">Request Password Reset</div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <p>
      Enter your User Name in the field below to request a Reset Password email
      link.
    </p>
    <form [formGroup]="form">
      <p>
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Username"
            formControlName="username"
          />
        </mat-form-field>
      </p>
      <div class="button">
        <button
          mat-raised-button
          color="accent"
          (click)="onResetPassword(username)"
        >
          Send Request
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
