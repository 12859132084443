import { Injectable, OnDestroy } from '@angular/core';
import {Subject, Observable, from, combineLatest} from 'rxjs';
import {takeUntil, tap, map, withLatestFrom} from 'rxjs/operators';
import { AppFacade } from '@app/+state';
import { AuthFacade } from '@auth/+state';
import { RouterFacade } from '@router/+state';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardAdminService
  implements CanActivate, CanActivateChild, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private auth: AuthFacade,
    private router: RouterFacade,
    private app: AppFacade
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([this.auth.idToken$, this.auth.roles$]).pipe(
      takeUntil(this.destroy$),
      tap(([token, groups]) => {
        if (token) {
          const roles = [...groups];
          if (!roles.includes('site-admin')) {
            this.router.go({ path: ['page-not-found'] });
          }
        } else {
          // Not logged in
          this.app.setRedirectURL(state.url);
          this.router.go({ path: ['login'] });
        }
      }),
      map((user) => !!user)
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([this.auth.idToken$, this.auth.roles$]).pipe(
      takeUntil(this.destroy$),
      tap(([token, groups]) => {
        if (token) {
          const roles = [...groups];
          if (!roles.includes('site-admin')) {
            this.router.go({ path: ['page-not-found'] });
          }
        } else {
          // Not logged in
          this.app.setRedirectURL(state.url);
          this.router.go({ path: ['login'] });
        }
      }),
      map((user) => !!user)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
