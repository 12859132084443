import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AuthFacade } from '@auth/+state';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  token: string;

  constructor(
    private auth: AuthFacade,
    private http: HttpClient,
    private domSanitizer: DomSanitizer
  ) {
    this.auth.idToken$
      .pipe(
        first(),
        map((idToken) => idToken.token)
      )
      .subscribe((token) => (this.token = token));
  }

  getFileUrl(id: string, action: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      `${environment.apiUrl}/documents/${id}/${action}?token=${this.token}`
    );
  }

  saveFile(url: string, fileName: string, type: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob) => {
        saveAs(blob, fileName);
      })
    );
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
