import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface OkCancelPrompt {
  title: string;
  body: string;
}

@Component({
  selector: 'app-create-user-prompt',
  template: `
    <h3 mat-dialog-title>{{ data.title }}</h3>
    <mat-dialog-content>
      {{ data.body }}
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button type="button" (click)="cancel()">
        CANCEL
      </button>
      <button mat-button type="button" (click)="ok()">
        OK
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        max-width: 400px;
        white-space: pre-wrap
      }
      mat-dialog-actions {
        display: flex;
        justify-content: flex-end;
      }
      [mat-button] {
        padding: 0;
      }
    `,
  ],
})
export class OkCancelPromptComponent {
  constructor(
    private ref: MatDialogRef<OkCancelPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OkCancelPrompt
  ) {}

  cancel() {
    this.ref.close(false);
  }

  ok() {
    this.ref.close(true);
  }
}
