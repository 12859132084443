<div class="padding">
  <p class="c12"><span class="c3">Sharescape</span></p>
  <p class="c12" id="h.gjdgxs"><span class="c3">PRIVACY POLICY</span></p>
  <p class="c9"><span class="c3"></span></p>
  <p class="c0">
    <span class="c3"
      >We are sensitive to protecting the privacy of our website visitors. The
      purpose of this Privacy Statement is to let you know how we handle
      information received from you through this website, as well as inform you
      of the risks involved when you communicate or provide information by way
      of the Internet. Keep in mind that changes are periodically made to this
      Privacy Statement without notice and it is your responsibility to review
      the Privacy Policy when you use this website. By continuing to use this
      site, you consent to any changes to our policy or terms of use.</span
    >
  </p>
  <p class="c0">
    <span class="c3"
      >During your visit to our website, remember that the medical and health
      information presented here is intended to be general in nature, and should
      not be viewed as a substitute for professional advice. Please consult with
      a health care professional for all matters relating to personal medical
      and health care issues.</span
    >
  </p>
  <p class="c2"><span class="c4">Health and Medical Information</span></p>
  <p class="c1"><span class="c3"></span></p>
  <p class="c0" id="h.30j0zll">
    <span class="c3"
      >Certain health and medical information about you is protected under the
      Health Insurance Portability and Accountability Act (&ldquo;HIPAA&rdquo;)
      and applicable state law. This information may be provided by you online
      or offline or may be collected by us from other methods such as through a
      health care provider. We protect covered health and medical information as
      required by HIPAA and applicable state law. Similarly, we may use covered
      health and medical information as permitted by HIPAA and applicable state
      law.</span
    >
  </p>
  <p class="c2"><span class="c4">Security/Confidentiality</span></p>
  <p class="c2">
    <span class="c3"
      ><br />No transmission of data over the Internet or any wireless network
      can be guaranteed to be 100 percent secure. We will, however, use a number
      of physical security methods (such as locks and alarm systems); electronic
      security methods (passwords and encryption methods); and procedural
      methods (rules regarding the handling and use of information) to protect
      the security and integrity of information submitted through this Web
      site.</span
    >
  </p>
  <p class="c1"><span class="c3"></span></p>
  <p class="c2"><span class="c4">Information Collection and Storage</span></p>
  <p class="c2">
    <span class="c3"
      ><br />Personally Identifiable Information: We do not collect information
      that personally identifies you unless you choose to provide it to us
      through the login provided to you. The personal information you submit is
      shared only with those people in our organization who need this
      information to respond to your question or request, and as otherwise
      permitted by law to allow us to operate our business or to comply with
      applicable laws. We do participate in hosting of your information with
      third parties who have signed HIPAA compliant, and/or state compliant
      business associate agreements and have agreed to follow our terms of
      conditions, privacy practices and other procedures designed to safeguard
      your information.
    </span>
  </p>
  <p class="c0 c10"><span class="c3"></span></p>
  <p class="c0">
    <span class="c3"
      >As used in this Privacy Statement, personal information does not include
      information protected under HIPAA or applicable state laws, which is
      protected as described in the section &ldquo;Health and Medical
      Information&rdquo; above.</span
    >
  </p>
  <p class="c2">
    <span class="c5">Non-Personally Identifiable Information:</span
    ><span class="c3"
      >&nbsp;We collect information that does not personally identify you for
      various purposes (for example, website usage, traffic patterns, site
      performance and related statistics based on our tracking record of your
      visits to our website).</span
    >
  </p>
  <p class="c0">
    <span class="c3"
      >We collect some of this information using persistent
      &ldquo;cookies&rdquo; to recognize a repeat visitor; this helps us collect
      general information about how our site is viewed. We also collect and log
      the IP address of each visitor to gather aggregate data on visitors to our
      site. Keep in mind that neither cookies nor IP addresses are used to
      collect any personal information from you.</span
    >
  </p>
  <p class="c2"><span class="c4">Email</span></p>
  <p class="c2">
    <span class="c8"
      ><br />Most email does not provide a completely secure and confidential
      means of communication. It is possible that your email could be viewed
      inappropriately by another Internet user. If you choose to provide us with
      your email, any misaddressing may result in others intercepting, altering,
      forwarding or using the information without authorization.&nbsp;</span
    ><span class="c4"
      >If you wish to keep your information completely private, you should not
      use email.</span
    >
  </p>
  <p class="c1"><span class="c3"></span></p>
  <p class="c0">
    <span class="c3"
      >From time to time, a representative Sharescape or an associated entity
      may wish to deliver information to you or your representatives via email.
      Please note, we do not sell, trade, or otherwise share our email lists
      with others. You may remove your name from any list you have registered
      for using our website&rsquo;s established method.</span
    >
  </p>
  <p class="c2"><span class="c4">Links to Other Websites</span></p>
  <p class="c2">
    <span class="c3"
      ><br />Our website contains links to other websites that are not managed
      or controlled by us. These links are provided for your convenience only.
      We do not review, endorse or take responsibility for the content of these
      other websites. If you choose to access such sites, you are subject to the
      privacy policies of those sites.</span
    >
  </p>
  <ul class="c7 lst-kix_list_1-0 start">
    <li class="c6">
      <span class="c3"
        >If you receive email at work, your employer may have the right to save
        and read your email; similarly, your Internet service provider may have
        the right to save and read your email.</span
      >
    </li>
    <li class="c6">
      <span class="c3"
        >If you share your email account, others may view your emails.</span
      >
    </li>
    <li class="c6">
      <span class="c3"
        >Email can be used unknowingly to spread computer viruses.</span
      >
    </li>
  </ul>
  <p class="c10 c13"><span class="c3"></span></p>
  <p class="c2">
    <span class="c11">Information From Children</span
    ><span class="c3"
      ><br />Our website is not intended to attract or collect information from
      children under the age of 13, unless they receive verifiable parental
      consent. If you think we have collected personal information from a child
      under the age of 13 through this website, please contact us
      immediately.</span
    >
  </p>
  <p class="c1"><span class="c4"></span></p>
  <p class="c2">
    <span class="c11">Printing or Downloading Information</span
    ><span class="c3"
      ><br />Unless otherwise stated, you may print or download information from
      our website for personal, noncommercial use only if it is acceptable under
      Health Insurance Portability and Accountability Act (&ldquo;HIPAA&rdquo;)
      and applicable state law. You must, however, identify the source of the
      material and include a statement that the materials are protected by
      copyright law.</span
    >
  </p>
  <p class="c1"><span class="c3"></span></p>
  <p class="c2">
    <span class="c11">Website Contact Information</span
    ><span class="c3"
      ><br />If you have questions about this Privacy Policy please contact us
      at help@sharescape.io
    </span>
  </p>
</div>
