import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavItem } from '@core/models';

@Component({
  selector: 'app-nav-tree',
  templateUrl: './nav-tree.component.html',
  styleUrls: ['./nav-tree.component.scss'],
})
export class NavTreeComponent {
  @Input() authenticated: boolean;
  @Input() roles: Array<string>;
  @Input() contact: string;
  @Input() url: string;
  @Input() organizationLogoUrl: string;
  @Input() navItems: NavItem[];
  @Input() accessList: string[];
  @Input() isLogoLoaded: boolean;

  @Output() selected: EventEmitter<NavItem> = new EventEmitter();
  @Output() logoLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() suggestProvider: EventEmitter<void> = new EventEmitter();
  @Output() referPatient: EventEmitter<void> = new EventEmitter();

  imageLoaded() {
    this.logoLoaded.emit(true);
  }
}
