import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { RouterModule } from '@angular/router';

import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from '@angular/fire/performance';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';

import { CoreModule } from 'src/app/core/core.module';
import { MaterialModule } from '@material/material.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './+state/auth.effects';
import { reducer } from './+state/auth.reducer';

import { environment } from 'src/environments/environment';
import {
  LoginComponent,
  PasswordResetComponent,
  RequestPasswordResetComponent,
  LogoutPromptComponent,
  PageNotFoundComponent,
  ChangePasswordComponent,
  MagicLoginComponent,
} from './components';

@NgModule({
  declarations: [
    LoginComponent,
    PasswordResetComponent,
    RequestPasswordResetComponent,
    LogoutPromptComponent,
    PageNotFoundComponent,
    ChangePasswordComponent,
    MagicLoginComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
    RouterModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    StoreModule.forFeature('auth', reducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [
    PerformanceMonitoringService,
    ScreenTrackingService,
    UserTrackingService,
  ],
  exports: [ChangePasswordComponent],
})
export class AuthModule {}
