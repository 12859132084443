import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UserAction } from '@core/models';

@Component({
  selector: 'app-user-actions-ui',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserActionsComponent implements OnChanges {
  @Input() user: any;
  @Input() actions: any;
  @Input() configurationId = null;

  @Input() isDetails: boolean;
  @Input() isEdit: boolean;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  // Store the Modules of the user's permission
  overrideExists = false; // Track if the user already has their permission overridden.
  modules = []; // top level modules the user has permissions for.
  data = []; // the permissions for each top level module.
  updatedPermissions: {}; // an object to track the updated permissions.
  dirty = false; // Track if permissions have changed.

  ngOnChanges() {
    if (this.actions) {
      // Persist the original permissions in the local var updatedPermissions
      this.updatedPermissions = this.actions;
      this.dirty = false;

      // Get all the module names
      const keys = Object.keys(this.actions).sort();

      // Create an array of arrays of permissions.
      keys.map((key) => {
        this.modules.push(key);
        const data: any = Object.values(
          this.actions[key]
        ).sort((a: UserAction, b: UserAction) =>
          a.action > b.action ? 1 : -1
        );
        this.data.push(data);
      });
    }

    if (
      this.user &&
      this.user.user_actions &&
      this.user.user_actions.length === 1
    ) {
      this.overrideExists = true;
    }
  }

  onSavePermissions(userPermissions) {
    this.save.emit({
      permissions: { ...userPermissions },
      user: { ...this.user },
    });
  }

  onChangePermission(event: MatSlideToggleChange, m: string, a: any) {
    // Manufacture an updatedAction object
    const updatedAction = { ...a, value: event.checked };

    // Get all the module's actions and filter out the passed in action.
    const updatedActions = this.updatedPermissions[m].filter(
      (action) => action.action !== a.action
    );

    // Push the updated action object onto the array of updatedActions
    updatedActions.push(updatedAction);

    // Reset the updatedPermissions object with the updated action
    this.updatedPermissions = {
      ...this.updatedPermissions,
      [m]: [...updatedActions],
    };

    // Permissions have changed, set dirty to true.
    this.dirty = true;
  }
}
