import {
  Component,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnChanges,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { Screen } from '@core/models';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-organization-users-list',
  templateUrl: './organization-users-list.component.html',
  styleUrls: ['./organization-users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationUsersListComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  @Input() screen: Screen;
  @Input() selectedOrganization: any;
  @Input() displayedColumns: Array<string>;
  @Input() users: any;
  @Input() selectedUser: any = null;
  @Input() pageSize: number;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() details: EventEmitter<any> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() sendInvite: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$: Subject<boolean> = new Subject<boolean>();

  dataSource = new MatTableDataSource<any>();

  ngOnChanges() {
    if (this.users) {
      this.dataSource.data = this.users;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'username':
          return item.username;
        case 'firstName':
          return item.first_name;
        case 'lastName':
          return item.last_name;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.page
      .pipe(
        takeUntil(this.destroy$),
        map((val) => {
          if (val.pageSize !== this.pageSize) {
            this.pageSizeChanged.emit(val.pageSize);
          }
        })
      )
      .subscribe();
  }

  delete(user, selectedOrganization) {
    const payload = {
      userId: user.id,
      username: user.username,
      organizationId: selectedOrganization.id,
      accountType: 'organizations',
    };
    this.remove.emit(payload);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
