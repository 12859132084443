<div mat-dialog-title>
  Select File Name
</div>
<div mat-dialog-content class="content">
  <mat-form-field class="flex-item">
    <mat-label>File Name</mat-label>
    <input matInput [(ngModel)]="data.fileName" />
  </mat-form-field>
</div>
<div mat-dialog-actions class="footer">
  <button mat-button color="default" [mat-dialog-close]="null">CANCEL</button>
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="data.fileName"
    cdkFocusInitial
  >
    OK
  </button>
</div>
