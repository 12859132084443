import { createAction, props } from '@ngrx/store';

export const loadSuccess = createAction(
  '[Preferences] Load Preferences Success',
  props<{ preferences: any }>()
);

export const loadError = createAction(
  '[Preferences] Load Preferences Error',
  props<{ error: any }>()
);

export const insertPreference = createAction(
  '[Preferences] Insert Preference',
  props<{ input: any }>()
);

export const insertPreferenceSuccess = createAction(
  '[Preferences] Insert Preference Success',
  props<{ preference: any }>()
);

export const insertPreferenceError = createAction(
  '[Preferences] Insert Preference Error',
  props<{ error: any }>()
);

export const updatePreference = createAction(
  '[Preferences] Update Preference',
  props<{ input: any }>()
);

export const updatePreferenceSuccess = createAction(
  '[Preferences] Update Preference Success',
  props<{ preference: any }>()
);

export const updatePreferenceError = createAction(
  '[Preferences] Update Preference Error',
  props<{ error: any }>()
);

export const loadPreference = createAction(
  '[Preferences] Load Preferences'
);

export const caseUpdateAlerts = createAction(
  '[Preferences] Case Update Alerts',
  props<{ input: any }>()
);

export const caseUpdateAlertsSuccess = createAction(
  '[Preferences] Case Update Alerts Success',
  props<{ mutation: any }>()
);

export const caseUpdateAlertsError = createAction(
  '[Preferences] Case Update Alerts Error',
  props<{ error: any }>()
);

export const clearState = createAction('[Preferences] Clear State');
