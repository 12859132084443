import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { OrganizationsService } from '@organizations/services';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {RouterFacade} from '@router/+state';
@Component({
  selector: 'app-client-details-ui',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientDetailsComponent implements OnChanges, OnInit {
  @Input() client: any;
  @Input() states: any;
  @Input() phoneTypes: any;
  @Input() sharedOrganizationId: string;

  @Input() isNew: boolean;
  @Input() isShare: boolean;
  @Input() isDetails: boolean;
  @Input() isEdit: boolean;

  @Input() providerId: string;
  @Input() roles: string;

  @Input() clientOrganizations: any;

  @Input() clientCaseContact: any;

  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  @Output() share: EventEmitter<any> = new EventEmitter();
  @Output() unShare: EventEmitter<any> = new EventEmitter();

  @Output() addressCreate: EventEmitter<any> = new EventEmitter();
  @Output() addressUpdate: EventEmitter<any> = new EventEmitter();
  @Output() addressDelete: EventEmitter<any> = new EventEmitter();

  @Output() phoneNumberCreate: EventEmitter<any> = new EventEmitter();
  @Output() phoneNumberUpdate: EventEmitter<any> = new EventEmitter();
  @Output() phoneNumberDelete: EventEmitter<any> = new EventEmitter();

  @Output() delete: EventEmitter<any> = new EventEmitter();

  ////////////////////////////////////////////////////////////
  // Won't Implement
  ////////////////////////////////////////////////////////////
  // @Output() caseContactCreate: EventEmitter<any> = new EventEmitter();
  // @Output() caseContactUpdate: EventEmitter<any> = new EventEmitter();

  clientForm: FormGroup;
  organizations: FormGroup;
  // caseContact: FormGroup;
  phoneNumbers: FormGroup;
  addresses: FormGroup;

  phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

  constructor(
    private fb: FormBuilder,
    private organizationsService: OrganizationsService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.sharedOrganizationId) {
      this.organizationsService
        .getOrganizationName(this.sharedOrganizationId)
        .pipe(first())
        .subscribe((org) => {
          this.createForms(org);
          this.cdr.markForCheck();
        });
    } else {
      this.createForms();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Populate client form on the first instance of changes.client that
    // way the current user's unsaved changes don't get clobbered.
    if (
      this.client &&
      changes.client &&
      !changes.client.previousValue &&
      this.clientForm
    ) {
      // Add local timezone to date
      const dateOfBirth = this.client.date_of_birth
        ? new Date(this.client.date_of_birth + 'T08:00:00.000Z')
        : null;
      const dateOfInjury = this.client.date_of_injury
        ? new Date(this.client.date_of_injury + 'T08:00:00.000Z')
        : null;
      this.clientForm.patchValue({
        ...this.client,
        date_of_birth: dateOfBirth,
        date_of_injury: dateOfInjury,
      });

      if (this.client.shared_organizations) {
        this.organizations.patchValue(this.client.shared_organizations);
        this.displayOrganizations();
      }
      if (this.client.addresses) {
        this.addresses.patchValue(this.client.addresses);
        this.displayAddresses();
      }
      if (this.client.phone_numbers) {
        this.phoneNumbers.patchValue(this.client.phone_numbers);
        this.displayPhoneNumbers();
      }
      ////////////////////////////////////////////////////////////
      // Won't Implement
      ////////////////////////////////////////////////////////////
      // Case Contact
      // if (this.client.client_attorneys) {
      //   this.caseContact.patchValue(this.client.client_attorneys);
      //   this.displayCaseContact();
      // }
    }
  }

    loadClient(id) {
        if (id) {
            const uri = `clients/${id}/details`;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                this.router.navigate([uri]));
        }
    }

  createForms(sharedOrganization?: any) {
    this.clientForm = this.fb.group({
      id: '',
      first_name: '',
      last_name: ['', Validators.required],
      middle_initial: '',
      gender: '',
      email: ['', [Validators.email]],
      date_of_birth: '',
      date_of_injury: '',
    });

    // If a shared organization was passed into this function
    // add the org to the array else create the form with
    // an empty array.
    this.organizations = this.fb.group({
      organizations: this.fb.array(
        sharedOrganization ? [sharedOrganization] : []
      ),
    });

    // this.caseContact = this.fb.group({
    //   caseContact: this.fb.array([]),
    // });

    this.addresses = this.fb.group({
      addresses: this.fb.array([]),
    });

    this.phoneNumbers = this.fb.group({
      phoneNumbers: this.fb.array([]),
    });
  }

  // Getter method for Gender
  get gender() {
    return this.clientForm.get('gender').value;
  }

  //////////////////////
  // Organization Form
  //////////////////////
  get organizationsForm() {
    return this.organizations.get('organizations') as FormArray;
  }

  displayOrganizations() {
    for (let i = this.organizationsForm.length; i >= 0; i--) {
      this.onDeleteOrganizationItem(i);
    }

    this.client.shared_organizations.forEach((organization) => {
      this.onAddOrganizationItem(organization.organization);
    });
  }

  // When adding an empty element,
  onAddOrganizationItem(organization?: any) {
    const newOrganization = this.fb.group({
      id: [organization ? organization.id : '', Validators.required],
      name: [organization ? organization.name : '', Validators.required],
    });

    this.organizationsForm.push(newOrganization);
  }

  onDeleteOrganizationItem(i: number) {
    this.organizationsForm.removeAt(i);
  }

  organizationChanged(item: FormGroup, org: any) {
    const controls = item.controls;
    controls.id.setValue(org.id);
    controls.name.setValue(org.name);
  }

  ////////////////////////////////////////////////////////////
  // Won't Implement
  ////////////////////////////////////////////////////////////

  //////////////////////
  // Case Contact Form
  //////////////////////
  /*get caseContactForm() {
    return this.caseContact.get('caseContact') as FormArray;
  }

  displayCaseContact() {
    for (let i = this.caseContactForm.length; i >= 0; i--) {
      this.onDeleteCaseContactItem(i);
    }

    this.client.client_attorneys.forEach((data) => {
      this.onAddCaseContactItem(data.user);
    });
  }

  onAddCaseContactItem(caseContact?: any) {
    const newCaseContact = this.fb.group({
      id: [caseContact ? caseContact.id : '', Validators.required],
      name: [caseContact ? caseContact.name : '', Validators.required],
    });

    this.caseContactForm.push(newCaseContact);
  }

  onDeleteCaseContactItem(i: number) {
    this.caseContactForm.removeAt(i);
  }

  caseContactChanged(item: FormGroup, org: any) {
    // const controls = item.controls;
    // controls.id.setValue(org.id);
    // controls.name.setValue(org.name);
  }
  */

  ////////////////////////////////////
  // Addresses Form
  ////////////////////////////////////
  get addressesForm() {
    return this.addresses.get('addresses') as FormArray;
  }

  displayAddresses() {
    for (let i = this.addressesForm.length; i >= 0; i--) {
      this.onDeleteAddressItem(i);
    }

    this.client.addresses.forEach((address) => {
      this.onAddAddressItem(address.address);
    });
  }

  onAddAddressItem(address?: any) {
    const newAddress = this.fb.group({
      __typename: address ? address.__typename : '',
      id: address ? address.id : '',
      street_address_1: [
        address ? address.street_address_1 : '',
        Validators.required,
      ],
      street_address_2: address ? address.street_address_2 : '',
      city: [address ? address.city : '', Validators.required],
      state: [address ? address.state : '', Validators.required],
      zip: [address ? address.zip : '', Validators.required],
    });

    this.addressesForm.push(newAddress);
  }

  onDeleteAddressItem(i: number) {
    this.addressesForm.removeAt(i);
  }

  ////////////////////////////////////
  // Phone Numbers Form
  ////////////////////////////////////
  get phoneNumbersForm() {
    return this.phoneNumbers.get('phoneNumbers') as FormArray;
  }

  displayPhoneNumbers() {
    for (let i = this.phoneNumbersForm.length; i >= 0; i--) {
      this.onDeletePhoneNumberItem(i);
    }

    this.client.phone_numbers.forEach((phone) => {
      this.onAddPhoneNumberItem(phone.phone_number);
    });
  }

  onAddPhoneNumberItem(phoneNumber?: any) {
    const newNumber = this.fb.group({
      __typename: phoneNumber ? phoneNumber.__typename : '',
      id: [phoneNumber ? phoneNumber.id : ''],
      type: [phoneNumber ? phoneNumber.type : 'work', Validators.required],
      number: [
        phoneNumber ? phoneNumber.number : '',
        [Validators.required, Validators.pattern(this.phoneRegex)],
      ],
    });

    this.phoneNumbersForm.push(newNumber);
  }

  onDeletePhoneNumberItem(i: number) {
    this.phoneNumbersForm.removeAt(i);
  }

  /**
   * onCreate() - Create a Client with nested objects
   *
   * Create a deep object and call the mutation to create
   * the Client entity and its nested objects.
   *
   * Example Deep Client Input Object:
   */
  // example_input = {
  // {
  //   "object": {
  //     "addresses": {
  //       "data": [
  //         {
  //           "address": {
  //             "data": {
  //               "city": "Denver",
  //               "state": "CO",
  //               "street_address_1": "100 Main Street",
  //               "street_address_2": "Apt B",
  //               "zip": "80101"
  //             }
  //           }
  //         },
  //         {
  //           "address": {
  //             "data": {
  //               "city": "Denver",
  //               "state": "CO",
  //               "street_address_1": "200 Broadway Ave.",
  //               "street_address_2": "Suite 100",
  //               "zip": "80101"
  //             }
  //           }
  //         }
  //       ]
  //     },
  //     "date_of_birth": "2000-01-01",
  //     "date_of_injury": "2020-01-01",
  //     "email": "test@test.com",
  //     "first_name": "Jane",
  //     "gender": "F",
  //     "last_name": "Doe",
  //     "middle_initial": "A",
  //     "phone_numbers": {
  //       "data": [
  //         {
  //           "phone_number": {
  //             "data": {
  //               "number": "(123) 345-6789",
  //               "type": "home"
  //             }
  //           }
  //         },
  //         {
  //           "phone_number": {
  //             "data": {
  //               "number": "(888) 987-65432",
  //               "type": "work"
  //             }
  //           }
  //         }
  //       ]
  //     },
  //     "provider": {
  //       "data": [
  //         {
  //           "provider_id": "067d68b1-3b72-41c5-97db-f15e75b57545"
  //         }
  //       ]
  //     },
  //     "shared_organizations": {
  //       "data": [
  //         {
  //           "organization_id": "01ffdf9c-43e3-3b1e-997c-f6d39673ec53"
  //         },
  //         {
  //           "organization_id": "01c690cd-77fc-3c35-8a4d-858b4edc210e"
  //         }
  //       ]
  //     }
  //   }
  // }
  // TODO refactor to array of providers
  onCreate() {
    // Manufacture a mutation input object
    // Start with user's provider organization.
    const input: any = {
      object: {
        provider: {
          data: [{ provider_id: this.providerId }],
        },
      },
    };
    // Add top level client details.
    Object.keys(this.clientForm.controls).forEach((key) => {
      const val = this.clientForm.get(key).value;
      if (val) {
        input.object[key] = val;
      }
    });

    // Add any shared organizations.
    if (this.organizationsForm.value.length > 0) {
      input.object.shared_organizations = {};
      input.object.shared_organizations.data = [
        ...this.organizationsForm.value.map((organization) => ({
          organization_id: organization.id,
        })),
      ];
    }

    // Add any addresses.
    if (this.addressesForm.value.length > 0) {
      input.object.addresses = {};
      input.object.addresses.data = {};
      input.object.addresses.data = [
        ...this.addressesForm.value.map((address) => ({
          address: {
            data: {
              street_address_1: address.street_address_1,
              street_address_2: address.street_address_2,
              city: address.city,
              state: address.state,
              zip: address.zip,
            },
          },
        })),
      ];
    }

    // Add any phone numbers.
    if (this.phoneNumbersForm.value.length > 0) {
      input.object.phone_numbers = {};
      input.object.phone_numbers.data = {};
      input.object.phone_numbers.data = [
        ...this.phoneNumbersForm.value.map((phoneNumber) => ({
          phone_number: {
            data: {
              number: phoneNumber.number,
              type: phoneNumber.type,
            },
          },
        })),
      ];
    }
    this.create.emit(input);
  }

  /**
   * onSave() - Persist all changes to the following entities
   *
   * 1. Client
   * 2. Shared Organizations
   * 3. Addresses
   * 4. Phone Numbers
   *
   * For the Client: check if the entity is modified then update.
   *
   * For Organizations:
   * Add Organization: check if the entity on the form doesn't exist on the client
   * Remove Organization: check if the entity on the client doesn't exist on the form
   *
   * For the Arrays of objects:
   *
   * Create: if the form's array element doesn't exist in the Object.
   * Update: if the form's array element is different than the element in the Object
   * Delete: if an object element exists but does not exist on the form.
   */
  onSave() {
    // Client
    if (this.clientForm.dirty) {
      const clientInput: any = this.clientForm.value;
      this.update.emit(clientInput);
    }

    ////////////////////////////////////////////////////////////
    // Shared Organizations
    ////////////////////////////////////////////////////////////
    // Check if the form is dirty or the length of organizations
    // arrays don't match
    const organizations = this.client.shared_organizations
      ? this.client.shared_organizations.map((org) => org.organization)
      : [];
    const organizationsChanges =
      this.organizationsForm.dirty ||
      this.organizationsForm.value.length !== this.client.shared_clients;
    if (organizationsChanges) {
      const fromForm = this.organizationsForm.value;
      // Check if Org should be added.
      fromForm.map((org) => {
        const found = organizations.find((o) => o.id === org.id);
        if (!found) {
          this.share.emit({
            clientId: this.client.id,
            organizationId: org.id,
          });
        }
      });

      // Check if Org should be removed.
      organizations.map((org) => {
        const found = fromForm.find((o) => o.id === org.id);
        if (!found) {
          this.unShare.emit({
            clientId: this.client.id,
            organizationId: org.id,
          });
        }
      });
    }

    ////////////////////////////////////////////////////////////
    // Addresses
    //
    //  1.  Iterate over the Addresses Form and check if the
    //      address is new or has been modified.
    //
    //  2.  Iterate over the original array of addresses
    //      and delete any that don't exist in the form.
    //
    ////////////////////////////////////////////////////////////
    const addresses = this.client.addresses
      ? this.client.addresses.map((address) => address.address)
      : [];
    const addressChanges =
      this.addressesForm.dirty ||
      this.addressesForm.value.length !== addresses.length;

    if (addressChanges) {
      // Iterate over the form's addresses:
      const fromForm = this.addressesForm.value;
      fromForm.map((address) => {
        const found = addresses.find((a) => a.id === address.id);
        const isEqual = _.isEqual(address, found);
        // If the object isn't found => insert
        // If the object is found and has changed => update
        if (!found) {
          this.addressCreate.emit({
            client_id: this.client.id,
            address,
          });
        } else if (found && !isEqual) {
          // Remove __typename property for update object
          const updateAddress = address;
          if (updateAddress.hasOwnProperty('__typename')) {
            delete updateAddress.__typename;
          }
          this.addressUpdate.emit(updateAddress);
        }
      });
      // Iterate over the original array of phone phone numbers:
      // 2. If the phone number in the array doesn't exist
      //    in the form => DELETE
      addresses.map((address) => {
        const found = fromForm.find((e) => e.id === address.id);
        if (!found) {
          // Manufacture delete mutation object
          this.addressDelete.emit(address);
        }
      });
    }

    ////////////////////////////////////////////////////////////
    // Phone Numbers
    //
    //  1.  Iterate over the Phone Numbers Form and check if the
    //      phone number is new or has been modified.
    //
    //  2.  Iterate over the original array of phone numbers
    //      and delete any that don't exist in the form.
    //
    ////////////////////////////////////////////////////////////
    const phoneNumbers = this.client.phone_numbers
      ? this.client.phone_numbers.map((phoneNumber) => phoneNumber.phone_number)
      : [];
    const phoneNumberChanges =
      this.phoneNumbersForm.dirty ||
      this.phoneNumbersForm.value.length !== phoneNumbers.length;
    if (phoneNumberChanges) {
      // Iterate over the form's phone numbers:
      // 1. If the phone number doesn't exist or isn't the same => upsert
      const numbers = this.phoneNumbersForm.value;
      numbers.map((phoneNumber) => {
        const found = phoneNumbers.find((ph) => ph.id === phoneNumber.id);
        const isEqual = _.isEqual(phoneNumber, found);
        // If the object isn't found or the object in the form is different
        // from the object in the original array => UPSERT
        if (!found) {
          this.phoneNumberCreate.emit({
            client_id: this.client.id,
            phoneNumber,
          });
        } else if (found && !isEqual) {
          // Remove __typename property for update object
          const updatePhoneNumber = phoneNumber;
          if (updatePhoneNumber.hasOwnProperty('__typename')) {
            delete updatePhoneNumber.__typename;
          }
          this.phoneNumberUpdate.emit(updatePhoneNumber);
        }
      });
      // Iterate over the original array of phone numbers:
      // 2. If the phone number in the array doesn't exist
      //    in the form => DELETE
      phoneNumbers.map((phoneNumber) => {
        const found = numbers.find((e) => e.id === phoneNumber.id);
        if (!found) {
          this.phoneNumberDelete.emit(phoneNumber);
        }
      });
    }
  }

  ////////////////////////////////////////////////////////////
  // Won't Implement
  ////////////////////////////////////////////////////////////
  onSaveCaseContact() {
    ////////////////////////////////////////////////////////////
    // Case Contact
    ////////////////////////////////////////////////////////////
    // Check if the form is dirty or the length of caseContact
    // arrays don't match

    /*const caseContact = this.client.client_attorneys
      ? this.client.client_attorneys.map((data) => data.user)
      : [];

    const caseContactChanges =
      this.caseContactForm.dirty ||
      this.caseContactForm.value.length !== caseContact.length;
    if (caseContactChanges) {
      // Iterate over the form's case contact:
      // 1. If the case contact doesn't exist or isn't the same => upsert
      const fromForm = this.caseContactForm.value;
      fromForm.map((value) => {
        const found = caseContact.find((c) => c.id === value.id);
        const isEqual = _.isEqual(value, found);
        // If the object isn't found or the object in the form is different
        // from the object in the original array => UPSERT
        if (!found && value.id) {
          this.caseContactCreate.emit({
            client_id: this.client.id,
            attorney_user_id: value.id
          });
        }
        // else if (found && !isEqual) {
        //   console.log('isEqual', isEqual);
        //   // Remove __typename property for update object
        //   const updateCaseContact = data;
        //   // if (updateCaseContact.hasOwnProperty('__typename')) {
        //   //   delete updateCaseContact.__typename;
        //   // }
        //   this.caseContactUpdate.emit(updateCaseContact);
        // }
      });
    }*/
  }

  onDelete() {
    this.delete.emit(this.client);
  }
}
