import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-email-setting-form',
  templateUrl: './email-setting-form.component.html',
  styleUrls: ['./email-setting-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailSettingFormComponent implements OnChanges{

  @Input() setting = null;
  @Output() caseUpdateAlerts: EventEmitter<any> = new EventEmitter();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = fb.group({
      email: false,
      app: true
    });
  }

  ngOnChanges(): void {
    if (this.setting) {
      this.form.patchValue(this.setting);
    }
  }

  onCaseUpdateToggle(): void {
    this.caseUpdateAlerts.emit(this.form.value);
  }

}
