<form [formGroup]="phoneNumbersForm">
  <div class="row">
    <mat-form-field class="phone-type">
      <mat-select placeholder="Type" formControlName="type" required>
        <mat-option
          *ngFor="let type of phoneTypes"
          [value]="type.value"
          [disabled]="isReadOnly"
          >{{ type.description }}</mat-option
        >
      </mat-select>
      <mat-error
        *ngIf="
          phoneNumbersForm.get('type').invalid &&
          !phoneNumbersForm.get('type').pristine &&
          phoneNumbersForm.get('type').touched
        "
        >Please select a phone number type.</mat-error
      >
    </mat-form-field>
    <mat-form-field class="phone-number">
      <span matPrefix>+1 &nbsp;</span>
      <input
        matInput
        phoneMask
        [preValue]="phoneNumbersForm.get('number').value"
        maxlength="14"
        placeholder="Number"
        formControlName="number"
        required
        [readonly]="isReadOnly"
      />
      <mat-error
        *ngIf="
          phoneNumbersForm.get('number').invalid &&
          !phoneNumbersForm.get('number').pristine &&
          phoneNumbersForm.get('number').touched
        "
        >Phone Number Invalid</mat-error
      >
    </mat-form-field>
  </div>
</form>
