import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './configuration.reducer';

export const selectConfigurationState = createFeatureSelector<
  fromReducer.State
>('configuration');

export const selectConfigurationIds = createSelector(
  selectConfigurationState,
  fromReducer.selectIds
);

export const selectConfigurationEntities = createSelector(
  selectConfigurationState,
  fromReducer.selectEntities
);

export const selectAllConfigurations = createSelector(
  selectConfigurationState,
  fromReducer.selectAll
);

export const selectConfigurationTotal = createSelector(
  selectConfigurationState,
  fromReducer.selectTotal
);

export const selectConfigurationByType = (preferenceType: string) =>
  createSelector(selectAllConfigurations, (entities) => {
    return entities && entities.find((e) => e.type === preferenceType);
  });
