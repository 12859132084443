import gql from 'graphql-tag';

/**
 * Fragments
 */
export const CONFIGURATION_FRAGMENT = gql`
  fragment configurationFragment on configurations {
    id
    type
    body
  }
`;
