import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CLIENT_FRAGMENT} from '@clients/fragments';
import {ORGANIZATION_FRAGMENT} from '@organizations/fragments';

/**
 * Mutations
 */
export const updateUserEula = gql`
  mutation UpdateUserEula($id: uuid!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { eula_accepted: true }) {
      id
    }
  }
`;

export const SaveFeedback = gql`
  mutation saveFeedback($feedback: String) {
    insert_feedback_one(object: { feedback: $feedback }) {
      id
      feedback
    }
  }
`;

export const searchProvider = gql`
  query SearchProvider(
    $search: String
  ) {
    search_organizations(
    args: {search: $search},
    where: {type: {_neq: "corporate"}})
    {
      id
      name
    }
  }
`;

/**
 * Mutations
 */
export const updateProfile = gql`
  mutation updateProfile($id: uuid!, $changes: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $changes) {
      id
      first_name
      last_name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private apollo: Apollo, private http: HttpClient) {
  }

  acceptEula(id: any): Observable<any> {
    return this.apollo.mutate({ mutation: updateUserEula, variables: { id } });
  }

  submitFeedback(feedback: string): Observable<any> {
    return this.apollo
      .mutate({
        mutation: SaveFeedback,
        variables: feedback,
      })
      .pipe(pluck('data', 'insert_feedback_one'));
  }

  submitShareScapeInvite(body: any, token: string): Observable<any> {
    const url = `${environment.apiUrl}/v1/email/suggest_provider`;
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${token}`),
    };
    return this.http.post(url, body, options);
  }

  submitReferPatient(body: any, token: string): Observable<any> {
    const url = `${environment.apiUrl}/v1/email/refer_patient`;
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${token}`),
    };
    return this.http.post(url, body, options);
  }

  searchProviderLocation(search: any): Observable<any> {
    return this.apollo
      .query<any>({
        query: searchProvider,
        variables: {search},
      })
      .pipe(
        pluck('data', 'search_organizations')
      );
  }

  update(input: any): Observable<any> {
    return this.apollo.mutate({
      mutation: updateProfile,
      variables: { id: input.id, changes: input },
    });
  }
}
