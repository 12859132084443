import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ORGANIZATION_FRAGMENT } from '@organizations/fragments';
import { CLIENT_FRAGMENT } from '@clients/fragments';
import { ADDRESS_FRAGMENT } from '@addresses/fragments';
import { PHONE_NUMBER_FRAGMENT } from '@phone-numbers/fragments';
import { USER_FRAGMENT } from '@app/users/fragments';

/**
 * Queries
 */
export const getOrganization = gql`
  query GetOrganization($id: uuid!) {
    organizations_by_pk(id: $id) {
      id
      name
      type
      __typename
      addresses {
        address {
          ...addressFragment
        }
      }
      phone_numbers {
        phone_number {
          ...phoneNumberFragment
        }
      }
      phone_numbers_aggregate {
        aggregate {
           count
        }
      }
      addresses_aggregate {
        aggregate {
          count
        }
      }
      shared_clients_aggregate {
        aggregate {
          count
        }
      }
      organization_users_aggregate{
        aggregate{
          count
        }
      }
    }
  }
  ${ADDRESS_FRAGMENT}
  ${PHONE_NUMBER_FRAGMENT}
`;

export const getOrganizationName = gql`
  query GetOrganizationName($id: uuid!) {
    organizations_by_pk(id: $id) {
      ...organizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const getOrganizationRoot = gql`
  query GetOrganizationRoot($id: uuid!) {
    organizations(where: {id: {_eq: $id}}) {
      root_organization {
        root {
          id
          name
        }
      }
    }
  }
`;

/**
 * Mutations
 */
export const createOrganization = gql`
  mutation CreateOrganization($object: provider_organizations_insert_input!) {
    insert_provider_organizations_one(object: $object) {
      organization {
        id
        name
        type
        phone_numbers(where: { phone_number: { type: { _eq: "work" } } }) {
          phone_number {
            number
            type
          }
        }
      }
    }
  }
`;

export const updateOrganization = gql`
  mutation UpdateOrganization($id: uuid, $name: String, $type: String) {
    update_organizations(
      where: { id: { _eq: $id } }
      _set: { name: $name, type: $type }
    ) {
      affected_rows
      returning {
        id
        name
        type
      }
    }
  }
`;

export const deleteOrganization = gql`
  mutation DeleteOrganization($id: uuid!) {
    delete_organizations_by_pk(id: $id) {
      ...organizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

/**
 * Subscriptions
 */
export const listOrganizationsSubscription = gql`
  subscription ListOrganizationsSubscription {
    organizations(
      order_by: { name: asc }
      where: { providerOrganizationsByOrganizationId: { organization: { type: { _neq: "provider" } } } }
    ) {
      ...organizationFragment
      phone_numbers(where: { phone_number: { type: { _eq: "work" } } }) {
        phone_number {
          ...phoneNumberFragment
        }
      }
      phone_numbers_aggregate {
        aggregate {
          count
        }
      }
      addresses_aggregate {
        aggregate {
          count
        }
      }
      shared_clients_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
  ${PHONE_NUMBER_FRAGMENT}
`;

export const getOrganizationUsersSubscription = gql`
  subscription OrganizationUsersSub($organizationId: uuid) {
    organization_users(
      where: {
        organization_id: { _eq: $organizationId }
        _and: { user: { enabled: { _eq: true } } }
      }
    ) {
      user {
        ...userFragment
        organization_users {
          organization_id
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const getOrganizationClientsSubscription = gql`
  subscription OrganizationClientsSub($organizationId: uuid) {
    shared_clients(
      where: { organization_id: { _eq: $organizationId } }
      order_by: { client: { last_name: asc } }
    ) {
      client {
        ...clientFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  // Used by the clients module
  organizationsSub$: Observable<any> = this.apollo
    .subscribe<any>({ query: listOrganizationsSubscription })
    .pipe(pluck('data', 'organizations'));

  constructor(private apollo: Apollo) {}

  organizations(): Observable<any> {
    return this.apollo
      .subscribe<any>({
        query: listOrganizationsSubscription,
      })
      .pipe(pluck('data', 'organizations'));
  }

  getOrganization(id: string): Observable<any> {
    return this.apollo
      .query({
        query: getOrganization,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(pluck('data', 'organizations_by_pk'));
  }

  getOrganizationName(id: string): Observable<any> {
    return this.apollo
      .query({
        query: getOrganizationName,
        variables: { id },
      })
      .pipe(pluck('data', 'organizations_by_pk'));
  }

  getUsers(organizationId: string): Observable<any> {
    return this.apollo
      .subscribe<any>({
        query: getOrganizationUsersSubscription,
        variables: { organizationId },
      })
      .pipe(
        pluck('data', 'organization_users'),
        map((res: any) => res.map((c) => c.user))
        // tap((res) => console.log(`Users:`, res))
      );
  }

  getClients(organizationId: string): Observable<any> {
    return this.apollo
      .subscribe({
        query: getOrganizationClientsSubscription,
        variables: { organizationId },
      })
      .pipe(
        pluck('data', 'shared_clients'),
        map((res: any) => {
          // Create a array of clients for the selected Org
          // that the user has access to.
          const clients = [];
          res.map((c) => {
            if (c && c.client && c.client.id) {
              clients.push(c.client);
            }
          });
          return clients;
        })
      );
  }

  create(mutation: any): Observable<any> {
    return this.apollo.mutate({
      mutation: createOrganization,
      variables: mutation,
    });
    // .pipe(tap((data) => console.log(`Mutation Create: `, data)));
  }

  update({ id, name, type }): Observable<any> {
    return this.apollo.mutate({
      mutation: updateOrganization,
      variables: { id, name, type },
    });
    // .pipe(tap((data) => console.log(`Mutation Update: `, data)));
  }

  delete(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteOrganization,
      variables: { id },
    });
    // .pipe(tap((data) => console.log(`Mutation Delete: `, data)));
  }

  getRootOrganization(id: string): Observable<any> {
    return this.apollo
      .query({
        query: getOrganizationRoot,
        variables: { id },
      })
      .pipe(pluck('data', 'organizations'));
  }
}
