import {
  Component,
  OnChanges,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CoreFacade } from '@core/+state';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { v4 as uuid } from 'uuid';
import { Screen } from '@core/models';

@Component({
  selector: 'app-transaction-details-ui',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionDetailsComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() screen: Screen;
  @Input() isDetails: boolean;
  @Input() isEdit: boolean;
  @Input() isNew: boolean;
  @Input() downloadTransaction: boolean;
  @Input() createTransaction: boolean;
  @Input() deleteTransaction: boolean;
  @Input() transaction: any;
  @Input() clientId: string;
  @Input() transactionDocuments: any;
  @Input() transactionTypes: any;
  @Input() displayedColumns: string[];

  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() downloadDocument: EventEmitter<any> = new EventEmitter();
  @Output() addDocument: EventEmitter<any> = new EventEmitter();
  @Output() deleteDocument: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  transactionForm: FormGroup;

  dataSource = new MatTableDataSource<any>();

  selectedDocument = null;

  constructor(private fb: FormBuilder, public core: CoreFacade) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges() {
    if (this.transaction && this.transactionForm) {
      this.clientId =
        this.transaction &&
        this.transaction.client_transactions &&
        this.transaction.client_transactions.length === 1
          ? this.transaction.client_transactions[0].client_id
          : null;
      this.transactionForm.patchValue({
        ...this.transaction,
      });
    }

    if (this.transactionDocuments) {
      this.dataSource.data = this.transactionDocuments;
    }
  }

  ngAfterViewInit() {
    // Configure sortingDataAccessor because
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (row, property) => {
      switch (property) {
        case 'displayName': {
          return row.display_name;
        }
        case 'docType': {
          return this.getDocumentTypeDisplay(row);
        }
        case 'fileSize': {
          return row.file_size;
        }
        default:
          return row[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  createForm() {
    this.transactionForm = this.fb.group({
      id: uuid(),
      bill: '0',
      charge: '0',
      check_date: new Date().toISOString(),
      check_number: '',
      company: ['', Validators.required],
      date_of_service: new Date().toISOString(),
      date_posted: new Date().toISOString(),
      description: '',
      final: { value: false, disabled: this.isDetails }, // Recommended way to set this field to disabled.
      location: '',
      payment: '0',
      timestamp: [new Date().toISOString(), Validators.required],
      type: ['', Validators.required],
      transaction_documents: [],
    });
  }

  get transactionId() {
    return this.transactionForm.get('id').value;
  }

  getDownloadCount(doc: any): number {
    return doc &&
      doc.download_histories_aggregate &&
      doc.download_histories_aggregate.aggregate.count
      ? doc.download_histories_aggregate.aggregate.count
      : null;
  }

  getDownloadCountToolTip(doc: any): string {
    let rc = null;
    const count = this.getDownloadCount(doc);
    if (count) {
      rc = `Downloaded ${count} ${count > 1 ? 'times' : 'time'}`;
    }
    return rc;
  }

  documentIcon(doc: any): string {
    let icon = 'insert_drive_file';
    if (typeof doc.file_type !== 'undefined') {
      if (doc.file_type.includes('pdf')) {
        icon = 'picture_as_pdf';
      } else if (doc.file_type.includes('image')) {
        icon = 'photo';
      }
    }
    return icon;
  }

  getDocumentTypeDisplay(row: any): string {
    let found = null;
    if (this.transactionTypes) {
      found = this.transactionTypes.find(
        (t) => t.id.toString() === row.type.toString()
      );
    }
    return found ? found.name : '';
  }

  getTotalSize() {
    return (
      this.transactionDocuments &&
      this.transactionDocuments.length > 0 &&
      this.transactionDocuments
        .map((row) => row.file_size)
        .filter((amount) => !isNaN(amount))
        .reduce((acc, value) => acc + value, 0)
    );
  }

  onAddDocument() {
    if (this.clientId && this.transactionId) {
      this.addDocument.emit({
        clientId: this.clientId,
        transactionId: this.transactionId,
      });
    }
  }
}
