import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-details-ui',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent implements OnInit, OnChanges {
  @Input() roles = [];
  @Input() user = null;
  @Input() organizationId: string = null;
  @Input() phoneTypes: any;
  @Input() header = 'User Information';
  @Input() isReadOnly = true;
  @Input() isNew = false;
  @Input() isEdit = false;
  @Input() isProfile = false;
  @Input() type: string = null;

  @Output() createUser: EventEmitter<any> = new EventEmitter();
  @Output() updateUserRole: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  @Output() phoneNumberCreate: EventEmitter<any> = new EventEmitter();
  @Output() phoneNumberUpdate: EventEmitter<any> = new EventEmitter();
  @Output() phoneNumberDelete: EventEmitter<any> = new EventEmitter();

  userForm: FormGroup;
  phoneNumbers: FormGroup;
  userGroups: FormGroup;

  providerUserGroups = [];

  phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();
    this.providerUserGroups = [
      { value: 'provider_user', description: 'User' },
      { value: 'provider_admin', description: 'Admin' },
    ];
  }

  ngOnChanges(): void {
    if (this.user && this.userForm) {
      this.userForm.patchValue(this.user);

      if (this.user.user_phone_numbers) {
        this.phoneNumbers.patchValue(this.user.user_phone_numbers);
        this.displayPhoneNumbers();
      }
      if (this.user.provider_user) {
        // tslint:disable-next-line:variable-name
        const provider_user_groups = this.user.provider_user?.group?.id;
        this.userGroups.patchValue({ provider_user_groups });
      }
    }
  }

  createForm() {
    this.userForm = this.fb.group({
      id: '',
      username: ['', [Validators.required, Validators.email]],
      first_name: '',
      last_name: '',
      enabled: '',
      last_login: '',
    });

    this.phoneNumbers = this.fb.group({
      phoneNumbers: this.fb.array([]),
    });

    this.userGroups = this.fb.group({
      provider_user_groups: ['', Validators.required],
    });
  }

  get userId() {
    return this.userForm.get('id').value;
  }

  get username() {
    return this.userForm.get('username').value;
  }

  get userGroup() {
    const group = this.userGroups.get('provider_user_groups').value;
    if (group === 'provider_admin') {
      return ['provider_user', 'provider_admin'];
    } else if (group === 'provider_user') {
      return ['provider_user'];
    }
    return null;
  }

  ////////////////////////////////////
  // Phone Numbers Form
  ////////////////////////////////////
  get phoneNumbersForm() {
    return this.phoneNumbers.get('phoneNumbers') as FormArray;
  }

  displayPhoneNumbers() {
    for (let i = this.phoneNumbersForm.length; i >= 0; i--) {
      this.onDeletePhoneNumberItem(i);
    }

    this.user.user_phone_numbers.forEach((phone) => {
      this.onAddPhoneNumberItem(phone.phone_number);
    });
  }

  onAddPhoneNumberItem(phoneNumber?: any) {
    const newNumber = this.fb.group({
      __typename: phoneNumber ? phoneNumber.__typename : '',
      id: [phoneNumber ? phoneNumber.id : ''],
      type: [phoneNumber ? phoneNumber.type : 'work', Validators.required],
      number: [
        phoneNumber ? phoneNumber.number : '',
        [Validators.required, Validators.pattern(this.phoneRegex)],
      ],
    });

    this.phoneNumbersForm.push(newNumber);
  }

  onDeletePhoneNumberItem(i: number) {
    this.phoneNumbersForm.removeAt(i);
  }

  onCreateUser() {
    // tslint:disable-next-line:variable-name
    const provider_user_groups = this.userGroup;
    const newUser = {
      username: this.username,
      organizationId: this.organizationId,
      ...(provider_user_groups && { provider_user_groups }),
    };
    this.createUser.emit(newUser);
  }

  onUpdateUserRole() {
    // tslint:disable-next-line:variable-name
    const provider_user_groups = this.userGroup;
    const user = {
      userId: this.userId,
      username: this.username,
      ...(provider_user_groups && {provider_user_groups}),
    };
    this.updateUserRole.emit(user);
  }

  /**
   * onSave() - Persist all changes to the following entities
   *
   * 1. User
   * 2. Phone Numbers
   *
   * For the User: check if the entity is modified then update.
   *
   * For the Phone Number Objects:
   *
   * Create: if the form's array element doesn't exist in the Object.
   * Update: if the form's array element is different than the element in the Object
   * Delete: if an object element exists but does not exist on the form.
   */
  onSave() {
    // User
    if (this.userForm.dirty) {
      const userInput: any = this.userForm.value;
      this.update.emit(userInput);
    }

    ////////////////////////////////////////////////////////////
    // Phone Numbers
    //
    //  1.  Iterate over the Phone Numbers Form and check if the
    //      phone number is new or has been modified.
    //
    //  2.  Iterate over the original array of phone numbers
    //      and delete any that don't exist in the form.
    //
    ////////////////////////////////////////////////////////////
    const phoneNumbers = this.user.user_phone_numbers
      ? this.user.user_phone_numbers.map(
          (phoneNumber) => phoneNumber.phone_number
        )
      : [];
    const phoneNumberChanges =
      this.phoneNumbersForm.dirty ||
      this.phoneNumbersForm.value.length !== phoneNumbers.length;
    if (phoneNumberChanges) {
      // Iterate over the form's phone numbers:
      // 1. If the phone number doesn't exist or isn't the same => upsert
      const numbers = this.phoneNumbersForm.value;
      numbers.map((phoneNumber) => {
        const found = phoneNumbers.find((ph) => ph.id === phoneNumber.id);
        const isEqual = _.isEqual(phoneNumber, found);
        // If the object isn't found or the object in the form is different
        // from the object in the original array => UPSERT
        if (!found) {
          this.phoneNumberCreate.emit({
            user_id: this.user.id,
            phoneNumber,
          });
        } else if (found && !isEqual) {
          if (phoneNumber.hasOwnProperty('__typename')) {
            delete phoneNumber.__typename;
          }
          this.phoneNumberUpdate.emit(phoneNumber);
        }
      });
      // Iterate over the original array of phone numbers:
      // 2. If the phone number in the array doesn't exist
      //    in the form => DELETE
      phoneNumbers.map((phoneNumber) => {
        const found = numbers.find((e) => e.id === phoneNumber.id);
        if (!found) {
          this.phoneNumberDelete.emit(phoneNumber);
        }
      });
    }
  }
}
