<mat-table
  [dataSource]="dataSource"
  matSort
  matSortDisableClear
  [matSortActive]="organizationsListTableSort.active"
  [matSortDirection]="organizationsListTableSort.direction"
>
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      ID
    </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.id }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Name
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="phoneNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Phone
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{
        row.phone_numbers && row.phone_numbers.length > 0
          ? row.phone_numbers[0].phone_number.number
          : ''
      }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button
        mat-icon-button
        color="primary"
        (click)="details.emit(row)"
        matTooltip="Details"
        [matTooltipShowDelay]="750"
      >
        <mat-icon>details</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="edit.emit(row)"
        matTooltip="Edit"
        [matTooltipShowDelay]="750"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        color="accent"
        (click)="createUser.emit(row)"
        matTooltip="Create User"
        [matTooltipShowDelay]="750"
      >
        <mat-icon>person_add</mat-icon>
      </button>
      <button
        [disabled]=""
        mat-icon-button
        color="warn"
        (click)="delete.emit(row)"
        matTooltip="Delete"
        [matTooltipShowDelay]="750"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{
      selected: selectedOrganization && selectedOrganization.id === row.id
    }"
    (click)="selectItem.emit(row)"
    matRipple
  >
  </mat-row>
</mat-table>
<mat-paginator
  [showFirstLastButtons]="true"
  [pageSizeOptions]="[5, 10, 25, 50, 100]"
  [pageSize]="organizationsListTableInformation.pageSize"
  [pageIndex]="organizationsListTableInformation.pageIndex"
  [length]="count"
></mat-paginator>
