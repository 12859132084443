import gql from 'graphql-tag';

/**
 * Fragments
 */
export const CLIENT_DEDUPED_FRAGMENT = gql`
  fragment clientDedupedFragment on clients_deduped {
    id
    first_name
    last_name
    middle_initial
    gender
    email
    date_of_birth
    date_of_injury
    balance
  }
`;
