import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CoreFacade } from '@core/+state';
import { Screen } from '@core/models';
import { MatSelectChange } from '@angular/material/select';
import { ConfigurationService } from '@configuration/services/configuration.service';
import {convertUTCStringToLocalDate} from '@core/utils';

@Component({
  selector: 'app-transaction-payment-details',
  templateUrl: './transaction-payment-details.component.html',
  styleUrls: ['./transaction-payment-details.component.scss'],
})
export class TransactionPaymentDetailsComponent implements OnInit, OnChanges {
  @Input() screen: Screen;
  @Input() isDetails: boolean;
  @Input() isEdit: boolean;
  @Input() isNew: boolean;
  @Input() createTransaction: boolean;
  @Input() deleteTransaction: boolean;
  @Input() transaction: any;
  @Input() clientId: string;
  @Input() transactionTypes: any;

  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  transactionForm: FormGroup;

  selectedDocument = null;

  regNumber = '^[0-9]+(\.[0-9]{1,2})?$';

  constructor(
    private fb: FormBuilder,
    public core: CoreFacade,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges() {
    if (this.transaction && this.transactionForm) {
      this.clientId =
        this.transaction &&
        this.transaction.client_transactions &&
        this.transaction.client_transactions.length === 1
          ? this.transaction.client_transactions[0].client_id
          : null;
      // tslint:disable-next-line:variable-name
      const _transaction = this.transformTransaction(this.transaction);
      this.transactionForm.patchValue(_transaction);
      // this.transactionForm.patchValue({
      //   ...this.transaction,
      // });
    }
  }

  createForm() {
    this.transactionForm = this.fb.group({
      id: '',
      check_date: new Date().toISOString(),
      check_number: '',
      company: '',
      date_posted: new Date().toISOString(),
      description: '',
      final: { value: false, disabled: this.isDetails }, // Recommended way to set this field to disabled.
      payment: [0, Validators.pattern(this.regNumber)],
      // timestamp: [new Date().toISOString(), Validators.required],
      updated_at: [new Date().toISOString(), Validators.required],
      type: ['', Validators.required],
      type_name: '',
    });
  }

  /**
   * Keep the type and type_name fields in sync
   *
   * @param changedValue: the id of the type
   */
  setTypeName(changedValue: MatSelectChange) {
    this.transactionForm
      .get('type_name')
      .setValue(
        this.configurationService.getTypeName(
          changedValue.value,
          this.transactionTypes
        )
      );
  }

  // transform transaction object
  transformTransaction(transaction) {
    // tslint:disable-next-line:variable-name
    const _transaction = {...transaction};
    _transaction.date_posted = convertUTCStringToLocalDate(transaction.date_posted, '-');
    _transaction.check_date = convertUTCStringToLocalDate(transaction.check_date, '-');
    return _transaction;
  }
}
