<form [formGroup]="myForm">
  <mat-card>
    <mat-card-title>
      <div class="header mat-elevation-z6">
        <div class="center-title">Change Password</div>
      </div>
    </mat-card-title>
    <mat-card-content>
      <mat-form-field>
        <input
          matInput
          [type]="hideCurrent ? 'password' : 'text'"
          placeholder="Current password"
          formControlName="currentPassword"
          required
          autocomplete="off"
        />
        <mat-icon matSuffix (click)="hideCurrent = !hideCurrent">{{
          hideCurrent ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-error
          *ngIf="
            myForm.get('currentPassword').hasError('required') &&
            !myForm.get('currentPassword').hasError('minlength')
          "
        >
          Please enter a your current password.
        </mat-error>
        <mat-error *ngIf="myForm.get('currentPassword').hasError('minlength')">
          Password must be at least 8 characters.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          [type]="hideNew ? 'password' : 'text'"
          placeholder="New password"
          formControlName="password"
          required
          autocomplete="off"
        />
        <mat-icon matSuffix (click)="hideNew = !hideNew">{{
          hideNew ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-error
          *ngIf="
            myForm.get('password').hasError('required') &&
            !myForm.get('password').hasError('minlength')
          "
        >
          Please enter a new password.
        </mat-error>
        <mat-error *ngIf="myForm.get('password').hasError('minlength')">
          Password must be at least 8 characters.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          [type]="hideConfirm ? 'password' : 'text'"
          placeholder="Confirm password"
          formControlName="confirmPassword"
          [errorStateMatcher]="matcher"
          required
          autocomplete="off"
        />
        <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
          hideConfirm ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        <mat-error *ngIf="myForm.hasError('notSame')">
          Passwords do not match
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button
        type="button"
        color="default"
        mat-raised-button
        routerLink="/profile"
      >
        CANCEL
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="onChangePassword()"
        [disabled]="myForm.invalid"
      >
        CHANGE PASSWORD
      </button>
    </mat-card-actions>
  </mat-card>
</form>
