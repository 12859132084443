import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromActions from './phone-numbers.actions';

@Injectable({ providedIn: 'root' })
export class PhoneNumbersFacade {
  constructor(private store: Store<any>) {}

  createOrganizationPhoneNumber(input: any) {
    this.store.dispatch(fromActions.createOrganizationPhoneNumber({ input }));
  }

  createClientPhoneNumber(input: any) {
    this.store.dispatch(fromActions.createClientPhoneNumber({ input }));
  }

  createUserPhoneNumber(input: any) {
    this.store.dispatch(fromActions.createUserPhoneNumber({ input }));
  }

  update(input: any) {
    this.store.dispatch(fromActions.updatePhoneNumber({ input }));
  }

  delete(input: any) {
    this.store.dispatch(fromActions.deletePhoneNumber({ input }));
  }
}
