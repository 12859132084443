import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './core.actions';
import * as fromStorage from './core.storage';

export interface State {
  themeName: string;
  isDark: boolean;
  logoLoaded: boolean;
  searchOrganization: any;
  profileUpdateReminder: any;
}

export const initialState: State = {
  // themeName: fromStorage.getThemeLocal(),
  // isDark: fromStorage.isDarkThemeActiveLocal(),
  themeName: 'app-theme-light',
  isDark: false,
  logoLoaded: null,
  searchOrganization: null,
  profileUpdateReminder: fromStorage.getProfileUpdateReminder(),
};

const layoutReducer = createReducer(
  initialState,
  on(fromActions.setTheme, (state, { theme }) => ({
    ...state,
    theme,
  })),
  on(fromActions.setThemeName, (state, { themeName, isDark }) => {
    fromStorage.upsertThemeLocal(themeName);
    return { ...state, themeName, isDark };
  }),
  on(fromActions.setLogoLoaded, (state, { logoLoaded }) => ({
    ...state,
    logoLoaded,
  })),
  on(fromActions.clearState, (state) => ({
    ...state,
  })),

  /**
   * Provider location
   */
  on(fromActions.searchOrganizationSuccess, (state, {searchRes}) => ({
    ...state,
    searchOrganization: searchRes ? searchRes : null,
  })),

  /**
   * Profile Update Reminder
   */

  on(fromActions.profileUpdateReminder, (state, { value }) => {
    fromStorage.setProfileUpdateReminder(value);
    return { ...state, profileUpdateReminder: value };
  }),
  /////////////////////////////////////////////////////
  // Errors
  /////////////////////////////////////////////////////
  on(fromActions.logError, (state, { error }) => ({
    ...state,
    error,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return layoutReducer(state, action);
}
