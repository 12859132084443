<div class="container">
  <mat-card>
    <mat-card-content>
      <div class="title">
        <img src="../../../../favicon.ico" width="35px" height="35px"/>
        <span>
        ShareScape
        </span>
      </div>
      <div class="mage">
        🧙
      </div>
      <div class="magic-link">
        What's a magic link?
      </div>
      <div class="content">
        We'll email you a secure link which will log you into the app. An alternative to using your password.
      </div>
      <div class="footer">
        <button type="submit" mat-flat-button
                color="primary" (click)="okay()">
          Okay!
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
