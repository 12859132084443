export function isFileTypeAllowed(
  file: File,
  allowedFileTypes: string[]
): boolean {
  if (allowedFileTypes.length === 0) {
    return true;
  }
  const ext = file.name.split('.')[file.name.split('.').length - 1];
  return allowedFileTypes.indexOf(ext.toLocaleUpperCase()) !== -1;
}

export function readFile(file: File) {
  const reader = new FileReader();
  reader.onload = function(e) {
    const f = <FileReader>e.target;
  }.bind(this);
  reader.readAsDataURL(file);
}

export function processFilesList(
  files: any,
  allowTypes: string[],
  validFiles: File[] = [],
  invalidFiles: File[] = []
) {
  // Iterate over the files and check if they
  // have a valid extension
  for (let i = 0; i < files.length; i++) {
    if (isFileTypeAllowed(files[i], allowTypes)) {
      // Push and Read the file.
      validFiles.push(files[i]);
      readFile(files[i]);
    } else {
      // push the file name on the invalid files array
      invalidFiles.push(files[i]);
    }
  }
}
