import { createAction, props } from '@ngrx/store';

export const loadByOrganizationId = createAction(
  '[Configurations] Load Configuration By Organization ID',
  props<{ id: string }>()
);

export const loadSuccess = createAction(
  '[Configurations] Load Configurations Success',
  props<{ configurations: any }>()
);

export const loadError = createAction(
  '[Configurations] Load Configurations Error',
  props<{ error: any }>()
);

export const insertConfiguration = createAction(
  '[Configurations] Insert Configuration',
  props<{ input: any }>()
);

export const insertConfigurationSuccess = createAction(
  '[Configurations] Insert Configuration Success',
  props<{ configuration: any }>()
);

export const insertConfigurationError = createAction(
  '[Configurations] Insert Configuration Error',
  props<{ error: any }>()
);

export const updateConfiguration = createAction(
  '[Configurations] Update Configuration',
  props<{ input: any }>()
);

export const updateConfigurationSuccess = createAction(
  '[Configurations] Update Configuration Success',
  props<{ configuration: any }>()
);

export const updateConfigurationError = createAction(
  '[Configurations] Update Configuration Error',
  props<{ error: any }>()
);

export const deleteConfiguration = createAction(
  '[Configurations] Delete Configuration',
  props<{ input: any }>()
);

export const deleteConfigurationSuccess = createAction(
  '[Configurations] Delete Configuration Success',
  props<{ configuration: any }>()
);

export const deleteConfigurationError = createAction(
  '[Configurations] Delete Configuration Error',
  props<{ error: any }>()
);

export const clearState = createAction('[Configurations] Clear State');
