<form [formGroup]="form" (submit)="send()">
  <div mat-dialog-title>
    <span>
           <h3 style="text-align: center; margin: 0">Welcome to ShareScape!</h3>
           <h3>We'll just need a little bit of information to get you started.</h3>
        </span>
  </div>
  <mat-dialog-content class="container">
    <mat-form-field appearance="outline">
      <mat-label>First Name</mat-label>
      <input matInput placeholder="" formControlName="first_name" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Last Name</mat-label>
      <input matInput placeholder="" formControlName="last_name" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Direct Line (Optional)</mat-label>
      <input matInput placeholder=""
             maxlength="14"
             phoneMask
             [preValue]="form.get('phoneNumber').value"
             formControlName="phoneNumber" />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-stroked-button
      color="primary"
      type="button"
      (click)="send()"
      [disabled]="form.invalid"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</form>