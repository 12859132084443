import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {EmailSettingsService} from '../services';
import * as fromActions from './email-settings.actions';
import {LoggerService} from '../../core/services';
import {PreferencesFacade} from '@preferences/+state';

@Injectable()
export class EmailSettingsEffects {
  @Effect()
  caseUpdateAlerts: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.caseUpdateAlerts),
    mergeMap(({ input }) => {
      return this.emailSettingService.linkUserEmailPreference(input).pipe(
        map((mutation) =>
          fromActions.caseUpdateAlertsSuccess({ mutation })
        ),
        catchError((error) =>
          of(fromActions.caseUpdateAlertsError({ error }))
        )
      );
    })
  );

  @Effect({ dispatch: false })
  caseUpdateAlertsSuccess$ = this.actions$.pipe(
    ofType(fromActions.caseUpdateAlertsSuccess),
    map(({ mutation }) => {
      const message = 'Case alert email preferences updated successfully.';
      this.preferences.loadPreferences();
      this.logger.info(message, mutation);
    })
  );

  @Effect({ dispatch: false })
  caseUpdateAlertsError$ = this.actions$.pipe(
    ofType(fromActions.caseUpdateAlertsError),
    map(({ error }) => {
      const message =
        'An error occurred updating case alert email preferences.';
      this.logger.error(message, error);
    })
  );

  constructor(
    private actions$: Actions,
    private emailSettingService: EmailSettingsService,
    private logger: LoggerService,
    private preferences: PreferencesFacade
  ) {}
}
