import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as fromActions from './preferences.actions';

// create Entity adapter
export const preferencesAdapter = createEntityAdapter<any>();
export interface State extends EntityState<any> {}

export const initialState: State = preferencesAdapter.getInitialState({});

const preferencesReducer = createReducer(
  initialState,
  on(fromActions.loadSuccess, (state, { preferences }) => {
    // Merge user preferences with the default preferences.
    //
    // Start with the default preferences.
    let userPreferences = preferences.default;

    // Loop over the user preferences.
    preferences.user.map((userPreference) => {
      // Filter out the default preference when it exists as a user preference.
      userPreferences = userPreferences.filter(
        (u) => u.type !== userPreference.preference.type
      );
      // Add the user preference to the list of userPreferences
      userPreferences.push(userPreference.preference);
    });
    return preferencesAdapter.setAll(userPreferences, state);
  }),
  on(fromActions.insertPreferenceSuccess, (state, { preference }) => {
    // Replace a default preference with the mutation preference.
    //
    // Get preferences from the store and filter out the existing
    // preference based on the type of the incoming preference.
    const preferences = Object.entries(state.entities)
      .map((e) => e[1])
      .filter((e) => e.type !== preference.type);

    // Add the new preference to the filtered list.
    preferences.push(preference);

    // replace all the preferences with the new list
    return preferencesAdapter.setAll(preferences, state);
  }),
  on(fromActions.updatePreferenceSuccess, (state, { preference }) => {
    const id = preference.id;
    const changes = { body: preference.body };
    return preferencesAdapter.updateOne({ id, changes }, state);
  }),
  on(
    fromActions.loadError,
    fromActions.insertPreferenceError,
    fromActions.updatePreferenceError,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(fromActions.clearState, (state) => ({ ...initialState }))
);

export function reducer(state: State | undefined, action: Action) {
  return preferencesReducer(state, action);
}

// Create the default selectors
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = preferencesAdapter.getSelectors();
