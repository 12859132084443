<div *ngIf="authenticated; else notLoggedIn">
  <!-- User is logged in -->
  <mat-toolbar color="primary" class="header">
    <a (click)="toggleSideNav.emit()" class="margin-top-right">
      <mat-icon>menu</mat-icon>
    </a>
<!--    <span class="heading" (click)="hiddenAction.emit()">ShareScape Portal</span>-->
    <a routerLink="/clients">
      <img
        *ngIf="organizationLogoUrl"
        class="sidenav-img"
        [src]="organizationLogoUrl"
        alt="Portal"
        (load)="imageLoaded()"
      />
    </a>
    <span class="fill-remaining-space"></span>
    <div
      class="organization-names"
      [ngClass]="{
        'hide-column': screen.device === 'handset' && screen.portrait
      }"
    >
      {{ organizationNames }}
    </div>

    <app-theme-picker></app-theme-picker>

<!--    <a mat-icon-button *ngIf="roles.includes('provider_admin') || roles.includes('user_provider')"-->
<!--       [matMenuTriggerFor]="menuMore" aria-label="Profile">-->
<!--      <mat-icon>more_vert</mat-icon>-->
<!--    </a>-->

<!--    <mat-menu #menuMore="matMenu">-->

<!--      <button mat-menu-item [matMenuTriggerFor]="switchOrg"-->
<!--              disabled="{{(organizationContext && organizationContext.length > 0) ? 'false' : 'true'}}">-->
<!--        <mat-icon>location_city</mat-icon>-->

<!--        <span>Switch Organization</span>-->
<!--      </button>-->
<!--      <mat-menu #switchOrg="matMenu">-->
<!--        <button mat-menu-item *ngFor="let org of organizationContext"-->
<!--                (click)="selectProvider.emit(org)">-->
<!--          <span>{{org?.name}}</span>-->
<!--        </button>-->
<!--      </mat-menu>-->
<!--    </mat-menu>-->

       <a mat-icon-button aria-label="help" [matTooltip]="'Help Center'" [href]="helpCenter" target="_blank">
         <mat-icon>help</mat-icon>
       </a>

        <a mat-icon-button [matMenuTriggerFor]="accountBox" aria-label="Profile">
            <mat-icon>account_box</mat-icon>
        </a>
        <mat-menu #accountBox="matMenu">
          <button mat-menu-item routerLink="/profile">
            <mat-icon>account_circle</mat-icon>
            <span>My Profile</span>
          </button>

          <button mat-menu-item [matMenuTriggerFor]="switchOrg"
                  *ngIf="roles.includes('provider_admin') || roles.includes('user_provider')"
                  disabled="{{(organizationContext && organizationContext.length > 0) ? 'false' : 'true'}}">
            <mat-icon>location_city</mat-icon>

            <span>Switch Organization</span>
          </button>
          <mat-menu #switchOrg="matMenu">
            <ng-container *ngFor="let org of organizationContext; let i=index">
              <button mat-menu-item
                      (click)="selectProvider.emit(org)">
                <span>{{org?.name}}</span>
<!--                <span [ngClass]="{'parentOrg': i == 0 && org.parent_id == null}">{{org?.name}}</span>-->
              </button>
              <mat-divider *ngIf="i == 0 && org.parent_id == null"></mat-divider>
            </ng-container>
          </mat-menu>

          <button mat-menu-item (click)="logout.emit()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
  </mat-toolbar>
</div>
<ng-template #notLoggedIn>
  <!-- User is not logged in -->
  <mat-toolbar color="primary" class="header">
    <a (click)="toggleSideNav.emit()" class="margin-top-right">
      <mat-icon>menu</mat-icon>
    </a>
    <!-- <span *ngIf="organizationName">{{ organizationName }}</span> -->
    &nbsp;Portal <span class="fill-remaining-space"></span>
    <mat-icon [matMenuTriggerFor]="menuLogin">account_circle</mat-icon>
    <mat-menu #menuLogin="matMenu">
      <a mat-menu-item (click)="login.emit()">Login</a>
    </mat-menu>
  </mat-toolbar>
</ng-template>
<mat-progress-bar
  mode="indeterminate"
  [ngClass]="{ hide: !loading && !compilingPDFs }"
></mat-progress-bar>
