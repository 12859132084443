import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      return value.charAt(0) === '-'
        ? '(' + value.substring(1, value.length) + ')'
        : value;
    }
  }
}
