import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-suggest-provider',
  template: `
    <form class="share-form" [formGroup]="form">
      <div mat-dialog-title>
<!--        <mat-icon class="close" (click)="cancel()">close</mat-icon>-->
        <span class="title">
           <h2>What medical provider would you like to see on ShareScape? Tell us a little about them and we'll reach out!</h2>
        </span>
      </div>
      <mat-dialog-content class="content">
        <p>Give us a few details and we'll reach out! :)</p>
        <mat-form-field appearance="outline" class="form-width">
          <mat-label>Office / Facility Name</mat-label>
          <input matInput placeholder="" formControlName="faculty_name">
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-width">
          <mat-label>Is there someone specific we can reach out to? (Optional)</mat-label>
          <input matInput placeholder="" formControlName="contact_name" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-width">
          <mat-label>Facility / Contact Email or Phone</mat-label>
          <input matInput placeholder="" formControlName="contact_info" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-width">
          <mat-label>Anything else we should know?</mat-label>
          <textarea
            rows="5"
            matInput
            placeholder=""
            formControlName="additional_info"
          ></textarea>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions style="display: block">
        <button
          [disabled]="form.invalid"
          mat-stroked-button
          color="primary"
          type="button"
          (click)="send()"
        >
          Submit
        </button>
        <button class="close"
          mat-stroked-button
          color="warn"
          type="button"
          (click)="cancel()"
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  `,
  styles: [
    `
      .share-form {
        min-width: 150px;
        max-width: 500px;
        width: 100%;
      }

      .form-width {
        width: 100%;
      }

      .close {
        float: right;
      }

      .title h2 {
        font-size: 18px;
        text-align: center;
      }

      .content {
        margin-top: 50px;
      }

      .marginBottom {
        margin-bottom: 0px;
      }
    `,
  ],
})
export class SuggestProviderComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SuggestProviderComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      faculty_name: ['', [Validators.required]],
      contact_name: [''],
      contact_info: ['', [Validators.required]],
      additional_info: [''],
    });
  }

  get shareInviteData() {
    return this.form.value;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  send() {
    this.dialogRef.close(this.shareInviteData);
  }
}
