import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './preferences.reducer';

export const selectPreferencesState = createFeatureSelector<fromReducer.State>(
  'preferences'
);

export const selectPreferenceIds = createSelector(
  selectPreferencesState,
  fromReducer.selectIds
);

export const selectPreferenceEntities = createSelector(
  selectPreferencesState,
  fromReducer.selectEntities
);

export const selectAllPreferences = createSelector(
  selectPreferencesState,
  fromReducer.selectAll
);

export const selectPreferenceTotal = createSelector(
  selectPreferencesState,
  fromReducer.selectTotal
);

export const selectPreferenceByType = (preferenceType: string) =>
  createSelector(selectAllPreferences, (entities) => {
    return entities && entities.find((e) => e.type === preferenceType);
  });
