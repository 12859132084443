import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import {EmailSettingsEffects} from './+state/email-settings.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([EmailSettingsEffects])],
})
export class EmailSettingsModule {}
