import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-magic-link-info',
  templateUrl: './magic-link-info.component.html',
  styleUrls: ['./magic-link-info.component.scss']
})
export class MagicLinkInfoComponent implements OnInit {

  constructor(
    private ref: MatDialogRef<MagicLinkInfoComponent>
  ) {
  }

  ngOnInit(): void {
  }

  okay() {
    this.ref.close(true);
  }

}
