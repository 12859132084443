// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  environment: 'QA',
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBnCV3mpDgF-xmUxCCvLQmMqgFxo5q5SLI',
    authDomain: 'sharescapeio-qa.firebaseapp.com',
    databaseURL: 'https://sharescapeio-qa.firebaseio.com',
    projectId: 'sharescapeio-qa',
    storageBucket: 'sharescapeio-qa.appspot.com',
    messagingSenderId: '445343368407',
    appId: '1:445343368407:web:0ddf9bd55bc39563759c92',
    measurementId: 'G-6883C8ETV3',
  },
  hasura: {
    https: 'https://qa-api.sharescape.io/v1/graphql',
    wss: 'wss://qa-api.sharescape.io/v1/graphql',
  },
  apiUrl: 'https://qa-api.sharescape.io',
  logToConsole: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
