<ng-container *ngIf="screen">
  <div class="container">
    <h4>
      Balance:
      {{ balance | currency: 'USD':'symbol' | customCurrency }}
    </h4>
    <mat-table
      matSort
      matSortDisableClear
      [dataSource]="dataSource"
      [matSortActive]="transactionsListTableSort.active"
      [matSortDirection]="transactionsListTableSort.direction"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            *ngIf="downloadFile && allDocumentsTotal() > 0"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && areAllSelected()"
            [indeterminate]="selection.hasValue() && !areAllSelected()"
            matTooltip="Toggle All Checkboxes"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            *ngIf="
              downloadFile &&
              row.transaction_bill_documents &&
              row.transaction_bill_documents.length > 0
            "
            (click)="$event.stopPropagation()"
            (change)="$event ? toggleBoth(row) : null"
            [checked]="areBothSelected(row)"
            [indeterminate]="isOneSelected(row)"
            matTooltip="Toggle Checkboxes"
          >
          </mat-checkbox>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
      </ng-container>

      <!-- File Type Icon Column -->
      <ng-container matColumnDef="fileType">
        <mat-header-cell
          *matHeaderCellDef
          [ngClass]="{
            'hide-column': screen.device === 'handset' && screen.portrait
          }"
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [ngClass]="{
            'hide-column': screen.device === 'handset' && screen.portrait
          }"
        >
          <mat-icon
            [matBadge]="getDownloadBadge(row)"
            [matTooltip]="getDownloadCountToolTip(row)"
            >{{ documentIcon(row) }}</mat-icon
          >
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          [ngClass]="{
            'hide-column': screen.device === 'handset' && screen.portrait
          }"
        ></mat-footer-cell>
      </ng-container>

      <!-- Transaction Type Name -->
      <ng-container matColumnDef="type_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Type</mat-header-cell
        >
        <mat-cell *matCellDef="let row">{{ row.type_name }} </mat-cell>
        <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
      </ng-container>

      <!-- Date Of Service -->
      <ng-container matColumnDef="dateOfService">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [ngClass]="{ 'hide-column': screen.device === 'handset' }"
          >Date Of Service</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [ngClass]="{ 'hide-column': screen.device === 'handset' }"
          >{{ row.date_of_service | dateValidator: 'MM/dd/yy' }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
      </ng-container>

      <!-- Date Posted -->
      <ng-container matColumnDef="datePosted">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [ngClass]="{ 'hide-column': screen.device === 'handset' }"
          >Date Posted</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [ngClass]="{ 'hide-column': screen.device === 'handset' }"
          >
            {{ row.date_posted | dateValidator:'MM/dd/yy' }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [ngClass]="{
            'hide-column': screen.device === 'handset' && screen.portrait
          }"
          >Details</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [ngClass]="{
            'hide-column': screen.device === 'handset' && screen.portrait
          }"
          >{{ row.location }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef style="justify-content: flex-end"
          >Balance:</mat-footer-cell
        >
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [ngClass]="{
            'hide-column': screen.device === 'handset' && screen.portrait
          }"
          >Amount $</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          [ngClass]="{
            'hide-column': screen.device === 'handset' && screen.portrait
          }"
          >{{ row.amount | currency: 'USD':'symbol' | customCurrency }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          {{ balance | currency: 'USD':'symbol' | customCurrency }}</mat-footer-cell
        >
      </ng-container>

      <!-- Action Items -->
      <ng-container matColumnDef="actions">
        <mat-header-cell
          *matHeaderCellDef
          style="justify-content: center"
          [ngStyle]="{
            'min-width.px':
              ((editTransaction ? 1 : 0) +
                (detailsTransaction ? 1 : 0) +
                (deleteTransaction ? 1 : 0)+
                (downloadFile ? 1 : 0)) *
                40 +
              120
          }"
        >
          Actions
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [ngStyle]="{
            'min-width.px':
              ((editTransaction ? 1 : 0) +
                (detailsTransaction ? 1 : 0) +
                (deleteTransaction ? 1 : 0) +
                (downloadFile ? 1 : 0)) *
                40 +
              120
          }"
        >
          <a
            *ngIf="detailsTransaction"
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Details"
            [matTooltipShowDelay]="750"
            (click)="details.emit(row)"
          >
            <mat-icon>details</mat-icon>
          </a>
          <a
            *ngIf="editTransaction"
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Edit"
            (click)="edit.emit(row)"
            [matTooltipShowDelay]="750"
          >
            <mat-icon>edit</mat-icon>
          </a>
          <div *ngIf="downloadFile && getDocument(row, 'transactionBill')">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? toggleItem(row, 'transactionBill') : null"
              [checked]="isSelected(row, 'transactionBill')"
              matTooltip="Select To Compile Bill"
            >
            </mat-checkbox>
            <a
              type="button"
              mat-icon-button
              color="accent"
              matTooltip="Preview Bill"
              (click)="stream.emit(getDocument(row, 'transactionBill'))"
            >
              <mat-icon>monetization_on</mat-icon>
            </a>
          </div>
          <div *ngIf="downloadFile && getDocument(row, 'transactionNote')">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? toggleItem(row, 'transactionNote') : null"
              [checked]="isSelected(row, 'transactionNote')"
              matTooltip="Select To Compile Note"
            >
            </mat-checkbox>
            <a
              type="button"
              mat-icon-button
              color="default"
              matTooltip="Preview Notes"
              (click)="stream.emit(getDocument(row, 'transactionNote'))"
            >
              <mat-icon>description</mat-icon>
            </a>
          </div>
          <a
            *ngIf="deleteTransaction"
            type="button"
            mat-icon-button
            color="warn"
            matTooltip="Delete"
            (click)="delete.emit(row)"
            [matTooltipShowDelay]="750"
          >
            <mat-icon>delete</mat-icon>
          </a>
        </mat-cell>
        <mat-footer-cell
          *matFooterCellDef
          [ngStyle]="{
            'min-width.px':
              ((editTransaction ? 1 : 0) +
                (detailsTransaction ? 1 : 0) +
                (deleteTransaction ? 1 : 0)+
                (downloadFile ? 1 : 0) ) *
              40
          }"
        >
        </mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
          selected: selectedTransaction && selectedTransaction.id === row.id
        }"
        (click)="selectItem.emit(row)"
      >
      </mat-row>
      <mat-footer-row
        *matFooterRowDef="displayedColumns"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      ></mat-footer-row>
    </mat-table>
    <mat-paginator
      showFirstLastButtons="true"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      [pageSize]="pageSize"
    ></mat-paginator>
  </div>
</ng-container>
<p>
  <i
    >The bills and notes for this client may not be complete. If you wish to
    close out this account, please contact us so that we can confirm the final
    balance.</i
  >
</p>
