import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './profile.reducer';

export const selectProfileState = createFeatureSelector<fromReducer.State>(
  'profile'
);

export const selectProfile = createSelector(
  selectProfileState,
  (state) => state.profile
);
