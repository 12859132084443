import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { CoreModule } from '@core/core.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './+state/profile.reducer';
import { ProfileEffects } from './+state/profile.effects';

import { PhoneNumbersModule } from '@phone-numbers/phone-numbers.module';

import { ProfileContainerComponent } from './components';
import {EmailSettingsModule} from '@app/email-settings/email-settings.module';

@NgModule({
  declarations: [ProfileContainerComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    CoreModule,
    PhoneNumbersModule,
    EmailSettingsModule,
    StoreModule.forFeature('profile', reducer),
    EffectsModule.forFeature([ProfileEffects]),
  ],
})
export class ProfileModule {}
