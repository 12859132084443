import gql from 'graphql-tag';

export const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on addresses {
    id
    street_address_1
    street_address_2
    city
    state
    zip
  }
`;
