import gql from 'graphql-tag';

/**
 * Fragments
 */
export const PREFERENCE_FRAGMENT = gql`
  fragment preferenceFragment on preferences {
    id
    type
    body
  }
`;
