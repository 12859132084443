<p class="c9"><span class="c3 c1">SHARESCAPE, LLC</span></p>
<p class="c8"><span class="c3 c1">END-USER LICENSE AGREEMENT</span></p>
<p class="c0"><span class="c3 c1">Effective date: July 1, 2020</span></p>
<p class="c0">
  <span class="c3 c1"
    >Welcome to Sharescape! Sharescape is a technology system designed to
    empower attorneys, patients, and their treating medical providers. Our
    web-enabled solution supports the sharing of patient and client information.
  </span>
</p>
<p class="c0">
  <span class="c3 c1"
    >By clicking the &#39;I Agree&#39; button and by using the Sharescape
    software, or any included documentation, Sharescape&#39;s website
    (&quot;website&quot;) and any of Sharescape&#39;s services
    (&quot;services&quot;) accessible by using the software (collectively, such
    software, mobile application, mobile website, &nbsp;website and services,
    the &quot;software&quot;), you acknowledge that you have read this
    agreement, understand it, and agree to be bound by the terms and conditions
    of this agreement (the &quot;agreement&quot;), and this will be a legally
    binding agreement between you and Sharescape inc. (&quot;Sharescape&quot;,
    &quot;we&quot;, &quot;us&quot; or &quot;our&quot;).</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >If you do not agree with the terms and conditions of this agreement, you
    should reject them by not clicking on &#39;I Agree&#39; and by not using the
    software.</span
  >
</p>
<p class="c0">
  <span class="c1"
    >Your access to and use of our software, website, and services is subject in
    all respects to the terms of our privacy policy available at&nbsp;</span
  ><span class="c11">www.sharescape.io</span
  ><span class="c3 c1"
    >&nbsp;as we may update that privacy policy from time to time on reasonable
    notice to you as described under our privacy policy (&quot;privacy
    policy&quot;).</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >You hereby expressly consent to Sharescape contacting you directly by phone
    call, email, text message, or otherwise through the software in order to
    provide you with services under this agreement.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >These terms and conditions include a class action waiver. This agreement
    limits the remedies that may otherwise be available to you in the event of a
    dispute.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >The software is&nbsp;not&nbsp;for medical emergencies. Always seek the
    advice of your physician or other qualified health provider, directly and
    not through our software, with any questions you may have regarding a
    medical condition.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >References in this Agreement to &quot;you&quot; or &quot;your&quot; refer
    to both you and any person or entity on whose behalf you act, including but
    not limited to if you are legally representing a client on the Website or if
    you are a medical provider treating a patient listed on the Website.</span
  >
</p>
<p class="c5"><span class="c2">Grant of License</span></p>
<p class="c0">
  <span class="c3 c1"
    >Subject to the terms and conditions of this Agreement, Sharescape hereby
    grants to you a non-transferable, non-exclusive, royalty free, limited
    license to install, access and use the Software. Portions of the Software,
    including substantial portions of the Services accessible as part of the
    Software, will be provided via remote access from your device to
    Sharescape&#39;s platform.
  </span>
</p>
<p class="c5"><span class="c2">Software Use Restrictions</span></p>
<p class="c0">
  <span class="c3 c1"
    >You shall not sublicense, distribute, hypothecate, lease, loan or otherwise
    convey the Software or the content made available through the Software
    (&ldquo;Content&rdquo;) or any portion thereof to anyone, and under no
    circumstance may you use or allow the use of the Software in any manner
    other than as expressly set forth above. You shall not modify the Software,
    incorporate the Software in whole or in part in any other product or create
    derivative works based on all or part of the Software. You shall not remove
    any copyright, trademark, proprietary rights, disclaimer or warning notice
    included on or embedded in any part of the Software. You shall not use the
    Software in connection with a service bureau, time sharing or fee-for-
    service arrangement with third parties. Except to the extent permitted by
    applicable local law, you shall not reverse assemble, decompile or
    disassemble or otherwise reverse engineer any portion of the Software. If
    you dispose of any media embodying Software or Content, you will ensure that
    you have completely erased or otherwise destroyed any Software and Content
    stored on such media.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >THE SOFTWARE IS NOT INTENDED FOR USE IN ANY SITUATION IN WHICH THE FAILURE
    OF THE SOFTWARE COULD LEAD TO DEATH OR BODILY INJURY OF ANY TYPE. YOU ARE
    SOLELY RESPONSIBLE FOR USING THE SOFTWARE IN A MANNER CONSISTENT WITH ALL
    APPLICABLE INTERNATIONAL, REGIONAL, FEDERAL, NATIONAL, STATE, AND LOCAL LAWS
    (&ldquo;APPLICABLE LAWS AND REGULATIONS&rdquo;).</span
  >
</p>
<p class="c5"><span class="c2">Personal Data</span></p>
<p class="c0">
  <span class="c3 c1"
    >To access and use the Software, you must register with Sharescape by
    providing your email and other information that may be used to identify you.
    During the course of using the Software, you may be prompted to provide
    additional personal information, including without limitation, protected
    health information (&ldquo;PHI&rdquo;) or other medical information
    (collectively, PHI and any personal information you or your healthcare
    provider enter through the Software shall be referred to as your
    &ldquo;Personal Data&rdquo;). Applicable Laws and Regulations require that
    Sharescape protect the privacy of your Personal Data.
  </span>
</p>
<p class="c0">
  <span class="c1 c3"
    >By agreeing to the terms of this Agreement, you are authorizing Sharescape
    to collect, use, and disclose your Personal Data of your client(s) in order
    to provide you with access to and use of the Software.</span
  >
</p>
<p class="c5"><span class="c2">Password Protection</span></p>
<p class="c0">
  <span class="c3 c1"
    >You are responsible for keeping your password confidential. You should
    notify us immediately if your password is hacked or stolen. Sharescape is
    not responsible or liable to you in any way if information is intercepted by
    an unauthorized person, either in transit or at your home, business or other
    place of access. You agree never to share your login information or your
    password.</span
  >
</p>
<p class="c5"><span class="c2">Use of Content</span></p>
<p class="c0">
  <span class="c3 c1"
    >The Software and Content may contain typographical errors, other
    inadvertent errors or inaccuracies. We reserve the right to make changes to
    the Software, document names, Content, descriptions or specifications of
    products or Services, or other information without obligation to issue any
    notice of such changes.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >You may view, copy, download, and print Content that is available through
    the Software, subject to the following conditions:</span
  >
</p>
<ol class="c12 lst-kix_list_1-0 start" start="1">
  <li class="c6">
    <span class="c3 c1"
      >The Content may be used solely for your personal informational purposes.
      In the case of a client or patient, you may only access use this
      information in a way you are authorized by that patient / client.
    </span>
  </li>
  <li class="c7">
    <span class="c3 c1"
      >You strictly adhere to the handling of all information in accordance with
      Health Insurance Portability and Accountability Act (&ldquo;HIPAA&rdquo;)
      and applicable state law.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1">The Content may not be modified.</span>
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Copyright, trademark, and other proprietary notices may not be
      removed.</span
    >
  </li>
</ol>
<p class="c0">
  <span class="c3 c1"
    >Nothing contained on Sharescape&#39;s Website or in the Software or
    Services should be construed as granting, by implication, estoppel, or
    otherwise, any license or right to use this Software or any Content
    displayed on our Website, through the use of framing or otherwise, except:
    (a) as expressly permitted by these terms of use; or (b) with our prior
    written permission or the permission of such third party that may own the
    trademark or copyright of material displayed on our Website.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >If you violate any of these terms and conditions, your permission to use
    the Software and Content automatically terminates and you must immediately
    destroy any copies you have made of any portion of the Software or Content.
    Your records containing your Personal Data are available in accordance with
    Sharescape&#39;s Privacy Policy.</span
  >
</p>
<p class="c5"><span class="c2">Copying Restrictions</span></p>
<p class="c0">
  <span class="c3 c1"
    >You agree that (i) your use and possession of such copies shall be solely
    under the terms and conditions of this Agreement, and (ii) you shall place
    the same proprietary and copyright notices and legends on all such copies as
    included by Sharescape on any media embodying an authorized copy of the
    Software originally provided by Sharescape. Except as described in this
    paragraph, you are not permitted to copy the Software or Content.</span
  >
</p>
<p class="c5"><span class="c2">Disclosure Restrictions</span></p>
<p class="c0">
  <span class="c3 c1"
    >You acknowledge that the Software, including the source code for the
    Software and any information derived therefrom, constitutes a valuable trade
    secret of Sharescape. If you should gain access to such materials, you shall
    not disclose them to anyone.</span
  >
</p>
<p class="c5"><span class="c2">Ownership of Software</span></p>
<p class="c0">
  <span class="c3 c1"
    >You agree and acknowledge that (i) the Software is licensed to you, not
    sold, and Sharescape transfers no ownership interest in the Software, in the
    intellectual property in any Software or in any Software copy, to you under
    this Agreement or otherwise, (ii) that Sharescape and its licensors reserve
    all rights not expressly granted to you hereunder, (iii) Sharescape or its
    licensors own the Software (including, but not by way of limitation, any
    images, algorithms, photographs, animations, video, audio, music and text
    incorporated in the Software), (iv) Sharescape owns the data collected via
    the Software and has the right to use such data in accordance with our
    Privacy Policy, and (v) the Software is protected by United States Copyright
    Law and international treaties relating to protection of copyright. The
    Software includes, and this Agreement will cover, any updates, upgrades or
    bug fixes for the Software provided to you.</span
  >
</p>
<p class="c5"><span class="c2">Login Info and Access</span></p>
<p class="c0">
  <span class="c3 c1"
    >Your access to the Software must be via login credentials (&quot;Login
    Credentials&quot;). You agree that you are responsible for protecting your
    Login Credentials from unauthorized use, and you are responsible for all
    activity that occurs under those Login Credentials. You agree to notify us
    immediately if you believe that any of Your Login Credentials have been or
    may be used without your permission so that appropriate action can be taken.
    You may not (i) create more than one account to access the Software, (ii)
    share your Login Credentials with any third party nor (iii) transfer your
    account to any third party. Sharescape is not responsible for any loss or
    damage caused by, or expense incurred by you as a result of, your failure to
    safeguard your Login Credentials. You agree that you shall not rent, resell,
    or to remarket the Software or Content or to provide access to the Software
    or Content to any third party. Sharescape may terminate any Login
    Credentials in its sole discretion; you may not be permitted to create a new
    account to access the Software or Content if your prior Login Credentials
    have been terminated by Sharescape.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >You agree to provide, maintain and update true, accurate, current and
    complete Personal Data on the screens that collect information from you in
    connection with the Software, and represent that you will not misrepresent
    your identity or your affiliation with any person or entity.</span
  >
</p>
<p class="c5">
  <span class="c2">HIPAA<br /></span
  ><span class="c11 c13"
    >You agree to handle any and all information and documentation on website in
    accordance with the Health Insurance Portability and Accountability Act
    (&ldquo;HIPAA&rdquo;) and applicable state law.</span
  >
</p>
<p class="c5" id="h.gjdgxs">
  <span class="c2">Transfer Restrictions</span>
</p>
<p class="c0">
  <span class="c3 c1"
    >You may not sublicense, delegate, assign or otherwise transfer this
    Agreement, the license granted herein, or any other of your rights or
    obligations under this Agreement, in whole or in part.</span
  >
</p>
<p class="c5"><span class="c2">Export Restrictions</span></p>
<p class="c0">
  <span class="c3 c1"
    >You may not export or re-export any Software except in full compliance with
    all United States laws and regulations and other Applicable Laws and
    Regulations, executive orders and the like, including in particular the
    Export Administration Regulations of the U.S. Department of Commerce.
    Without limitation of the foregoing, no Software may be exported or
    re-exported into (or to a national or resident of) any country to which the
    U.S. embargoes goods, or to anyone on the U.S. Treasury Department&#39;s
    list of Specially Designated Nationals and Blocked Persons or the U.S.
    Commerce Department&#39;s Denied Persons List.</span
  >
</p>
<p class="c5"><span class="c2">Breach and Termination</span></p>
<p class="c0">
  <span class="c3 c1"
    >This Agreement and any license granted herein may be suspended or
    terminated by Sharescape immediately in our sole discretion if you fail to
    comply with any term or condition of this Agreement. In addition, Sharescape
    may at any time suspend your access to the Software, if you are in breach of
    this Agreement or using the Software in a manner that impairs the operation
    of the Software or that violates the Privacy Policy. You agree that we are
    not liable to you or any third party for any termination of your access to
    our Software.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >You acknowledge and agree that the Software is under development and will
    continually change as Sharescape may determine from time to time. Sharescape
    reserves the right to terminate any portion of the Software or any Services
    related to the Software at any time.</span
  >
</p>
<p class="c5">
  <span class="c2">Enforcement of Terms; Effect of Termination</span>
</p>
<p class="c0">
  <span class="c3 c1"
    >If you fail to fulfill any of your obligations under this Agreement, and
    this Agreement terminates, Sharescape and/or its licensors may pursue all
    legal remedies available to them. You agree that Sharescape&#39;s licensors
    referenced in the Software are third- party beneficiaries of this Agreement,
    and may enforce this Agreement as it relates to their intellectual property.
    Sections of this Agreement which by their nature survive expiration or
    termination of this Agreement shall survive according to their terms. Upon
    termination, you shall promptly uninstall and remove the Software from the
    mobile device on which it was installed.</span
  >
</p>
<p class="c5"><span class="c2">US Government Users</span></p>
<p class="c0">
  <span class="c3 c1"
    >Pursuant to the policy stated at 48 CFR 227.7202-1, U.S. Government users
    acknowledge that (i) the Software is commercial computer software, (ii) this
    Agreement embodies the licenses customarily used by Sharescape for licenses
    in Software granted to the public, and (iii) the licenses set forth herein
    shall apply to all possession, use and duplication of the Software by the
    U.S. Government, except to the extent which such licenses are inconsistent
    with Federal procurement law. The Contractor/manufacturer is Sharescape
    Inc.</span
  >
</p>
<p class="c5"><span class="c2">Access to our Software by Minors</span></p>
<p class="c0">
  <span class="c1"
    >The Children&rsquo;s Online Privacy and Protection Act requires that online
    service providers, which are consistently accessed by children under the age
    of 13 years old, obtain parental consent before they collect personally
    identifiable information online from these children. Sharescape does not
    knowingly collect Personal Data from children under the age of 13, and our
    Software is not directed at users under the age of 13.&nbsp;</span
  ><span class="c2"
    >You hereby acknowledge and agree that children under the age of 13 are
    prohibited from using our Software.</span
  ><span class="c3 c1"
    >&nbsp;A parent, guardian, or personal representative may use the Software
    on behalf of a child under the age of 13. Furthermore, you acknowledge and
    agree that minors between the ages of 13 and 17 may use our Software, but
    that a parent, guardian or personal representative must consent to this
    Agreement and our Privacy Policy on their behalf.</span
  >
</p>
<p class="c5"><span class="c2">Assumption of Risk</span></p>
<p class="c0">
  <span class="c3 c1"
    >The Software is designed to provide a designated third-party with
    health-related information based on your use of the Software. The Software
    and any related data supplied to you by Sharescape does not provide medical
    advice. By granting you the right to use the Software, Sharescape does not
    assume any obligation or liability with respect to your health. In no event
    shall Sharescape be liable for any death or bodily injury that you suffer,
    or that you cause to any third party, in connection with your use of the
    Software or any activity you undertake in connection with your use of the
    Software.</span
  >
</p>
<p class="c5"><span class="c2">Disclaimer of Warranty</span></p>
<p class="c0">
  <span class="c3 c1"
    >SHARESCAPE PROVIDES THE SOFTWARE TO YOU &quot;AS IS&quot;, WITH ALL FAULTS,
    AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, STATUTORY, IMPLIED OR OTHERWISE,
    INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A
    PARTICULAR PURPOSE, OR NON-INFRINGEMENT. SHARESCAPE MAKES NO REPRESENTATION
    OR WARRANTY THAT THE SOFTWARE IS ACCURATE, COMPLETE OR UP-TO- DATE.
    SHARESCAPE MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND WITH RESPECT TO
    THE USE OR THE RESULTS OF THE USE OF ANY DATA OR INTERACTIONS OF ANY USER.
    NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY ANY SHARESCAPE EMPLOYEE,
    REPRESENTATIVE OR DISTRIBUTOR SHALL CREATE A WARRANTY FOR THE SOFTWARE, AND
    YOU MAY NOT RELY ON ANY SUCH INFORMATION OR ADVICE. SHARESCAPE&#39;S
    LICENSORS EXPLICITLY DISCLAIM ANY AND ALL WARRANTIES WITH RESPECT TO THE
    SOFTWARE.</span
  >
</p>
<p class="c5">
  <span class="c2">Limitations of Liability and Releases</span>
</p>
<p class="c0">
  <span class="c3 c1"
    >IN NO EVENT SHALL SHARESCAPE OR ITS LICENSORS BE LIABLE TO YOU FOR ANY
    SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY, INCIDENTAL OR INDIRECT DAMAGES
    OF ANY KIND (INCLUDING WITHOUT LIMITATION THE COST OF COVER, DAMAGES ARISING
    FROM LOSS OF DATA, USE, PROFITS OR GOODWILL), WHETHER OR NOT SHARESCAPE HAS
    BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS, HOWEVER CAUSED AND ON ANY
    THEORY OF LIABILITY ARISING OUT OF THIS AGREEMENT. THESE LIMITATIONS SHALL
    APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
    REMEDY.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >SHARESCAPE&#39;S MAXIMUM AGGREGATE LIABILITY ARISING OUT OF THIS AGREEMENT
    AND/OR YOUR USE OR POSSESSION OF THE SOFTWARE, INCLUDING WITHOUT LIMITATION
    ANY CLAIMS IN TORT (INCLUDING NEGLIGENCE), CONTRACT, BREACH OF WARRANTY,
    STRICT LIABILITY OR OTHERWISE, AND FOR ANY AND ALL CLAIMS COMBINED, WILL NOT
    EXCEED U.S.&nbsp;$1.</span
  >
</p>
<p class="c5"><span class="c2">Exclusions</span></p>
<p class="c0">
  <span class="c3 c1"
    >SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
    LIMITATIONS ON CERTAIN TYPES OF DAMAGES, SO THE ABOVE DISCLAIMERS AND
    LIMITATIONS MAY NOT APPLY TO YOU WITH RESPECT TO CERTAIN TYPES OF DAMAGES OR
    CLAIMS.</span
  >
</p>
<p class="c5"><span class="c2">User Submissions</span></p>
<p class="c0">
  <span class="c3 c1"
    >You agree that you will not upload or transmit any communications or
    submissions of any type to public areas of the Software, including message
    boards if we add them to our Services (&ldquo;Public Areas&rdquo;) that
    infringe or violate any rights of any party. By submitting communications or
    other materials to the Public Areas, you agree that such submissions are
    non-confidential for all purposes.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >If you make any such submission you agree that you will not send or
    transmit to Sharescape by email, (including through the email addresses
    listed on the &quot;Contact Us&quot; page) any communication or material
    that infringes or violates any rights of any party. If you submit any
    business information, idea, concept or invention to Sharescape by email, you
    agree such submission is non-confidential for all purposes.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >If you make any submission to a Public Area of our Website or if you submit
    any business information, idea, concept or invention to Sharescape by email
    or otherwise, you automatically grant, or warrant that the owner of such
    communication or intellectual property has expressly granted, Sharescape a
    royalty-free, perpetual, irrevocable, world-wide nonexclusive license to
    use, reproduce, create derivative works from, modify, publish, edit,
    translate, distribute, perform, and display the communication or material in
    any media or medium, or any form, format, or forum now known or hereafter
    developed. Sharescape may sublicense its rights through multiple tiers of
    sublicenses. If you wish to keep any business information, ideas, concepts
    or inventions private or proprietary, do not submit them to a Public Area or
    to Sharescape.</span
  >
</p>
<p class="c5"><span class="c2">Prohibited Activities</span></p>
<p class="c0">
  <span class="c3 c1"
    >By accessing our Software or using our Services, you agree to abide by the
    following standards of conduct. You agree that you will not, and will not
    authorize or facilitate any attempt by another person to use our Software or
    Services to:</span
  >
</p>
<ol class="c12 lst-kix_list_2-0 start" start="1">
  <li class="c6">
    <span class="c3 c1"
      >Transmit any communications or materials that are unlawful, harmful,
      threatening, abusive, harassing, defamatory, vulgar, offensive, obscene,
      pornographic, lewd, lascivious, or otherwise objectionable, as determined
      by Sharescape.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Use a name or language that Sharescape, in its sole discretion, deems
      offensive.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1">Transmit or post defamatory statements.</span>
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Transmit or post hateful or racially or ethnically objectionable
      communications or materials.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Transmit or post any communications or materials that infringe
      another&#39;s copyright, trademark or trade secret.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Transmit or post unsolicited advertising or unlawfully promote products
      or services.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Harass, threaten or intentionally embarrass or cause distress to another
      person or entity.</span
    >
  </li>
  <li class="c7"><span class="c3 c1">Impersonate another person.</span></li>
  <li class="c7">
    <span class="c3 c1"
      >Upload, post, e-mail, transmit or otherwise make available: (A) any
      information or material that infringes upon a third party right,
      especially intellectual property rights; (B) any third party
      advertisements, including banner exchange services; (C) any software
      viruses, Trojan horses, worms or any other malicious application or (D)
      any information or material which may constitute or encourage conduct that
      is a criminal offense or civil wrong or otherwise violates any applicable
      law.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Stalk, threaten or harass any other users or infringe upon or attempt to
      infringe upon their privacy.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Promote, solicit, or participate in any multi-level marketing or pyramid
      schemes.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1">Exploit children under 18 years of age.</span>
  </li>
  <li class="c7">
    <span class="c3 c1">Engage in disruptive activity.</span>
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Obtain unauthorized access to any computer system through the
      Website.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Invade the privacy of any person, including but not limited to posting
      personally identifying or otherwise private information about a person
      without their consent (or their parent&#39;s consent in the case of a
      child under 13 years of age).</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Solicit personal information from children under 13 years of age.</span
    >
  </li>
  <li class="c7">
    <span class="c3 c1">Violate any Applicable Law or Regulation.</span>
  </li>
  <li class="c7">
    <span class="c3 c1"
      >Encourage conduct that would constitute a criminal or civil
      offense.</span
    >
  </li>
</ol>
<p class="c5">
  <span class="c2">Ads, Searches and Links to Other Sites</span>
</p>
<p class="c0">
  <span class="c3 c1"
    >Sharescape may provide links to third-party websites (&ldquo;sites&rdquo;).
    Sharescape also may select certain sites as priority responses to search
    terms you enter and Sharescape may agree to allow advertisers to respond to
    certain search terms with advertisements or sponsored material. Sharescape
    does not recommend and does not endorse the content on any third-party
    sites. Sharescape is not responsible for the content of linked third-party
    sites, sites framed within the Sharescape Website, third-party sites
    provided as search results, or third-party advertisements, and does not make
    any endorsements or representations regarding their content or accuracy.
    Your use of third-party sites is at your own risk and subject to the terms
    of service of use for such sites.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >You acknowledge and understand that should you choose to provide any
    Personal Data to such third party sites, the recipients of such Personal
    Data, after it is disclosed, may not be subject to the same obligations
    under federal privacy laws or other Applicable Laws and Regulations, and
    such third party sites may use or re-disclose the information in accordance
    with Applicable Laws and Regulations and their respective privacy
    policies.</span
  >
</p>
<p class="c5"><span class="c2">Notices</span></p>
<p class="c0">
  <span class="c3 c1"
    >Notices to you hereunder shall be sent to the email address provided by you
    when you registered to download and install the Software. Notices to
    Sharescape shall be sent to the attention of its CEO at Sharescape, Inc.
    8095 E Prentice Ave, Greenwood Village, CO 80111. Each party may change such
    address upon written notice to the other party.</span
  >
</p>
<p class="c5"><span class="c2">Governing Law</span></p>
<p class="c0">
  <span class="c3 c1"
    >This Agreement shall be governed by and construed in accordance with the
    laws of the Colorado, USA without regard to its conflicts of laws provision.
    The United Nations Convention on Contracts for the International Sale of
    Goods shall not apply. The parties hereby consent to the exclusive
    jurisdiction and venue in the state courts in Denver County, Colorado or any
    federal court located therein. In any action or proceeding to enforce or
    interpret this Agreement, the prevailing party will be entitled to recover
    the costs and expenses (including reasonable attorneys&#39; fees) that it
    incurred in connection with such action or proceeding and enforcing any
    judgment or order obtained.</span
  >
</p>
<p class="c5"><span class="c2">Class Action Waiver</span></p>
<p class="c0">
  <span class="c3 c1"
    >WHERE PERMITTED BY APPLICABLE LAW YOU AND SHARESCAPE AGREE THAT EACH MAY
    BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR SHARESCAPE&rsquo;S INDIVIDUAL
    CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
    REPRESENTATIVE ACTION. You further acknowledge and agree that no arbitrator
    or judge may consolidate more than one person&rsquo;s claims or otherwise
    preside over any form of a representative or class proceeding. This Class
    Action Waiver section will survive any termination of this Agreement.</span
  >
</p>
<p class="c5"><span class="c2">Jurisdictional Issues</span></p>
<p class="c0">
  <span class="c3 c1"
    >Unless otherwise specified by Sharescape to you in writing, we make no
    representation that information on the Software or Services are appropriate
    or available for use outside the United States. Those who choose to access
    this Website from outside the United States do so on their own initiative
    and at their own risk and are responsible for compliance with all Applicable
    Laws and Regulations.</span
  >
</p>
<p class="c5"><span class="c2">Intellectual Property Rights</span></p>
<p class="c0">
  <span class="c3 c1"
    >Unless otherwise noted, all Content contained on this Software is the
    property of Sharescape and/or its affiliates or licensors, and is protected
    from unauthorized copying and dissemination by United States copyright law,
    trademark law, international conventions and other intellectual property
    laws. Domain names and product names are trademarks or registered trademarks
    of their respective owners. Content and Services are subject to change or
    termination without notice and at the discretion of Sharescape. All rights
    not expressly granted herein are reserved to Sharescape and its
    licensors.</span
  >
</p>
<p class="c0">
  <span class="c3 c1"
    >We do not claim ownership of communications or materials submitted by Users
    without compensation by Sharescape and with the expectation that such
    communication or materials will be made publicly accessible through our
    Software. By submitting such communications or materials, however, You agree
    to grant us a world-wide, royalty-free, perpetual, irrevocable, non-
    exclusive license to use, distribute, reproduce, modify, adapt, create
    derivative works from, and publicly perform or display such communications
    or materials. This license shall remain in effect until we delete the
    communications or materials from our systems.</span
  >
</p>
<p class="c5"><span class="c2">Complete Agreement; Severability</span></p>
<p class="c0">
  <span class="c3 c1"
    >This Agreement supersedes all proposals, oral or written, all negotiations,
    conversations, discussions, agreements, and all past course of dealing
    between you and Sharescape relating to the Software or the terms of its
    license to you, and may only be modified in writing signed by you and
    Sharescape. In the event any term of this Agreement is held by a court of
    competent jurisdiction not to be enforceable, such unenforceability shall
    not affect the remaining terms of this Agreement in such jurisdiction or
    render unenforceable or invalidate such terms and provisions of this
    Agreement in other jurisdictions. Upon such determination that any of the
    terms or provisions of this Agreement are held to be invalid under any
    applicable statute or rule of law, they shall be severed from this Agreement
    and the remaining provisions of this Agreement shall be interpreted so as
    best to reasonably effect the intent of the parties and the parties agree to
    replace any invalid or unenforceable provisions in a mutually acceptable
    manner in order that the transactions contemplated hereby be consummated as
    originally contemplated to the greatest extent possible. No waiver of any
    right or obligation contained herein shall be given except in writing signed
    by the party against whom the waiver is sought to be enforced.</span
  >
</p>
<p class="c0">
  <span class="c1"
    >If you have any questions about this license agreement, please
    contact&nbsp;</span
  ><span class="c11">help@sharescape.io</span><span class="c3 c1">&nbsp;</span>
</p>
<p class="c10"><span class="c3 c1"></span></p>
