import {Injectable} from '@angular/core';
import {gitVersion} from '../../../../environments/git-version';

@Injectable({providedIn: 'root'})
export class GitVersionService {
  constructor() {
  }

  getCommitHash() {
    return gitVersion.SHA;
  }
}
