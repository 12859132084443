import {
  Component,
  OnChanges,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Screen } from '@core/models';

@Component({
  selector: 'app-report-organizations-ui',
  templateUrl: './report-organizations.component.html',
  styleUrls: ['./report-organizations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportOrganizationsComponent
  implements OnChanges, AfterViewInit, OnDestroy {
  @Input() screen: Screen;
  @Input() organizations = null;
  @Input() pageSize: number;

  @Output() saveReport: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();

  @ViewChild(MatSort, { static: false })
  sort: MatSort;
  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  destroy$: Subject<boolean> = new Subject<boolean>();

  dataSource = new MatTableDataSource<any>();

  displayedColumns = ['Organization', 'PhoneNumber'];

  ngOnChanges() {
    if (this.organizations) {
      this.dataSource.data = this.organizations;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Organization':
          return item.name;
        case 'PhoneNumber':
          return item.phone_numbers && item.phone_numbers.length > 0
            ? item.phone_numbers[0].phone_number.number
            : null;
        default:
          return item[property];
      }
    };

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.page
      .pipe(
        takeUntil(this.destroy$),
        map((val) => {
          if (val.pageSize !== this.pageSize) {
            this.pageSizeChanged.emit(val.pageSize);
          }
        })
      )
      .subscribe();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
