import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Theme } from '@core/models';
import { LoggerService } from '@core/services';

@Component({
  selector: 'app-upload-transaction-document',
  template: `
    <h3 mat-dialog-title>{{ title }}</h3>
    <mat-dialog-content>
      <p>{{ dialogMessage }}</p>
      <mat-form-field *ngIf="transactionFile" class="filename">
        <span matPrefix><mat-icon>picture_as_pdf</mat-icon></span>
        <span matSuffix
          ><mat-icon class="clear-button" (click)="clearFile()"
            >clear</mat-icon
          ></span
        >
        <input
          matInput
          placeholder="File Name"
          [value]="transactionFile.name"
          readonly
        />
      </mat-form-field>

      <section *ngIf="!transactionFile" class="mat-typography margin-top">
        <div class="dropzone">
          <app-drop-zone
            dropZoneTextSize="100%"
            [dropZoneText]="dropZoneText"
            [allowMultipleFiles]="false"
            [allowedFileTypes]="allowedFileTypes"
            [dragEnterColor]="theme.accent"
            [dragEnterTextColor]="theme.primaryText"
            [dragLeaveColor]="theme.primary"
            [dragLeaveTextColor]="theme.accentText"
            (validFiles)="onFilesValid($event)"
            (invalidFiles)="onFilesInvalid($event)"
          >
          </app-drop-zone>
        </div>
      </section>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="cancel()">CANCEL</button>
      <button mat-button (click)="upload()" [disabled]="!transactionFile">
        UPLOAD
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      p {
        width: 500px;
      }
      mat-dialog-actions {
        display: flex;
        justify-content: flex-end;
      }
      mat-icon {
        margin-right: 10px;
      }
      [mat-button] {
        padding: 0;
      }
      .filename {
        width: 100%;
      }
      .clear-button {
        cursor: pointer;
      }
      .dropzone {
        height: 100px;
        width: 100%;
        margin-bottom: 10px;
      }
    `,
  ],
})
export class UploadTransactionDocumentComponent {
  title = 'Upload Transaction Document';
  dropZoneText = 'SELECT FILE';
  clientId: string;
  transactionId: string;
  transactionType: string;
  dialogMessage: string;
  transactionFile: File;
  allowedFileTypes: [];
  theme: Theme;

  constructor(
    private ref: MatDialogRef<UploadTransactionDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private logger: LoggerService
  ) {
    (this.clientId = data.clientId),
      (this.transactionId = data.transactionId),
      (this.transactionType = data.transactionType);
    this.allowedFileTypes = data.allowedFileTypes;
    this.title = data.title;
    this.theme = data.theme;
    this.dropZoneText = data.dropZoneText;
  }

  onFilesValid(files: File[]) {
    this.transactionFile = files[0];
  }

  onFilesInvalid(files: File[]) {
    if (files.length > 0) {
      const types = new Set();
      const info = files.map((file) => {
        types.add(
          file.type.length > 0 ? file.type.toLocaleUpperCase() : 'UNKNOWN'
        );
        return {
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
        };
      });
      const message = `Invalid file ${
        Array.from(types).length === 1 ? 'type' : 'types'
      }: ${Array.from(types).join(', ')}.`;
      this.logger.error(message, info);
    }
  }

  cancel() {
    this.ref.close(false);
  }

  upload() {
    this.ref.close({
      clientId: this.clientId,
      transactionId: this.transactionId,
      transactionType: this.transactionType,
      file: this.transactionFile,
    });
  }

  clearFile() {
    this.transactionFile = null;
  }
}
