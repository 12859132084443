import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromActions from './router.actions';
import * as fromReducer from './router.reducer';
import * as fromSelectors from './router.selector';

@Injectable({ providedIn: 'root' })
export class RouterFacade {
  params$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectParams)
  );
  queryParams$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectQueryParams)
  );

  url$: Observable<string> = this.store.pipe(select(fromSelectors.selectUrl));

  constructor(private store: Store<any>) {}

  go(url: fromReducer.RouterUrl) {
    this.store.dispatch(new fromActions.GoAction(url));
  }

  back() {
    this.store.dispatch(new fromActions.BackAction());
  }

  forward() {
    this.store.dispatch(new fromActions.ForwardAction());
  }
}
