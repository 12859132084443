import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnChanges, OnDestroy,
  Output,
} from '@angular/core';
import { Screen } from '@core/models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnChanges {
  @Input() defaultTitle: string;
  @Input() authenticated = false;
  @Input() organizationNames = '';
  @Input() roles: Array<string>;
  @Input() screen: Screen;
  @Input() loading = false;
  @Input() compilingPDFs = false;
  @Input() environment: string;
  @Input() providerUser: any;

  @Input() url: string;
  @Input() organizationLogoUrl: string;
  @Input() isLogoLoaded: boolean;

  @Input() helpCenter: string;

  @Output() toggleSideNav: EventEmitter<void> = new EventEmitter();
  @Output() login: EventEmitter<void> = new EventEmitter();
  @Output() logout: EventEmitter<void> = new EventEmitter();
  @Output() openEula: EventEmitter<void> = new EventEmitter();
  @Output() closeSearch: EventEmitter<void> = new EventEmitter();
  @Output() selectProvider: EventEmitter<any> = new EventEmitter();

  @Output() logoLoaded: EventEmitter<boolean> = new EventEmitter();

  organizationContext = [];

  ngOnChanges() {
    this.organizationContext = [];
    if (this.providerUser) {
      this.organizationContext = [...this.sortContextMenu()];
    }
  }

  sortContextMenu() {
    // tslint:disable-next-line:variable-name / remove duplicate providers
    const _providerUser = [...new Map(this.providerUser.map(item => [item.organization?.id, item.organization])).values()];
    // @ts-ignore/ find parent provider
    const parent = _providerUser.find(p => p.parent_id === null);
    let children = [..._providerUser];
    if (parent) {
      // @ts-ignore/ find child organization
      children = _providerUser.filter(c => c.id !== parent.id);
    }
    // @ts-ignore/ sort children alphabetically
    const sortList = children.sort((a, b) => (a.name > b.name) ? 1 : -1);
    // const organizationContext = parent ? sortList.unshift(parent) : sortList;
    let organizationContext = sortList;
    if (parent) {
      organizationContext = [parent, ...sortList];
    }
    return organizationContext;
  }

  imageLoaded() {
    this.logoLoaded.emit(true);
  }
}
