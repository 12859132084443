import { Component } from '@angular/core';
import { CoreFacade } from '@core/+state';

@Component({
  selector: 'app-root',
  template: `
    <div *ngIf="core.themeName$ | async as theme">
      <div [class]="theme">
        <div class="mat-app-background">
          <app-nav></app-nav>
        </div>
      </div>
    </div>
  `,
})
export class AppComponent {
  constructor(public core: CoreFacade) {}
}
