import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromActions from './app.actions';
import * as fromReducer from './app.reducer';
import * as fromSelectors from './app.selectors';

@Injectable({ providedIn: 'root' })
export class AppFacade {
  loading$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectLoading)
  );

  compilingPDFs$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectCompilingPDFs)
  );

  environment$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectEnvironment)
  );

  phoneTypes$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectPhoneTypeCodes)
  );

  prefixes$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectPrefixCodes)
  );

  states$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectStateCodes)
  );

  redirectURL$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectRedirectURL)
  );

  constructor(
    private store: Store<fromReducer.State> // private gaService: GoogleAnalyticsService
  ) {}

  setLoading(loading: boolean) {
    this.store.dispatch(fromActions.setLoading({ loading }));
  }

  setCompilingPDFs(compilingPDFs: boolean) {
    this.store.dispatch(fromActions.setCompilingPDFs({ compilingPDFs }));
  }

  setEnvironment(environment: string) {
    this.store.dispatch(fromActions.setEnvironment({ environment }));
  }

  setRedirectURL(redirectURL: string) {
    this.store.dispatch(fromActions.setRedirectURL({ redirectURL }));
  }
}
