import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment userFragment on users {
    id
    username
    first_name
    last_name
    last_login
    enabled
  }
`;
