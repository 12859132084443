import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlName][dateMask]'
})
export class DateMaskDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('keypress', ['$event'])
  // @HostListener('keydown')
  private dateValidate(event) {
    const {key} = event;
    if (event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
    const slash = key === 'Backspace' || key === 'Delete';
    let elValue = this.el.nativeElement.value;
    if (elValue.length > 10) {
      elValue = elValue.slice(0, 10);
    }
    const v = elValue;
    if (v.match(/^\d{2}$/) !== null) {
      elValue = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      elValue = v + '/';
    }
    if (slash && elValue.endsWith('/')) {
      elValue = elValue.slice(0, -1);
    }
    this.renderer.setProperty(this.el.nativeElement, 'value', elValue);
  }
}


