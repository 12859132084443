import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Screen} from '@core/models';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Subject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {takeUntil, map} from 'rxjs/operators';

@Component({
  selector: 'app-my-organization-users-list',
  templateUrl: './my-organization-users-list.component.html',
  styleUrls: ['./my-organization-users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyOrganizationUsersListComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() screen: Screen;
  @Input() selectedOrganization: any;
  @Input() displayedColumns: Array<string>;
  @Input() users: any;
  @Input() selectedUser: any = null;
  @Input() pageSize: number;

  @Input() isCreate: boolean;
  @Input() isDelete: boolean;
  @Input() isEmail: boolean;
  @Input() isEdit: boolean;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() details: EventEmitter<any> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() sendInvite: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$: Subject<boolean> = new Subject<boolean>();

  dataSource = new MatTableDataSource<any>();

  ngOnChanges() {
    if (this.users) {
      this.dataSource.data = this.users;
    }
    this.showUserGroupColumn();
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'username':
          return item.username;
        case 'firstName':
          return item.first_name;
        case 'lastName':
          return item.last_name;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.page
      .pipe(
        takeUntil(this.destroy$),
        map((val) => {
          if (val.pageSize !== this.pageSize) {
            this.pageSizeChanged.emit(val.pageSize);
          }
        })
      )
      .subscribe();
  }

  showUserGroupColumn() {
    if (this.displayedColumns && !this.displayedColumns.includes('userGroup')) {
      const displayedColumn = [...this.displayedColumns];
      displayedColumn.splice((this.displayedColumns.length - 2), 0, 'userGroup');
      this.displayedColumns = [...displayedColumn];
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
