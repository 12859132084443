import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoggerService } from '@core/services';
import { Theme } from '@core/models';
import { v4 as uuid } from 'uuid';
import { MatSelectChange } from '@angular/material/select';
import { ConfigurationService } from '@configuration/services/configuration.service';

@Component({
  selector: 'app-transaction-new-bill',
  templateUrl: './transaction-new-bill.component.html',
  styleUrls: ['./transaction-new-bill.component.scss'],
})
export class TransactionNewBillComponent implements OnInit {
  @Input() clientId: string;
  @Input() theme: Theme;
  @Input() transactionTypes: any;

  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  typeFormGroup: FormGroup;
  detailsFormGroup: FormGroup;
  billFormGroup: FormGroup;
  noteFormGroup: FormGroup;

  regNumber = '^[0-9]+(\.[0-9]{1,2})?$';

  constructor(
    private fb: FormBuilder,
    private configurationService: ConfigurationService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.typeFormGroup = this.fb.group({
      id: [uuid(), Validators.required],
      location: '',
      type: ['', Validators.required],
      type_name: '',
    });
    this.detailsFormGroup = this.fb.group({
      description: '',
      date_of_service: new Date().toISOString(),
      date_posted: new Date().toISOString(),
      bill: [0, Validators.pattern(this.regNumber)],
      created_at: [new Date().toISOString(), Validators.required],
      updated_at: [new Date().toISOString(), Validators.required],
      // timestamp: [new Date().toISOString(), Validators.required],
    });

    this.billFormGroup = this.fb.group({
      bill: '',
    });

    this.noteFormGroup = this.fb.group({
      note: '',
    });
  }

  get id() {
    return this.typeFormGroup.get('id').value;
  }

  get bill() {
    return this.billFormGroup.get('bill').value;
  }

  getBillFileName(): string {
    return this.bill && this.bill.name;
  }

  clearBillFile() {
    this.billFormGroup.get('bill').setValue(null);
  }

  get note() {
    return this.noteFormGroup.get('note').value;
  }

  getNoteFileName(): string {
    return this.note && this.note.name;
  }

  clearNoteFile() {
    this.noteFormGroup.get('note').setValue(null);
  }

  billFilesValid(files: File[]) {
    if (files.length === 1) {
      this.billFormGroup.get('bill').setValue(files[0]);
    }
  }

  noteFilesValid(files: File[]) {
    if (files.length === 1) {
      this.noteFormGroup.get('note').setValue(files[0]);
    }
  }

  filesInvalid(files: File[]) {
    if (files.length > 0) {
      const types = new Set();
      const info = files.map((file) => {
        types.add(
          file.type.length > 0 ? file.type.toLocaleUpperCase() : 'UNKNOWN'
        );
        return {
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
        };
      });
      const message = `Invalid file ${
        Array.from(types).length === 1 ? 'type' : 'types'
      }: ${Array.from(types).join(', ')}.`;
      this.logger.error(message, info);
    }
  }

  // Create Transaction Object syntax
  // {
  //   "object": {
  //     "id": "aac0712f-ea98-11e9-93dd-12b06c210614"
  //     "bill": "100",
  //     "charge": "100",
  //     "check_date": "2020-01-01",
  //     "check_number": 1000,
  //     "company": "ShareScape",
  //     "date_of_service": "2020-01-01",
  //     "date_posted": "2020-01-01",
  //     "description": "Description",
  //     "final": false,
  //     "location": "ShareScape",
  //     "payment": 0,
  //     "timestamp": "2020-07-07",
  //     "type": "1",
  //     "client_transactions": {
  //       "data": {
  //         "client_id": "00217c4d-bc06-312c-9caf-ae9a99662bd6"
  //       }
  //     }
  //   }
  // }
  done() {
    const object = {
      clientId: this.clientId,
      transactionId: this.id,
      bill: {
        ...this.typeFormGroup.value,
        ...this.detailsFormGroup.value,
        client_transaction_bills: { data: { client_id: this.clientId } },
      },
      billFile: this.bill,
      noteFile: this.note,
    };
    this.create.emit(object);
  }

  /**
   * Keep the type and type_name fields in sync
   *
   * @param changedValue: the id of the type
   */
  setTypeName(changedValue: MatSelectChange) {
    this.typeFormGroup
      .get('type_name')
      .setValue(
        this.configurationService.getTypeName(
          changedValue.value,
          this.transactionTypes
        )
      );
  }
}
