import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap, filter, catchError, tap } from 'rxjs/operators';
import { AppFacade } from '@app/+state';
import { AuthFacade } from '@auth/+state';
import { ProfileService } from '../services/profile.service';
import { LoggerService } from '@core/services';
import * as fromActions from './profile.actions';

@Injectable()
export class ProfileEffects {
  @Effect()
  loadProfile$: Observable<Action> = combineLatest([
    this.actions$.pipe(ofType(fromActions.loadProfile)),
    this.auth.uid$,
  ]).pipe(
    filter(([_, uid]) => !!uid),
    tap(() => this.app.setLoading(true)),
    switchMap(([_, uid]) => {
      return this.profileService.getProfile(uid).pipe(
        map((user) => fromActions.loadProfileSuccess({ user })),
        catchError((error) => of(fromActions.loadProfileError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  loadProfileSuccess$ = this.actions$.pipe(
    ofType(fromActions.loadProfileSuccess),
    tap(() => this.app.setLoading(false))
  );

  @Effect({ dispatch: false })
  loadProfileError$ = this.actions$.pipe(
    ofType(fromActions.loadProfileError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred retrieving profile.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  updateProfile$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.updateProfile),
    switchMap(({ input }) => {
      return this.profileService.update(input).pipe(
        map((user) => fromActions.updateProfileSuccess({ user })),
        catchError((error) => of(fromActions.updateProfileError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  updateProfileSuccess$ = this.actions$.pipe(
    ofType(fromActions.updateProfileSuccess),
    map(() => {
      const message = 'Your profile changes have been saved.';
      this.logger.notice(message);
    })
  );

  @Effect({ dispatch: false })
  updateProfileError$ = this.actions$.pipe(
    ofType(fromActions.updateProfileError),
    map(({ error }) => {
      const message = 'An error occurred updating profile.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  logout$: Observable<Action> = this.auth.isAuthenticated$.pipe(
    filter((authenticated) => !authenticated),
    map(() => fromActions.clearState())
  );
  constructor(
    private actions$: Actions,
    private app: AppFacade,
    private auth: AuthFacade,
    private profileService: ProfileService,
    private logger: LoggerService
  ) {}
}
