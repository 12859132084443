import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from './auth.actions';
import * as fromStorage from '@core/+state/core.storage';

export interface State {
  authenticated: boolean;
  uid: string;
  organizations: any;
  providerId: string;
  provider_users: any;
  username: string;
  roles: string[];
  global_roles: string[];
  token: string;
  expirationTime: string;
}
export const initialState: State = {
  authenticated: false,
  uid: null,
  organizations: null,
  providerId: null,
  provider_users: null,
  username: null,
  roles: [],
  global_roles: [],
  token: null,
  expirationTime: null,
};

const authReducer = createReducer(
  initialState,
  /////////////////////////////////////////////////////
  // Set Authenticated Data
  /////////////////////////////////////////////////////
  on(fromActions.setAuthState, (state, { idToken }) => {
    if (idToken) {
      return {
        ...state,
        authenticated: true,
        uid: idToken.claims.user_id,
        // providerId: idToken.claims.corporate_provider_id,
        //   roles: getRoles(idToken),
          username: idToken.claims.username,
        token: idToken.token,
        expirationTime: new Date(idToken.expirationTime).toLocaleString(),
      };
    } else {
      return initialState;
    }
  }),

  on(fromActions.getUserInformationSuccess, (state, { userInformation }) => ({
    ...state,
    organizations: [
      ... getOrganizaitons(userInformation),
    ],
    global_roles: getGlobalRoles(userInformation.group),
    roles: getGlobalRoles(userInformation.group),
    // provider_users: userInformation.provider.map((p) => p),
  })),

  on(fromActions.providerUserSuccess, (state, { providers }) => ({
    ...state,
    provider_users: providers.map((p) => p),
  })),

  on(fromActions.setProvider, (state, { provider }) => ({
    ...state,
    providerId: setOrganization(provider.id),
    organizations: [provider],
    roles: provider ? getRoles(provider.id, state)
      : [...state.global_roles]
  })),

  /////////////////////////////////////////////////////
  // Errors
  /////////////////////////////////////////////////////
  on(
    fromActions.logoutError,
    fromActions.getUserInformationError,
    fromActions.authenticateError,
    fromActions.firebaseSignInError,
    fromActions.requestPasswordResetError,
    fromActions.passwordResetError,
    fromActions.changePasswordError,
    fromActions.updateLastSeenError,
    fromActions.magicAuthenticateError,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),

  /////////////////////////////////////////////////////
  // Clear State
  /////////////////////////////////////////////////////
  on(fromActions.clearState, (state) => ({ ...initialState }))
);

export function setOrganization(id) {
  fromStorage.setOrganizationLocal(id);
  return id;
}

export function getGlobalRoles(groups): string[] {
  const roles = [];
  for (const role of groups) {
    if (role.group_id) {
      roles.push(role.group_id);
    }
  }
  return roles;
}

export function getRoles(providerId, state): string[] {
  const roles = [...state.global_roles.map(g => g)];
  if (providerId) {
    const providers = state.provider_users.filter(user => user.organization.id === providerId);
    providers.forEach((provider) => {
      if (provider.group_id && !roles.includes(provider.group_id)) {
        roles.push(provider.group_id);
      }
      if (provider.organization?.group?.id && !roles.includes(provider.organization?.group.id)) {
        roles.push(provider.organization.group.id);
      }
    });
  }
  return roles;
}

export function getOrganizaitons(userInformation) {
  const organizations = [...userInformation.user.map((p: any) => p.organization)];
  // const key = 'id';
  const key = 'name';
  return [...new Map(organizations.map(item =>
    [item[key], item])).values()];
}

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
