import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { PhoneNumbersService } from '../services/phone-numbers.service';
import { LoggerService } from '@core/services';
import * as fromActions from './phone-numbers.actions';

@Injectable()
export class PhoneNumbersEffects {
  @Effect()
  createOrganizationPhoneNumber$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.createOrganizationPhoneNumber),
    mergeMap(({ input }) => {
      return this.phoneNumberService.createOrganizationPhoneNumber(input).pipe(
        map((mutation) =>
          fromActions.createOrganizationPhoneNumberSuccess({ mutation })
        ),
        catchError((error) =>
          of(fromActions.createOrganizationPhoneNumberError({ error }))
        )
      );
    })
  );

  @Effect({ dispatch: false })
  createOrganizationPhoneNumberSuccess$ = this.actions$.pipe(
    ofType(fromActions.createOrganizationPhoneNumberSuccess),
    map(({ mutation }) => {
      const message = 'Organization phone number created successfully.';
      this.logger.info(message, mutation);
    })
  );

  @Effect({ dispatch: false })
  createOrganizationPhoneNumberError$ = this.actions$.pipe(
    ofType(fromActions.createOrganizationPhoneNumberError),
    map(({ error }) => {
      const message =
        'An error occurred creating an organization phone number.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  createClientPhoneNumber$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.createClientPhoneNumber),
    mergeMap(({ input }) => {
      return this.phoneNumberService.createClientPhoneNumber(input).pipe(
        map((mutation) =>
          fromActions.createClientPhoneNumberSuccess({ mutation })
        ),
        catchError((error) =>
          of(fromActions.createClientPhoneNumberError({ error }))
        )
      );
    })
  );

  @Effect({ dispatch: false })
  createClientPhoneNumberSuccess$ = this.actions$.pipe(
    ofType(fromActions.createClientPhoneNumberSuccess),
    map(({ mutation }) => {
      const message = 'Client phone number created successfully.';
      this.logger.info(message, mutation);
    })
  );

  @Effect({ dispatch: false })
  createClientPhoneNumberError$ = this.actions$.pipe(
    ofType(fromActions.createClientPhoneNumberError),
    map(({ error }) => {
      const message = 'An error occurred creating a client phone number.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  createUserPhoneNumber$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.createUserPhoneNumber),
    mergeMap(({ input }) =>
      this.phoneNumberService.createUserPhoneNumber(input).pipe(
        map((mutation) =>
          fromActions.createUserPhoneNumberSuccess({ mutation })
        ),
        catchError((error) =>
          of(fromActions.createUserPhoneNumberError({ error }))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  createUserPhoneNumberSuccess$ = this.actions$.pipe(
    ofType(fromActions.createUserPhoneNumberSuccess),
    map(({ mutation }) => {
      const message = 'User phone number created successfully.';
      this.logger.info(message, mutation);
    })
  );

  @Effect({ dispatch: false })
  createUserPhoneNumberError$ = this.actions$.pipe(
    ofType(fromActions.createUserPhoneNumberError),
    map(({ error }) => {
      const message = 'An error occurred creating a user phone number.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  updatePhoneNumber$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.updatePhoneNumber),
    mergeMap(({ input }) => {
      return this.phoneNumberService.update(input).pipe(
        map((mutation) => fromActions.updatePhoneNumberSuccess({ mutation })),
        catchError((error) => of(fromActions.updatePhoneNumberError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  updatePhoneNumberSuccess$ = this.actions$.pipe(
    ofType(fromActions.updatePhoneNumberSuccess),
    map(({ mutation }) => {
      const message = 'Phone number updated successfully.';
      this.logger.info(message, mutation);
    })
  );

  @Effect({ dispatch: false })
  updatePhoneNumberError$ = this.actions$.pipe(
    ofType(fromActions.updatePhoneNumberError),
    map(({ error }) => {
      const message = 'An error occurred updating a phone number.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  deletePhoneNumber$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.deletePhoneNumber),
    mergeMap(({ input }) => {
      return this.phoneNumberService.delete(input).pipe(
        map((mutation) => fromActions.deletePhoneNumberSuccess({ mutation })),
        catchError((error) => of(fromActions.deletePhoneNumberError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  deletePhoneNumberSuccess$ = this.actions$.pipe(
    ofType(fromActions.deletePhoneNumberSuccess),
    map(({ mutation }) => {
      const message = 'Phone number deleted successfully.';
      this.logger.info(message, mutation);
    })
  );

  @Effect({ dispatch: false })
  deletePhoneNumberError$ = this.actions$.pipe(
    ofType(fromActions.deletePhoneNumberError),
    map(({ error }) => {
      const message = 'An error occurred deleting a phone number.';
      this.logger.error(message, error);
    })
  );

  constructor(
    private actions$: Actions,
    private phoneNumberService: PhoneNumbersService,
    private logger: LoggerService
  ) {}
}
