import { Injectable, OnDestroy } from '@angular/core';
import {Subject, Observable, from, combineLatest} from 'rxjs';
import {takeUntil, tap, map, withLatestFrom} from 'rxjs/operators';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {AuthFacade} from '@auth/+state';
import {RouterFacade} from '@router/+state';
import {AppFacade} from '@app/+state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardAttorneyService
  implements CanActivate, CanActivateChild, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private auth: AuthFacade,
    private router: RouterFacade,
    private app: AppFacade
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([this.auth.idToken$, this.auth.roles$]).pipe(
      takeUntil(this.destroy$),
      tap(([token, groups]) => {
        if (token) {
          if (groups.length > 0){
            const roles = [...groups];
            if (!roles.includes('user_attorney')) {
              this.router.go({ path: ['page-not-found'] });
            }
          }
        } else {
          // Not logged in
          this.app.setRedirectURL(state.url);
          this.router.go({ path: ['login'] });
        }
      }),
      map((user) => !!user)
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([this.auth.idToken$, this.auth.roles$]).pipe(
      takeUntil(this.destroy$),
      tap(([token, groups]) => {
        if (token) {
          if (groups.length > 0){
            const roles = [...groups];
            if (!roles.includes('user_attorney')) {
              this.router.go({ path: ['page-not-found'] });
            }
          }
        } else {
          // Not logged in
          this.app.setRedirectURL(state.url);
          this.router.go({ path: ['login'] });
        }
      }),
      map((user) => !!user)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
