<form class="form" [formGroup]="form">
  <div class="row">
    <div class="item-label">Type</div>
    <div class="item-input">Enabled / Disabled</div>
  </div>
  <div class="divider">
    <mat-divider></mat-divider>
  </div>
  <div class="row">
    <div class="item-label">Case Update Alerts</div>
    <div class="item-input">
      <mat-slide-toggle formControlName="email"
         (change)="onCaseUpdateToggle()"
      >
      </mat-slide-toggle>
    </div>
  </div>
<!--  <div class="row">-->
<!--    <div class="item-label"><span><</span>Other Emails (Eventually)<span>></span></div>-->
<!--    <div class="item-input">-->
<!--      <mat-slide-toggle></mat-slide-toggle>-->
<!--    </div>-->
<!--  </div>-->
</form>
