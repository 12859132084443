import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './auth.reducer';

export const getAppState = createFeatureSelector<State>('auth');

export const selectAuthenticated = createSelector(
  getAppState,
  (state) => state.authenticated
);

export const selectUID = createSelector(getAppState, (state) => state.uid);

export const selectOrganizations = createSelector(
  getAppState,
  (state) => state.organizations
);

export const selectOrganizationNames = createSelector(
  getAppState,
  (state) =>
    state.organizations &&
    state.organizations.map((o) => (o && o.name ? o.name : null)).join(' | ')
);

export const selectUsername = createSelector(
  getAppState,
  (state) => state.username
);

export const selectRoles = createSelector(getAppState, (state) => state.roles);

export const selectGlobalRole = createSelector(getAppState, (state) => state.global_roles);

export const selectProviderId = createSelector(
  getAppState,
  (state) => state.providerId
);

export const selectProviderUser = createSelector(
    getAppState,
    (state) => state.provider_users
);

// Helper -> combine email address and UID for log messages.
export const selectUsernameUID = createSelector(
  getAppState,
  selectUsername,
  selectUID,
  (state, username, uid) => {
    let payload = { username: 'Not Authenticated', uid: null };
    if (uid) {
      payload = { username, uid };
    }
    return state && payload;
  }
);
