import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './app.reducer';
import * as fromReducer from './app.reducer';

export const getAppState = createFeatureSelector<State>('app');

export const selectLoading = createSelector(
  getAppState,
  (state) => state.loading
);
export const selectCompilingPDFs = createSelector(
  getAppState,
  (state) => state.compilingPDFs
);
export const selectEnvironment = createSelector(
  getAppState,
  (state) => state.environment
);
export const selectPhoneTypeCodes = createSelector(
  getAppState,
  (state) => state.phoneTypeCodes
);
export const selectPrefixCodes = createSelector(
  getAppState,
  (state) => state.prefixCodes
);
export const selectStateCodes = createSelector(
  getAppState,
  (state) => state.stateCodes
);
export const selectRedirectURL = createSelector(
  getAppState,
  (state) => state.redirectURL
);
