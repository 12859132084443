<mat-card>
  <mat-card-header class="clients-header">
    <div class="mat-h3">{{ selectedOrganization.name }} Clients</div>
    <button
      *ngIf="createClient"
      mat-mini-fab
      aria-label="Add Client"
      (click)="create.emit(selectedOrganization)"
      matTooltip="Add Client"
    >
      <mat-icon>add</mat-icon>
    </button>
    <!-- Add a div to keep headings correct if the Add button is off -->
    <div *ngIf="!createClient"></div>
  </mat-card-header>

  <mat-table
    matSort
    matSortDisableClear
    matSortActive="lastName"
    matSortDirection="asc"
    [dataSource]="dataSource"
  >
    <!-- Last Name -->
    <ng-container matColumnDef="lastName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'hide-column': screen.device === 'handset' }"
      >
        Last Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'hide-column': screen.device === 'handset' }"
        >{{ row.last_name }}</mat-cell
      >
    </ng-container>

    <!-- First Name -->
    <ng-container matColumnDef="firstName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'hide-column': screen.device === 'handset' }"
      >
        First Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'hide-column': screen.device === 'handset' }"
        >{{ row.first_name }}</mat-cell
      >
    </ng-container>

    <!-- Name -> displayed only on Mobile -->
    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'hide-column': screen.device !== 'handset' }"
        >Name</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'hide-column': screen.device !== 'handset' }"
        >{{ row.last_name }}, {{ row.first_name }}
      </mat-cell>
    </ng-container>

    <!-- Shared Organization -->
    <ng-container matColumnDef="sharedOrganizations">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Shared Organization
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.shared_organizations | organizationNames }}
      </mat-cell>
    </ng-container>

    <!-- Date of Birth -->
    <ng-container matColumnDef="dateOfBirth">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Date of Birth
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.date_of_birth | date: 'MM/dd/yyyy' }}</mat-cell
      >
    </ng-container>

    <!-- Date of Injury -->
    <ng-container matColumnDef="dateOfInjury">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Date of Injury
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.date_of_injury | date: 'MM/dd/yyyy' }}</mat-cell
      >
    </ng-container>

    <!-- Balance -->
    <ng-container matColumnDef="balance">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Balance
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{
          row.client_balance.balance | currency: 'USD':'symbol' | customCurrency
        }}</mat-cell
      >
    </ng-container>

    <!-- Work Phone -->
    <ng-container matColumnDef="workPhone">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Work
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.workPhone }} </mat-cell>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <mat-header-cell
        *matHeaderCellDef
        [ngStyle]="{
          'min-width.px':
            ((createClient ? 1 : 0) +
              (editClient ? 1 : 0) +
              (detailsClient ? 1 : 0) +
              (shareClient ? 1 : 0)) *
            40
        }"
      >
        Actions
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngStyle]="{
          'min-width.px':
            ((createClient ? 1 : 0) +
              (editClient ? 1 : 0) +
              (detailsClient ? 1 : 0) +
              (shareClient ? 1 : 0)) *
            40
        }"
      >
        <a
          *ngIf="editClient"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="Edit"
          (click)="edit.emit(row)"
          [matTooltipShowDelay]="750"
        >
          <mat-icon>edit</mat-icon>
        </a>
        <a
          *ngIf="detailsClient"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="Details"
          (click)="details.emit(row)"
          [matTooltipShowDelay]="750"
        >
          <mat-icon>details</mat-icon>
        </a>
        <a
          *ngIf="shareClient"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="Share"
          (click)="share.emit(row)"
          [matTooltipShowDelay]="750"
        >
          <mat-icon>share</mat-icon>
        </a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selectItem.emit(row)"
      matRipple
      [ngClass]="{ selected: selectedClient && selectedClient.id === row.id }"
    >
    </mat-row>
  </mat-table>
  <mat-paginator
    showFirstLastButtons
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="pageSize"
  ></mat-paginator>
</mat-card>
