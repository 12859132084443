import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './app.actions';

export interface State {
  loading: boolean;
  compilingPDFs: boolean;
  environment: string;
  phoneTypeCodes: any;
  prefixCodes: any;
  stateCodes: any;
  redirectURL: string;
}
export const initialState: State = {
  loading: false,
  compilingPDFs: false,
  environment: '',
  phoneTypeCodes: [],
  prefixCodes: [],
  stateCodes: [],
  redirectURL: '',
};

const appReducer = createReducer(
  initialState,
  on(fromActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(fromActions.setCompilingPDFs, (state, { compilingPDFs }) => ({
    ...state,
    compilingPDFs,
  })),
  on(fromActions.setEnvironment, (state, { environment }) => ({
    ...state,
    environment,
  })),
  //////////////////////////////////////
  // UI Codes
  //////////////////////////////////////
  on(fromActions.loadUICodesSuccess, (state, { response }) => {
    return {
      ...state,
      phoneTypeCodes: response.phone_types,
      prefixCodes: response.prefix,
      stateCodes: response.states,
    };
  }),
  on(fromActions.loadUICodesError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(fromActions.setRedirectURL, (state, { redirectURL }) => ({
    ...state,
    redirectURL,
  })),
  on(fromActions.clearState, (state) => ({
    ...state,
    loading: false,
    phoneTypeCodes: null,
    prefixCodes: null,
    stateCodes: null,
    redirectURL: '',
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return appReducer(state, action);
}
