<h1 class="mat-h1">Action Permissions</h1>
<div *ngIf="user && actions">
  <mat-card>
    <mat-card-title>{{ user.first_name }} {{ user.last_name }}</mat-card-title>
    <mat-card-subtitle> {{ user.username }}</mat-card-subtitle>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let module of modules; let i = index">
        <mat-expansion-panel-header>{{
          module.replace('_', ' ') | uppercase
        }}</mat-expansion-panel-header>
        <div *ngFor="let data of data[i]">
          <mat-slide-toggle
            [checked]="data.value"
            (change)="onChangePermission($event, module, data)"
            >{{ data.action | uppercase }}</mat-slide-toggle
          >
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
  <div class="buttons">
    <button
      *ngIf="isEdit && overrideExists"
      mat-stroked-button
      [disabled]="!dirty"
      color="primary"
      routerLink="/admin/users"
      (click)="onSavePermissions(updatedPermissions)"
    >
      SAVE
    </button>
    <button
      *ngIf="isEdit && !overrideExists"
      mat-stroked-button
      [disabled]="!dirty"
      color="primary"
      routerLink="/admin/users"
      (click)="onSavePermissions(updatedPermissions)"
    >
      CREATE OVERRIDE
    </button>
    <button
      *ngIf="configurationId && isEdit"
      mat-stroked-button
      color="warn"
      routerLink="/admin/users"
      (click)="delete.emit(configurationId)"
    >
      DELETE OVERRIDE
    </button>
    <button mat-stroked-button type="button" (click)="cancel.emit()">
      CANCEL
    </button>
  </div>
</div>
