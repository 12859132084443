<a [href]="url" target="_blank">
  <img
    *ngIf="organizationLogoUrl"
    class="sidenav-img"
    [src]="organizationLogoUrl"
    alt="Portal"
    (load)="imageLoaded()"
    />
</a>
<div *ngIf="authenticated && isLogoLoaded">
  <a
    *ngIf="contact"
    mat-stroked-button
    color="primary"
    class="container"
    [href]="contact"
    target="_blank"
    >
    Contact Us
  </a>

  <div *ngIf="navItems">
    <mat-nav-list>
      <app-nav-item
        *ngFor="let item of navItems"
        [item]="item"
        [roles]="roles"
        [accessList]="accessList"
        (selected)="selected.emit()"
        ></app-nav-item>

    </mat-nav-list>
  </div>

    <div style="width:100%" class="suggest-provider-content" *ngIf="roles.includes('user_attorney')">
        <button center color="primary" mat-flat-button [matMenuTriggerFor]="menu">Send a Referral</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="suggestProvider.emit()">Refer a Provider to ShareScape</button>
          <button mat-menu-item (click)="referPatient.emit()">Refer a Patient to a Provider</button>
        </mat-menu>
    </div>
</div>
