import { createAction, props } from '@ngrx/store';

export const loadProfile = createAction('[Core] Load Profile');
export const loadProfileSuccess = createAction(
  '[Core] Load Profile Success',
  props<{ user: any }>()
);
export const loadProfileError = createAction(
  '[Core] Load Profile Error',
  props<{ error: any }>()
);

export const updateProfile = createAction(
  '[Profile] Update Profile',
  props<{ input: any }>()
);
export const updateProfileSuccess = createAction(
  '[Profile] Update Profile Success',
  props<{ user: any }>()
);
export const updateProfileError = createAction(
  '[Profile] Update Profile Error',
  props<{ error: any }>()
);

export const clearState = createAction('[Core] Clear State');
