import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlName][trimWhitespace]'
})
export class TrimWhitespaceDirective {

  constructor(private control: NgControl) {
  }

  @HostListener('blur')
  onBlur(): void {
    if (typeof this.control.value === 'string') {
      this.control.control.setValue(this.control.value.trim());
    }
  }
}
