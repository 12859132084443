import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

export const getUserInformation = gql`
  query getUserInformation($id: uuid!) {
    users_by_pk(id: $id) {
      id
      eula_accepted
      group: user_groups {
        group_id
      }
      user: organization_users {
        organization {
          id
          name
          group {
            id
          }
        }
      }
      provider: provider_users {
        group_id
        organization {
          id
          name
          group {
            id
          }
        }
      }
    }
  }
`;

export const updateLastSeen = gql`
  mutation UpdateUserLastLogin($id: uuid!, $changes: users_set_input) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $changes) {
      id
      username
    }
  }
`;
export const getUserProviderSub = gql`
  subscription getUserProviderSub($id: uuid!) {
    provider_users(where: {user_id: {_eq: $id}}) {
      group_id
      organization {
        id
        name
        parent_id
        group {
          id
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  authenticate(username: string, password: string): Observable<any> {
    const url = `${environment.apiUrl}/v1/auth/login`;
    const body = { username, password };
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post(url, body, options);
  }

  sendMagicLink(username: string): Observable<any> {
    const url = `${environment.apiUrl}/v1/auth/login/magic`;
    const body = { username };
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post(url, body, options);
  }

  getUserInformation(id: string): Observable<any> {
    return this.apollo
      .query({
        query: getUserInformation,
        variables: { id },
      })
      .pipe(
        // tap((res: any) => console.log(res)),
        pluck('data', 'users_by_pk')
      );
  }

  updateLastSeen(id: string): Observable<any> {
    const changes = { last_login: new Date().toUTCString() };
    return this.apollo.mutate({
      mutation: updateLastSeen,
      variables: { id, changes },
    });
    // .pipe(tap((data) => console.log(`Mutation Update Last Seen: `, data)));
  }

  changePassword(userId: string, body: any, token: string): Observable<any> {
    const url = `${environment.apiUrl}/v1/auth/users/${userId}/password`;
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${token}`),
    };
    return this.http.put(url, body, options);
  }

  resetPasswordRequest(username: string): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/v1/auth/users/${username}/password/forgot`,
      null
    );
  }

  resetPassword(body: any): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/v1/auth/users/password/reset`,
      body
    );
  }

  getUserProvider(id: string): Observable<any> {
    return this.apollo
      .subscribe({
        query: getUserProviderSub,
        variables: {id},
      })
      .pipe(
        // pluck('data', 'users_by_pk')
        pluck('data', 'provider_users')
      );
  }
}
