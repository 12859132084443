import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-chirotouch-import-prompt',
  template: `
    <form [formGroup]="form" (submit)="importPatient()">
      <h3 mat-dialog-title>ChiroTouch Client Import</h3>
      <mat-dialog-content>
        <mat-form-field>
          <input
            matInput
            color="accent"
            formControlName="accountNo"
            placeholder="Account Number"
            required
            autocomplete="off"
          />
          <mat-error *ngIf="form.get('accountNo').invalid"
            >Must be numeric.</mat-error
          >
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button mat-button type="button" (click)="cancel()">
          CANCEL
        </button>
        <button mat-button type="submit" [disabled]="form.invalid">
          IMPORT
        </button>
      </mat-dialog-actions>
    </form>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        max-width: 300px;
      }
      mat-dialog-actions {
        display: flex;
        justify-content: flex-end;
      }
      [mat-button] {
        padding: 0;
      }
    `,
  ],
})
export class ChiroTouchImportPromptComponent implements OnInit {
  form: FormGroup;

  constructor(
    private ref: MatDialogRef<ChiroTouchImportPromptComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const numberRegex = /^[0-9]*$/;
    this.form = this.fb.group({
      accountNo: ['', [Validators.required, Validators.pattern(numberRegex)]],
    });
  }

  get accountNo() {
    return this.form.get('accountNo').value;
  }

  cancel() {
    this.ref.close(false);
  }

  importPatient() {
    this.ref.close(this.accountNo);
  }
}
