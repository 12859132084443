import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDateTime'
})
export class LocalDateTimePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      return new Date(value + 'Z').toLocaleString();
    } else {
      return null;
    }
  }
}
