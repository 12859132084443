<div class="mat-elevation-z4">
  <mat-table [dataSource]="dataSource" matSort>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          *ngIf="downloadFile && documents.length > 0"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          matTooltip="Toggle All Checkboxes"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          *ngIf="downloadFile && row.file_type == 'application/pdf'"
          (click)="$event.stopPropagation()"
          (change)="$event ? toggle(row) : null"
          [checked]="selection.isSelected(row.id)"
          matTooltip="Select To Compile"
        >
        </mat-checkbox>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
    </ng-container>

    <!-- File Type Icon Column -->
    <ng-container matColumnDef="fileType">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      ></mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        <mat-icon
          [matBadge]="getDownloadCount(row)"
          [matTooltip]="getDownloadCountToolTip(row)"
          >{{ documentIcon(row) }}</mat-icon
        >
      </mat-cell>
      <mat-footer-cell
        *matFooterCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      ></mat-footer-cell>
    </ng-container>

    <!-- File Display Name Column -->
    <ng-container matColumnDef="displayName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >File Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.display_name }}
      </mat-cell>
      <mat-footer-cell
        *matFooterCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >Total</mat-footer-cell
      >
    </ng-container>

    <!-- Document Type Column -->
    <ng-container matColumnDef="docTypeName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Document Type
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.type_name }}
      </mat-cell>
      <mat-footer-cell
        *matFooterCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      ></mat-footer-cell>
    </ng-container>

    <!-- Document Location Column -->
    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Location
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{
        row?.client_documents ?
          row?.client_documents[0]?.client?.provider ?
            row?.client_documents[0]?.client?.provider[0]?.organization?.name : '' : ''
        }}
      </mat-cell>
      <mat-footer-cell
        *matFooterCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      ></mat-footer-cell>
    </ng-container>

    <!-- File Size Column -->
    <ng-container matColumnDef="fileSize">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >File Size</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.file_size | fileSize }}</mat-cell
      >
      <mat-footer-cell
        *matFooterCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ getTotalSize() | fileSize }}</mat-footer-cell
      >
    </ng-container>

    <!-- Created At Column -->
    <ng-container matColumnDef="createdAt">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >Date</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.created_at | dateValidator: 'MM/dd/yyyy hh:mm a' }}</mat-cell
      >
      <mat-footer-cell
        *matFooterCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      ></mat-footer-cell>
    </ng-container>

    <!-- Updated At Column -->
    <ng-container matColumnDef="updatedAt">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'hide-column': screen.device === 'handset' }"
        >Modified</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'hide-column': screen.device === 'handset' }"
        >{{ row.updated_at | date: 'MM/dd/yyyy hh:mm a' }}</mat-cell
      >
      <mat-footer-cell
        *matFooterCellDef
        [ngClass]="{ 'hide-column': screen.device === 'handset' }"
      ></mat-footer-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell
        *matHeaderCellDef
        [ngStyle]="{
          'min-width.px':
            ((editDocument ? 1 : 0) +
              (detailsDocument ? 1 : 0) +
              (deleteDocument ? 1 : 0)) *
              40 +
            40
        }"
      >
        Actions
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngStyle]="{
          'min-width.px':
            ((editDocument ? 1 : 0) +
              (detailsDocument ? 1 : 0) +
              (deleteDocument ? 1 : 0)) *
              40 +
            40
        }"
      >
        <a
          *ngIf="detailsDocument"
          type="button"
          mat-icon-button
          color="primary"
          (click)="details.emit(row)"
          matTooltip="Details"
          [matTooltipShowDelay]="750"
        >
          <mat-icon>details</mat-icon>
        </a>
        <a
          *ngIf="editDocument"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="Edit"
          (click)="edit.emit(row)"
          [matTooltipShowDelay]="750"
        >
          <mat-icon>edit</mat-icon>
        </a>

        <!-- <a
          *ngIf="downloadFile"
          type="button"
          mat-icon-button
          color="accent"
          matTooltip="Download"
          (click)="download.emit(row)"
        >
          <mat-icon>cloud_download</mat-icon>
        </a> -->
        <a
          *ngIf="downloadFile"
          type="button"
          mat-icon-button
          color="accent"
          matTooltip="Preview"
          (click)="stream.emit(row)"
        >
          <mat-icon>open_in_new</mat-icon>
        </a>

        <a
          *ngIf="deleteDocument"
          type="button"
          mat-icon-button
          color="warn"
          matTooltip="Delete"
          (click)="delete.emit(row)"
        >
          <mat-icon>delete</mat-icon>
        </a>
      </mat-cell>
      <mat-footer-cell
        *matFooterCellDef
        [ngStyle]="{
          'min-width.px':
            ((editDocument ? 1 : 0) +
              (detailsDocument ? 1 : 0) +
              (deleteDocument ? 1 : 0)) *
              40 +
            40
        }"
      ></mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{
        selected: selectedDocument && selectedDocument.id === row.id
      }"
      (click)="selectItem.emit(row)"
    >
    </mat-row>
    <mat-footer-row
      *matFooterRowDef="displayedColumns"
      [ngClass]="{
        'hide-column': screen.device === 'handset' && screen.portrait
      }"
    ></mat-footer-row>
  </mat-table>
  <mat-paginator
    showFirstLastButtons="true"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="pageSize"
  ></mat-paginator>
</div>
