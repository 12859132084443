import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromActions from './email-settings.actions';

@Injectable({ providedIn: 'root' })
export class EmailSettingsFacade {
  constructor(private store: Store<any>) {}

  caseUpdateAlerts(input: any) {
    this.store.dispatch(fromActions.caseUpdateAlerts({ input }));
  }
}
