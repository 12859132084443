import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

export const GetUICodes = gql`
  query GetUICodes {
    states: codes(
      where: { type: { _eq: "state" } }
      order_by: { sort_order: asc }
    ) {
      type
      value
      description
      abbreviation
    }
    phone_types: codes(
      where: { type: { _eq: "phone-type" } }
      order_by: { sort_order: asc }
    ) {
      type
      value
      description
      abbreviation
    }
    prefix: codes(
      where: { type: { _eq: "prefix" } }
      order_by: { sort_order: asc }
    ) {
      type
      value
      description
      abbreviation
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AppService {
  getUICodes$: Observable<any> = this.apollo
    .query({ query: GetUICodes })
    .pipe(pluck('data'));

  constructor(private apollo: Apollo) {}

  resetStore(): Observable<any> {
    return from(this.apollo.getClient().resetStore());
  }
}
