import { Injectable } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  filter,
  withLatestFrom,
  tap,
} from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { AppFacade } from '@app/+state';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthFacade } from '@app/auth/+state';
import { ConfigurationService } from '@configuration/services/configuration.service';
import { LoggerService } from '@app/core/services';
import * as fromActions from './configuration.actions';

@Injectable()
export class ConfigurationEffects {
  @Effect()
  loadConfigurations$: Observable<Action> = combineLatest([
      this.auth.isAuthenticated$,
      this.auth.providerId$,
  ]).pipe(
    filter(([authenticated]) => authenticated),
    tap(() => this.app.setLoading(true)),
    withLatestFrom(this.auth.uid$),
    withLatestFrom(this.auth.organizations$),
    switchMap(([[[authenticated, providerId], uid], organization]) => {
      return this.configurationService.getConfigurations(uid, providerId, organization).pipe(
        map((configurations) => fromActions.loadSuccess({ configurations })),
        catchError((error) => of(fromActions.loadError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  loadConfigurationsSuccess$ = this.actions$.pipe(
    ofType(fromActions.loadSuccess),
    map(() => this.app.setLoading(false))
  );

  @Effect({ dispatch: false })
  loadConfigurationsError$ = this.actions$.pipe(
    ofType(fromActions.loadError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred loading Configuration.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  insertConfiguration$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.insertConfiguration),
    tap(() => this.app.setLoading(true)),
    switchMap(({ input }) => {
      return this.configurationService.insert(input).pipe(
        map(({ configuration }) =>
          fromActions.insertConfigurationSuccess({ configuration })
        ),
        catchError((error) =>
          of(fromActions.insertConfigurationError({ error }))
        )
      );
    })
  );

  @Effect({ dispatch: false })
  insertConfigurationSuccess$ = this.actions$.pipe(
    ofType(fromActions.insertConfigurationSuccess),
    tap(() => this.app.setLoading(false)),
    map(({ configuration }) => {
      const message = 'Configuration inserted successfully.';
      this.logger.notice(message);
    })
  );

  @Effect({ dispatch: false })
  insertConfigurationError$ = this.actions$.pipe(
    ofType(fromActions.insertConfigurationError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred saving a new configuration.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  updateConfiguration$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.updateConfiguration),
    tap(() => this.app.setLoading(true)),
    switchMap(({ input }) => {
      return this.configurationService.update(input).pipe(
        map((configuration) =>
          fromActions.updateConfigurationSuccess({ configuration })
        ),
        catchError((error) =>
          of(fromActions.updateConfigurationError({ error }))
        )
      );
    })
  );

  @Effect({ dispatch: false })
  updateConfigurationSuccess$ = this.actions$.pipe(
    ofType(fromActions.updateConfigurationSuccess),
    tap(() => this.app.setLoading(false)),
    map(({ configuration }) => {
      const message = 'Configuration updated successfully.';
      this.logger.notice(message);
    })
  );

  @Effect({ dispatch: false })
  updateConfigurationError$ = this.actions$.pipe(
    ofType(fromActions.updateConfigurationError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred saving configuration.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  deleteConfiguration$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.deleteConfiguration),
    tap(() => this.app.setLoading(true)),
    switchMap(({ input }) => {
      return this.configurationService.delete(input).pipe(
        map((configuration) =>
          fromActions.deleteConfigurationSuccess({ configuration })
        ),
        catchError((error) =>
          of(fromActions.deleteConfigurationError({ error }))
        )
      );
    })
  );

  @Effect({ dispatch: false })
  deleteConfigurationSuccess$ = this.actions$.pipe(
    ofType(fromActions.deleteConfigurationSuccess),
    tap(() => this.app.setLoading(false)),
    map(({ configuration }) => {
      const message = 'Configuration deleted successfully.';
      this.logger.notice(message);
    })
  );

  @Effect({ dispatch: false })
  deleteConfigurationError$ = this.actions$.pipe(
    ofType(fromActions.deleteConfigurationError),
    tap(() => this.app.setLoading(false)),
    map(({ error }) => {
      const message = 'An error occurred deleting configuration.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  logout$: Observable<Action> = this.auth.isAuthenticated$.pipe(
    filter((authenticated) => !authenticated),
    map(() => fromActions.clearState())
  );

  constructor(
    private actions$: Actions,
    private app: AppFacade,
    private auth: AuthFacade,
    private configurationService: ConfigurationService,
    private logger: LoggerService
  ) {}
}
