import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { ConfigurationService } from '@configuration/services/configuration.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-transaction-new-payment',
  templateUrl: './transaction-new-payment.component.html',
  styleUrls: ['./transaction-new-payment.component.scss'],
})
export class TransactionNewPaymentComponent implements OnInit {
  @Input() clientId: string;
  @Input() transactionTypes: any;

  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  typeFormGroup: FormGroup;
  detailsFormGroup: FormGroup;

  // https://www.regextester.com/97725
  regNumber = '^[0-9]+(\.[0-9]{1,2})?$';

  constructor(
    private fb: FormBuilder,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this.typeFormGroup = this.fb.group({
      id: [uuid(), Validators.required],
      company: '',
      type: ['', Validators.required],
      type_name: '',
    });
    this.detailsFormGroup = this.fb.group({
      description: '',
      date_posted: new Date().toISOString(),
      check_date: new Date().toISOString(),
      check_number: '',
      payment: [0, Validators.pattern(this.regNumber)],
      final: false,
      created_at: [new Date().toISOString(), Validators.required],
      updated_at: [new Date().toISOString(), Validators.required],
      // timestamp: [new Date().toISOString(), Validators.required],
    });
  }

  get id() {
    return this.typeFormGroup.get('id').value;
  }

  done() {
    const object = {
      clientId: this.clientId,
      transactionId: this.id,
      payment: {
        ...this.typeFormGroup.value,
        ...this.detailsFormGroup.value,
        client_transaction_payments: { data: { client_id: this.clientId } },
      },
    };
    this.create.emit(object);
  }

  /**
   * Keep the type and type_name fields in sync
   *
   * @param changedValue: the id of the type
   */
  setTypeName(changedValue: MatSelectChange) {
    this.typeFormGroup
      .get('type_name')
      .setValue(
        this.configurationService.getTypeName(
          changedValue.value,
          this.transactionTypes
        )
      );
  }
}
