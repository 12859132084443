import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavItem } from '@core/models';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent implements OnInit {
  @Input() item: NavItem;
  @Input() roles: Array<string>;
  @Input() accessList: Array<string>;
  @Output() selected: EventEmitter<NavItem> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
