import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'organizationNames',
  pure: true,
})
export class OrganizationNamesPipe implements PipeTransform {
  transform(organizations: any, ...args: any[]): any {
    if (organizations && organizations.length > 0) {
      const orgList = organizations.map((organization) =>
        organization.organization && organization.organization.name
          ? organization.organization.name
          : ''
      );
      return orgList.sort((a, b) => (a > b ? 1 : -1)).join(' | ');
    } else {
      return '';
    }
  }
}
