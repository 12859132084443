import {
  Component,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Screen } from '@core/models';
@Component({
  selector: 'app-organizations-list-pagination',
  templateUrl: './organizations-list-pagination.component.html',
  styleUrls: ['./organizations-list-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsListPaginationComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() screen: Screen;
  @Input() displayedColumns = [];
  @Input() organizations: any[] = [];
  @Input() selectedOrganization: any = null;
  @Input() pageSize: number;
  @Input() organizationsListTableInformation: PageEvent = null;
  @Input() organizationsListTableSort: Sort = null;
  @Input() count = 0;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() details: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() tableInformation: EventEmitter<PageEvent> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
  @Output() tableSort: EventEmitter<Sort> = new EventEmitter();
  @Output() createUser: EventEmitter<any> = new EventEmitter();

  dataSource = new MatTableDataSource<any>();

  ngOnChanges() {
    if (this.organizations) {
      this.dataSource.data = this.organizations;
    }
  }

  ngAfterViewInit() {
    if (this.sort) {
      this.sort.sortChange
        .pipe(
          takeUntil(this.destroy$),
          map((sortInformation) => this.tableSort.emit(sortInformation))
        )
        .subscribe();
      this.paginator.page
        .pipe(
          takeUntil(this.destroy$),
          map((val) => {
            if (
              val.pageSize !== this.organizationsListTableInformation.pageSize
            ) {
              this.pageSizeChanged.emit(val.pageSize);
            }
            this.tableInformation.emit(val);
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
