import { Component } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthFacade } from '../../../auth/+state';
import { MyErrorStateMatcher } from '../password-reset/password-reset.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  myForm: FormGroup;

  hideCurrent = true;
  hideNew = true;
  hideConfirm = true;

  matcher = new MyErrorStateMatcher();

  constructor(private fb: FormBuilder, public auth: AuthFacade) {
    this.myForm = this.fb.group(
      {
        currentPassword: ['', [Validators.required, Validators.minLength(8)]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: '',
      },
      { validator: this.checkPasswords }
    );
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  // Grab the User's ID from the store and call changePassword
  onChangePassword() {
    const body = {
      current_password: this.myForm.get('currentPassword').value,
      new_password: this.myForm.get('password').value,
    };
    this.auth.changePassword(body);
  }
}
