import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AuthGuardUserService,
  AuthGuardAdminService,
  AuthGuardProviderService,
  AuthGuardReportsService,
} from '@auth/services';
import {
  LoginComponent,
  PageNotFoundComponent,
  RequestPasswordResetComponent,
  ChangePasswordComponent,
  PasswordResetComponent,
  MagicLoginComponent,
} from './auth/components';
const routes: Routes = [
  { path: '', redirectTo: 'clients', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'password-reset-request', component: RequestPasswordResetComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'magic-login', component: MagicLoginComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  {
    path: 'admin',
    canActivateChild: [AuthGuardAdminService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'clients',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./clients/clients.module').then((m) => m.ClientsModule),
  },
  {
    path: 'dashboard',
    canActivateChild: [AuthGuardProviderService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'documents',
    runGuardsAndResolvers: 'always',
    canActivateChild: [AuthGuardUserService],
    loadChildren: () =>
      import('./documents/documents.module').then((m) => m.DocumentsModule),
  },
  {
    path: 'organizations',
    canActivateChild: [AuthGuardProviderService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./organizations/organizations.module').then(
        (m) => m.OrganizationsModule
      ),
  },
  {
    path: 'organization',
    canActivateChild: [AuthGuardProviderService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
        import('./my-organization/my-organization.module').then(
            (m) => m.MyOrganizationModule
        ),
  },
  {
    path: 'reports',
    canActivateChild: [AuthGuardReportsService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'transactions',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./transactions/transactions.module').then(
        (m) => m.TransactionsModule
      ),
  },
  {
    path: 'users',
    canActivateChild: [AuthGuardProviderService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'profile',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },

  { path: '**', component: PageNotFoundComponent }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
