import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'providerNames'
})
export class ProviderNamesPipe implements PipeTransform {

  transform(providers: any, ...args: any[]): any {
    if (providers && providers.length > 0) {
      const orgList = providers.map((p) =>
        p.provider && p.provider.name
          ? p.provider.name
          : ''
      );
      return orgList.sort((a, b) => (a > b ? 1 : -1)).join(' | ');
    } else {
      return '';
    }
  }

}
