export const defaultTheme = 'app-theme-light';
export const darkTheme = 'app-theme-dark';
const availableThemes: Array<string> = [defaultTheme, darkTheme];
const themeStoreKey = 'themeV2';
const selectedOrganization = 'selected-organization';
const profileUpdateReminder = 'profile-update-reminder';

/**
 * Function used for determining if the current selected theme is valid.
 * Useful for logic dependent if the user is using a deprecated theme.
 *
 * @param t name of theme
 */
export function validateTheme(t?: string): string {
  if (typeof t !== 'string') {
    return defaultTheme;
  }
  return availableThemes.includes(t) ? t : defaultTheme;
}

export function isDarkThemeActiveLocal(): boolean {
  return isDarkTheme(getThemeLocal());
}

export function isDarkTheme(t?: string): boolean {
  return t === darkTheme;
}

export function getThemeLocal(): string {
  let t = localStorage.getItem(themeStoreKey);
  if (typeof t !== 'string') {
    t = defaultTheme;
  }
  return validateTheme(t);
}

export function upsertThemeLocal(t: string) {
  t = validateTheme(t);
  localStorage.setItem(themeStoreKey, t);
}

export function setOrganizationLocal(org: any) {
  localStorage.setItem(selectedOrganization, org);
}

export function getOrganizationLocal() {
  return localStorage.getItem(selectedOrganization);
}

export function setProfileUpdateReminder(value) {
   localStorage.setItem(profileUpdateReminder, value);
}

export function getProfileUpdateReminder() {
  return localStorage.getItem(profileUpdateReminder);
}
