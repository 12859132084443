<!--Disable and Hide buttons in right corner of merged /clients-->
<!--<ng-container *ngIf="roles?.includes('user_attorney') &&-->
<!--              client?.clients_merged && client?.clients_merged.length > 0 &&-->
<!--              client?.clients_merged[0].merged_client !== null">-->
<!--  <div class="example-button-row" style="float: right">-->
<!--    <button mat-button color="primary"-->
<!--            (click)="loadClient(client?.id)">{{client.date_of_injury | date: 'mediumDate'}}</button>-->
<!--    <span *ngFor="let value of client?.clients_merged">-->
<!--            <button mat-button (click)="loadClient(value?.merged_client?.id)">-->
<!--                {{value?.merged_client?.date_of_injury | date: 'mediumDate'}}-->
<!--            </button>-->
<!--        </span>-->
<!--  </div>-->
<!--</ng-container>-->

<h1 class="mat-h1">Client Information</h1>
<div *ngIf="clientForm" class="form-container">
  <form [formGroup]="clientForm" onSubmit="onSave()">
    <mat-card>
      <mat-card-title> Name Information </mat-card-title>
      <mat-card-content>
        <div class="flex-container">
          <mat-form-field class="item">
            <input
              matInput
              placeholder="First Name"
              formControlName="first_name"
              [readonly]="!isEdit && !isNew"
            />
          </mat-form-field>
          <mat-form-field class="item-middle-initial">
            <input
              matInput
              placeholder="MI"
              formControlName="middle_initial"
              [readonly]="!isEdit && !isNew"
            />
          </mat-form-field>
          <mat-form-field class="item">
            <input
              matInput
              placeholder="Last Name"
              formControlName="last_name"
              [readonly]="!isEdit && !isNew"
              required
            />
          </mat-form-field>
          <mat-form-field class="item-email">
            <input
              matInput
              placeholder="Email"
              formControlName="email"
              [readonly]="!isEdit && !isNew"
            />
            <mat-error
              *ngIf="
                clientForm.get('email').invalid &&
                !clientForm.get('email').pristine &&
                clientForm.get('email').touched
              "
              >Please enter a valid email address.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="flex-container">
          <mat-form-field class="item-date">
            <input
              matInput
              formControlName="date_of_birth"
              [matDatepicker]="dateOfBirth"
              placeholder="Date of Birth"
              [readonly]="!isEdit && !isNew"
              dateMask
            />
            <mat-datepicker-toggle
              *ngIf="isEdit || isNew"
              matSuffix
              [for]="dateOfBirth"
            ></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirth></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="item-date">
            <input
              matInput
              formControlName="date_of_injury"
              [matDatepicker]="dateOfInjury"
              placeholder="Date of Injury"
              [readonly]="!isEdit && !isNew"
              dateMask
            />
            <mat-datepicker-toggle
              *ngIf="isEdit || isNew"
              matSuffix
              [for]="dateOfInjury"
            ></mat-datepicker-toggle>
            <mat-datepicker #dateOfInjury></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- Gender -->
        <mat-radio-group formControlName="gender" class="gender-radio-group">
          <mat-radio-button value="M" [disabled]="isDetails || isShare"
            >Male</mat-radio-button
          >
          <mat-radio-button value="F" [disabled]="isDetails || isShare"
            >Female</mat-radio-button
          >
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
  </form>

  <!-- Organizations -->
  <form [formGroup]="organizations">
    <mat-card>
      <mat-card-title>Shared Organizations</mat-card-title>
      <mat-card-content
        *ngIf="
          clientOrganizations && clientOrganizations.length > 0 && !isDetails
        "
      >
        <div formArrayName="organizations">
          <div
            *ngFor="let item of organizationsForm.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="flex-container">
              <a
                *ngIf="isEdit || isShare || isNew"
                mat-icon-button
                class="icon-button"
                color="warn"
                (click)="onDeleteOrganizationItem(i)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </a>
              <div class="item">
                <app-organizations-popup
                  [options]="clientOrganizations"
                  [selectedItem]="item.value"
                  (selectionChanged)="organizationChanged(item, $event)"
                ></app-organizations-popup>
              </div>
            </div>
          </div>
          <a
            *ngIf="(isEdit || isShare || isNew) && organizationsForm.length < 1"
            mat-icon-button
            class="icon-button"
            color="primary"
            (click)="onAddOrganizationItem()"
          >
            <mat-icon>add_circle_outline</mat-icon>
          </a>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="isDetails">
        <div formArrayName="organizations">
          <div
            *ngFor="let item of organizationsForm.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="flex-container">
              <mat-form-field class="item">
                <input
                  matInput
                  placeholder="Organization Name"
                  formControlName="name"
                  [readonly]="isDetails || isShare"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>

  <!-- Case Contact -->
<!--  <form [formGroup]="caseContact">-->
<!--    <mat-card>-->
<!--      <mat-card-title>Case Contact</mat-card-title>-->
<!--      <mat-card-content-->
<!--        *ngIf="-->
<!--          clientCaseContact && clientCaseContact.length > 0-->
<!--        "-->
<!--      >-->
<!--        <div formArrayName="caseContact">-->
<!--          <div-->
<!--            *ngFor="let item of caseContactForm.controls; let i = index"-->
<!--            [formGroupName]="i"-->
<!--          >-->
<!--            <div class="flex-container">-->
<!--              <a-->
<!--                *ngIf="isDetails && item.status == 'INVALID'"-->
<!--                mat-icon-button-->
<!--                class="icon-button"-->
<!--                color="warn"-->
<!--                (click)="onDeleteCaseContactItem(i)"-->
<!--              >-->
<!--                <mat-icon>remove_circle_outline</mat-icon>-->
<!--              </a>-->
<!--              <div class="item">-->
<!--                <app-client-case-contact-->
<!--                  [options]="clientCaseContact"-->
<!--                  [selectedItem]="item.value"-->
<!--                  (selectionChanged)="caseContactChanged(item, $event)"-->
<!--                ></app-client-case-contact>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <a-->
<!--            *ngIf="isDetails"-->
<!--            mat-icon-button-->
<!--            class="icon-button"-->
<!--            color="primary"-->
<!--            (click)="onAddCaseContactItem()"-->
<!--          >-->
<!--            <mat-icon>add_circle_outline</mat-icon>-->
<!--          </a>-->
<!--        </div>-->
<!--      </mat-card-content>-->
<!--      <mat-card-content *ngIf="!isDetails">-->
<!--         <div formArrayName="caseContact">-->
<!--           <div-->
<!--             *ngFor="let item of caseContactForm.controls; let i = index"-->
<!--             [formGroupName]="i"-->
<!--           >-->
<!--             <div class="flex-container">-->
<!--               <mat-form-field class="item">-->
<!--                 <input-->
<!--                   matInput-->
<!--                   placeholder="Display Name"-->
<!--                   formControlName="name"-->
<!--                   [readonly]="true"-->
<!--                 />-->
<!--               </mat-form-field>-->
<!--             </div>0-->

<!--           </div>-->
<!--         </div>-->
<!--       </mat-card-content>-->
<!--    </mat-card>-->
<!--  </form>-->

  <!-- Addresses -->
  <form [formGroup]="addresses">
    <mat-card>
      <mat-card-title>Addresses</mat-card-title>
      <mat-card-content>
        <div formArrayName="addresses">
          <div
            *ngFor="let item of addressesForm.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="flex-container-nowrap">
              <a
                *ngIf="isEdit || isNew"
                mat-icon-button
                class="icon-button"
                color="warn"
                (click)="onDeleteAddressItem(i)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </a>
              <div class="addresses">
                <app-address-form
                  [isReadOnly]="!(isEdit || isNew)"
                  [addressForm]="item"
                  [states]="states"
                ></app-address-form>
              </div>
            </div>
          </div>
          <a
            *ngIf="isEdit || isNew"
            mat-icon-button
            class="icon-button"
            color="primary"
            (click)="onAddAddressItem()"
          >
            <mat-icon>add_circle_outline</mat-icon>
          </a>
        </div>
      </mat-card-content>
    </mat-card>
  </form>

  <!-- Phone Numbers -->
  <form [formGroup]="phoneNumbers">
    <mat-card>
      <mat-card-title>Phone Numbers</mat-card-title>
      <mat-card-content>
        <div formArrayName="phoneNumbers">
          <div
            *ngFor="let item of phoneNumbersForm.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="flex-container-nowrap">
              <a
                *ngIf="isEdit || isNew"
                mat-icon-button
                class="icon-button"
                color="warn"
                (click)="onDeletePhoneNumberItem(i)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </a>
              <div class="phoneNumbers">
                <app-phone-form
                  [isReadOnly]="!(isEdit || isNew)"
                  [phoneNumbersForm]="item"
                  [phoneTypes]="phoneTypes"
                ></app-phone-form>
              </div>
            </div>
          </div>
        </div>
        <a
          *ngIf="isEdit || isNew"
          mat-icon-button
          class="icon-button"
          color="primary"
          (click)="onAddPhoneNumberItem()"
        >
          <mat-icon>add_circle_outline</mat-icon>
        </a>
      </mat-card-content>
    </mat-card>
  </form>

  <div>
    <button
      *ngIf="isEdit || isShare"
      mat-stroked-button
      type="button"
      color="primary"
      (click)="onSave()"
      routerLink="/clients"
      [disabled]="
        !clientForm.valid || !addressesForm.valid || !phoneNumbersForm.valid
      "
    >
      SAVE
    </button>
<!--    <button-->
<!--      *ngIf="isDetails"-->
<!--      mat-stroked-button-->
<!--      type="button"-->
<!--      color="primary"-->
<!--      (click)="onSaveCaseContact()"-->
<!--    >-->
<!--      SAVE-->
<!--    </button>-->
    <button
      *ngIf="isNew"
      mat-stroked-button
      type="button"
      color="primary"
      (click)="onCreate()"
      routerLink="/clients"
      [disabled]="
        !clientForm.valid || !addressesForm.valid || !phoneNumbersForm.valid
      "
    >
      CREATE
    </button>
    <button
      *ngIf="!isDetails"
      mat-stroked-button
      type="button"
      (click)="cancel.emit()"
    >
      CANCEL
    </button>
    <button
      *ngIf="isDetails"
      mat-stroked-button
      type="button"
      (click)="cancel.emit()"
    >
      BACK
    </button>
    <button class="delete"
            [disabled]="!roles.includes('provider_tier1')"
            *ngIf="isEdit"
            type="button"
            mat-stroked-button
            color="warn"
              matTooltip="Delete"
              [matTooltipShowDelay]="750"
            (click)="onDelete()"
    >
      <mat-icon>delete</mat-icon>
      DELETE
    </button>
  </div>
</div>
<!-- <h2>Variables</h2>
isNew: {{ isNew }}<br />
isShare: {{ isShare }}<br />
isDetails: {{ isDetails }}<br />
isEdit: {{ isEdit }}<br /> -->

<!-- <h2>Client</h2>
<pre>{{ client | json }}</pre> -->

<!-- <h2>Client Form</h2>
Dirty:{{ clientForm.dirty }} Valid:{{ clientForm.valid }}
<pre>{{ clientForm.value | json }}</pre> -->

<!-- <h2>Organizations Form</h2>
Dirty:{{ organizationsForm.dirty }} Valid:{{ organizationsForm.valid }}
<pre>{{ organizationsForm.value | json }}</pre> -->

<!-- <h2>Addresses Form</h2>
Dirty:{{ addressesForm.dirty }} Valid:{{ addressesForm.valid }}
<pre>{{ addresses.value | json }}</pre> -->

<!-- <h2>Phone Numbers Form</h2>
Dirty:{{ phoneNumbersForm.dirty }} Valid: {{ phoneNumbersForm.valid }}
<pre>{{ phoneNumbers.value | json }}</pre> -->
