import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';

import * as fromActions from './auth.actions';
import * as fromReducer from './auth.reducer';
import * as fromSelectors from './auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  idToken$: Observable<firebase.auth.IdTokenResult> = this.afs.idTokenResult;

  isAuthenticated$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectAuthenticated)
  );

  uid$: Observable<string> = this.store.pipe(select(fromSelectors.selectUID));

  username$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectUsername)
  );

  organizations$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectOrganizations)
  );

  organizationNames$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectOrganizationNames)
  );

  roles$: Observable<string[]> = this.store.pipe(
    select(fromSelectors.selectRoles)
  );

  globalRole$: Observable<string[]> = this.store.pipe(
    select(fromSelectors.selectGlobalRole)
  );

  providerId$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectProviderId)
  );

  providerUser$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectProviderUser)
  );

  usernameUID$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectUsernameUID)
  );

  constructor(
    private store: Store<fromReducer.State>,
    private afs: AngularFireAuth
  ) {}

  authenticate(username: string, password: string) {
    this.store.dispatch(fromActions.authenticate({ username, password }));
  }

  magicAuthenticate(token: string) {
    this.store.dispatch(fromActions.magicAuthenticate({ token }));
  }

  sendMagicLink(username: string) {
    this.store.dispatch(fromActions.sendMagicLink({ username }));
  }

  login() {
    this.store.dispatch(fromActions.login());
  }

  logout() {
    this.store.dispatch(fromActions.logout());
  }

  requestPasswordReset(username: any) {
    this.store.dispatch(fromActions.requestPasswordReset({ username }));
  }

  passwordReset(body: any) {
    this.store.dispatch(fromActions.passwordReset({ body }));
  }

  changePassword(body: any) {
    this.store.dispatch(fromActions.changePassword({ body }));
  }

  setProvider(provider: any) {
    this.store.dispatch(fromActions.setProvider({ provider }));
  }
}
