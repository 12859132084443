import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import * as fromReducer from './router.reducer';

export const getRouterState = createFeatureSelector<
  RouterReducerState<fromReducer.RouterStateUrl>
>('router');

export const selectParams = createSelector(
  getRouterState,
  state => state.state.params
);

export const selectUrl = createSelector(
  getRouterState,
  state => state.state.url
);

export const selectQueryParams = createSelector(
  getRouterState,
  state => state.state.queryParams
);
