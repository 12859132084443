<div class="header">
  <mat-form-field>
    <mat-icon matSuffix>search</mat-icon
    ><input
      matInput
      (keyup)="applyFilter($event.target.value)"
      placeholder="Organization Name"
      aria-label="Search"
      autocomplete="off"
    />
  </mat-form-field>
  <div>
    <a
      mat-stroked-button
      color="primary"
      (click)="saveReport.emit(organizations)"
    >
      EXPORT
    </a>
  </div>
</div>
<div class="mat-elevation-z8">
  <mat-table
    [dataSource]="dataSource"
    matSort
    matSortDisableClear
    matSortActive="Organization"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="Organization">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Organization
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.Organization }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="PhoneNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        PhoneNumber
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.PhoneNumber }}</mat-cell>
      <!-- <mat-cell *matCellDef="let row">{{ row.phoneNumber }}</mat-cell> -->
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" matRipple>
    </mat-row>
  </mat-table>
  <mat-paginator
    showFirstLastButtons
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="pageSize"
  ></mat-paginator>
</div>
