import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CONFIGURATION_FRAGMENT } from '@configuration/fragments';

export const getConfigurationsWithProvider = gql`
  query GetConfigurationsWithProvider(
    $provider_id: uuid!
    $user_id: uuid!
    $group_id: [String!]
  ) {
    default: default_configurations(
      args: { p_user_id: $user_id }
      where: { type: { _neq: "Themes" } }
    ) {
      ...configurationFragment
    }
    provider: provider_configurations(
      where: { provider_id: { _eq: $provider_id } }
    ) {
      configuration {
        ...configurationFragment
      }
    }
    user: user_configurations(where: { user_id: { _eq: $user_id } }) {
      configuration {
        ...configurationFragment
      }
    }
    group: group_configurations(where: { group_id: { _in: $group_id } }) {
      configuration {
        ...configurationFragment
      }
    }
  }
  ${CONFIGURATION_FRAGMENT}
`;

export const getConfigurations = gql`
  query GetConfigurations($user_id: uuid!, $group_id: String!) {
    default: default_configurations(
     args: { p_user_id: $user_id }
     where: { type: { _neq: "Themes" } }
    ) {
      ...configurationFragment
    }
    user: user_configurations(where: { user_id: { _eq: $user_id } }) {
      configuration {
        ...configurationFragment
      }
    }
    group: group_configurations(where: {group_id: {_eq: $group_id}}) {
      configuration {
        ...configurationFragment
      }
    }
  }
  ${CONFIGURATION_FRAGMENT}
`;

/**
 * MUTATIONS
 */
export const insertConfiguration = gql`
  mutation InsertUserConfiguration($object: user_configurations_insert_input!) {
    insert_user_configurations_one(object: $object) {
      configuration {
        ...configurationFragment
      }
    }
  }
  ${CONFIGURATION_FRAGMENT}
`;

export const updateConfiguration = gql`
  mutation updateConfiguration($id: uuid!, $body: json) {
    update_configurations_by_pk(
      pk_columns: { id: $id }
      _set: { body: $body }
    ) {
      ...configurationFragment
    }
  }
  ${CONFIGURATION_FRAGMENT}
`;

export const deleteConfiguration = gql`
  mutation deleteConfiguration($id: uuid!) {
    delete_configurations_by_pk(id: $id) {
      ...configurationFragment
    }
  }
  ${CONFIGURATION_FRAGMENT}
`;

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private apollo: Apollo) {}

  /**
   * Retrieve the configurations rows for the user logged in
   * @param userId: User's Id
   * @param providerId: User's Provider Id if the user is a Provider
   *
   * Due to the way Hasura optimizes queries, a null providerId will
   * return all provider_configurations rows rather than no rows.
   *
   * Therefore: if the user doesn't have a provider_id
   *    i.e. the user is a Law Firm user
   * Then: send the userId in for the providerId to result in no provider_configurations
   * overrides.
   */
  getConfigurations(uid: string, pid?: string, organization?: any): Observable<any> {
    const userId = uid;
    // const providerId = pid ? pid : uid;
    // if the user have a provider_id then the user is Provider user
    const providerId = pid;
    if (providerId) {
      const org = organization ? {...organization[0]} : {};
      const groupId = org?.group?.id;
      return this.apollo
        .query({
          query: getConfigurationsWithProvider,
          variables: {
            provider_id: providerId,
            user_id: userId,
            group_id: groupId
          },
        })
        .pipe(pluck('data'));
    } else {
      // if the user doesn't have a provider_id then the user is a Law Firm user
      const groupId = 'user_attorney';
      return this.apollo
        .query({
          query: getConfigurations,
          variables: { user_id: userId, group_id: groupId },
        })
        .pipe(pluck('data'));
    }
  }

  insert(input: any): Observable<any> {
    return this.apollo
      .mutate({ mutation: insertConfiguration, variables: input })
      .pipe(
        // tap((data) => console.log(`Mutation Insert:`, data)),
        pluck('data', 'insert_user_configurations_one')
      );
  }

  update(input: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: updateConfiguration,
        variables: { id: input.id, body: input.body },
      })
      .pipe(
        // tap((data) => console.log(`Mutation Update:`, data)),
        pluck('data', 'update_configurations_by_pk')
      );
  }

  delete(input: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: deleteConfiguration,
        variables: { id: input.id },
      })
      .pipe(
        // tap((data) => console.log(`Mutation Delete:`, data)),
        pluck('data', 'delete_configurations_by_pk')
      );
  }

  /**
   * Return the name associated with the type id from
   * the passed in list of config types
   *
   * @param id = configuration type id
   * @param types = the list of all configuration types
   */
  getTypeName(id: string, types: any): string {
    const found = types.find((type) => type.id.toString() === id);
    return found ? found.name : id;
  }
}
