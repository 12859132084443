import { Component, OnInit } from '@angular/core';
import { LoggerService } from '@core/services';

@Component({
  selector: 'app-page-not-found',
  template: `<mat-card>
    <mat-card-title>Oops! 404 - PAGE NOT FOUND</mat-card-title>
    The page you are looking for might have been removed or is temporarily
    unavailable.
    <mat-card-actions>
      <button mat-button color="primary" routerLink="..">GO BACK</button>
      <button mat-button color="accent" routerLink="/">GO TO HOMEPAGE</button>
    </mat-card-actions>
  </mat-card>`,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        margin: 100px 0px;
      }
    `,
  ],
})
export class PageNotFoundComponent implements OnInit {
  constructor(private logger: LoggerService) {}
  ngOnInit() {
    this.logger.alert('navigated to page-not-found');
  }
}
