import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { PHONE_NUMBER_FRAGMENT } from '@phone-numbers/fragments';

export const createOrganizationPhoneNumber = gql`
  mutation CreateOrganizationPhoneNumber(
    $object: [organization_phone_numbers_insert_input!]!
  ) {
    insert_organization_phone_numbers(objects: $object) {
      returning {
        phone_number_id
        organization_id
        phone_number {
          ...phoneNumberFragment
        }
      }
    }
  }
  ${PHONE_NUMBER_FRAGMENT}
`;

export const createClientPhoneNumber = gql`
  mutation CreateClientPhoneNumber(
    $object: [client_phone_numbers_insert_input!]!
  ) {
    insert_client_phone_numbers(objects: $object) {
      returning {
        phone_number_id
        client_id
        phone_number {
          ...phoneNumberFragment
        }
      }
    }
  }
  ${PHONE_NUMBER_FRAGMENT}
`;

export const createUserPhoneNumber = gql`
  mutation CreateUserPhoneNumber($object: [user_phone_numbers_insert_input!]!) {
    insert_user_phone_numbers(objects: $object) {
      returning {
        phone_number_id
        user_id
        phone_number {
          ...phoneNumberFragment
        }
      }
    }
  }
  ${PHONE_NUMBER_FRAGMENT}
`;

export const updatePhoneNumber = gql`
  mutation UpdatePhoneNumber($id: uuid, $changes: phone_numbers_set_input) {
    update_phone_numbers(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        ...phoneNumberFragment
      }
    }
  }
  ${PHONE_NUMBER_FRAGMENT}
`;

export const deletePhoneNumber = gql`
  mutation DeletePhoneNumber($id: uuid) {
    delete_phone_numbers(where: { id: { _eq: $id } }) {
      returning {
        ...phoneNumberFragment
      }
    }
  }
  ${PHONE_NUMBER_FRAGMENT}
`;

@Injectable({
  providedIn: 'root',
})
export class PhoneNumbersService {
  constructor(private apollo: Apollo) {}

  createOrganizationPhoneNumber(phoneNumber: any): Observable<any> {
    // Manufacture insert mutation object
    const mutation = {
      object: {
        organization_id: phoneNumber.organization_id,
        phone_number: {
          data: {
            number: phoneNumber.phoneNumber.number,
            type: phoneNumber.phoneNumber.type,
          },
        },
      },
    };
    return this.apollo
      .mutate({
        mutation: createOrganizationPhoneNumber,
        variables: mutation,
      })
      .pipe(pluck('data', 'insert_organization_phone_numbers', 'returning'));
  }

  createClientPhoneNumber(phoneNumber: any): Observable<any> {
    // Manufacture insert mutation object
    const mutation = {
      object: {
        client_id: phoneNumber.client_id,
        phone_number: {
          data: {
            number: phoneNumber.phoneNumber.number,
            type: phoneNumber.phoneNumber.type,
          },
        },
      },
    };
    return this.apollo
      .mutate({
        mutation: createClientPhoneNumber,
        variables: mutation,
      })
      .pipe(pluck('data', 'insert_client_phone_numbers', 'returning'));
  }

  createUserPhoneNumber(phoneNumber: any): Observable<any> {
    // Manufacture insert mutation object
    const mutation = {
      object: {
        user_id: phoneNumber.user_id,
        phone_number: {
          data: {
            number: phoneNumber.phoneNumber.number,
            type: phoneNumber.phoneNumber.type,
          },
        },
      },
    };
    return this.apollo
      .mutate({
        mutation: createUserPhoneNumber,
        variables: mutation,
      })
      .pipe(pluck('data', 'insert_user_phone_numbers', 'returning'));
  }

  update(input: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: updatePhoneNumber,
        variables: { id: input.id, changes: input },
      })
      .pipe(pluck('data', 'update_phone_numbers', 'returning'));
  }

  delete(phoneNumber: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: deletePhoneNumber,
        variables: { id: phoneNumber.id },
      })
      .pipe(pluck('data', 'delete_phone_numbers', 'returning'));
  }
}
