import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumbers',
  pure: true,
})
export class PhoneNumbersPipe implements PipeTransform {
  transform(phoneNumbers: any, ...args: any[]): any {
    if (phoneNumbers && phoneNumbers.length > 0) {
      const phoneList = phoneNumbers.map((phoneNumber) =>
        phoneNumber.phone_number && phoneNumber.phone_number.number
          ? phoneNumber.phone_number.number
          : ''
      );
      return phoneList.join(' | ');
    } else {
      return '';
    }
  }
}
