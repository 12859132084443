import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Screen } from '@core/models';

@Component({
  selector: 'app-organizations-clients-list',
  templateUrl: './organizations-clients-list.component.html',
  styleUrls: ['./organizations-clients-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsClientsListComponent
  implements OnChanges, AfterViewInit, OnDestroy {
  @Input() createClient = false;
  @Input() editClient = false;
  @Input() detailsClient = false;
  @Input() shareClient = false;

  @Input() screen: Screen;
  @Input() selectedOrganization: any;
  @Input() displayedColumns = [];
  @Input() clients: any[] = [];
  @Input() selectedClient: any = null;
  @Input() pageSize: number;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() details: EventEmitter<any> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() share: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$: Subject<boolean> = new Subject<boolean>();
  dataSource = new MatTableDataSource<any>();

  ngOnChanges() {
    if (this.clients) {
      this.dataSource.data = this.clients;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'lastName':
          return item.last_name;
        case 'firstName':
          return item.first_name;
        case 'dateOfInjury':
          return item.date_of_injury;
        case 'balance':
          return item.client_balance.balance;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.page
      .pipe(
        takeUntil(this.destroy$),
        map((val) => {
          if (val.pageSize !== this.pageSize) {
            this.pageSizeChanged.emit(val.pageSize);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
