<h1 class="mat-h1">{{ header }}</h1>
<form [formGroup]="userForm">
  <mat-card>
    <mat-card-title>Username</mat-card-title>
    <mat-card-content class="flex-container">
      <mat-form-field class="item-username">
        <input
          matInput
          placeholder="Email"
          formControlName="username"
          [readonly]="!isNew && isReadOnly"
          required
        />
        <mat-error
          *ngIf="
            userForm.get('username').invalid && userForm.get('username').touched
          "
          >Please enter a valid email address.
        </mat-error>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <form [formGroup]="userGroups" *ngIf="type == 'provider'">
    <mat-card>
      <mat-card-title>Group</mat-card-title>
      <mat-card-content class="flex-container">
        <mat-form-field class="item-username">
          <mat-select placeholder="Provider User Group" formControlName="provider_user_groups" required>
            <mat-option
              *ngFor="let group of providerUserGroups"
              [value]="group.value"
              [disabled]="!isNew && !roles.includes('provider_admin')">{{ group.description }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
            userGroups.get('provider_user_groups').invalid && userGroups.get('provider_user_groups').touched
          "
          >Please select a user group.
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </form>

  <mat-card *ngIf="!isNew">
    <mat-card-title> Name Information </mat-card-title>
    <mat-card-content class="flex-container">
      <mat-form-field class="item">
        <input
          matInput
          placeholder="First Name"
          formControlName="first_name"
          [readonly]="isReadOnly"
        />
      </mat-form-field>
      <mat-form-field class="item">
        <input
          matInput
          placeholder="Last Name"
          formControlName="last_name"
          [readonly]="isReadOnly"
        />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <!-- Phone Numbers -->
  <form [formGroup]="phoneNumbers" *ngIf="!isNew">
    <mat-card>
      <mat-card-title>Phone Numbers</mat-card-title>
      <mat-card-content>
        <div formArrayName="phoneNumbers">
          <div
            *ngFor="let item of phoneNumbersForm.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="flex-container-nowrap">
              <a
                *ngIf="!isReadOnly"
                mat-icon-button
                class="icon-button"
                color="warn"
                (click)="onDeletePhoneNumberItem(i)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </a>
              <div class="phoneNumbers">
                <app-phone-form
                  [isReadOnly]="isReadOnly"
                  [phoneNumbersForm]="item"
                  [phoneTypes]="phoneTypes"
                ></app-phone-form>
              </div>
            </div>
          </div>
        </div>
        <a
          *ngIf="!isReadOnly"
          mat-icon-button
          class="icon-button"
          color="primary"
          (click)="onAddPhoneNumberItem()"
        >
          <mat-icon>add_circle_outline</mat-icon>
        </a>
      </mat-card-content>
    </mat-card>
  </form>
  <button
    *ngIf="!isReadOnly && !isNew"
    mat-stroked-button
    type="button"
    color="primary"
    (click)="onSave()"
    routerLink="/users"
    [disabled]="!userForm.valid"
  >
    SAVE
  </button>
  <button
    *ngIf="!isReadOnly && isNew"
    mat-stroked-button
    type="button"
    color="primary"
    (click)="onCreateUser()"
    [disabled]="!userForm.valid"
  >
    CREATE
  </button>
  <button
    *ngIf="!isNew && type == 'provider' && roles.includes('provider_admin')"
    mat-stroked-button
    type="button"
    color="primary"
    (click)="onUpdateUserRole()"
    [disabled]="!userForm.valid"
  >
    UPDATE
  </button>
  <button
    *ngIf="!isReadOnly"
    mat-stroked-button
    type="button"
    (click)="cancel.emit()"
  >
    CANCEL
  </button>
  <button
    *ngIf="isReadOnly"
    mat-stroked-button
    type="button"
    (click)="cancel.emit()"
  >
    BACK
  </button>
</form>

<!-- <pre>{{ userForm.value | json }}</pre> -->
<!-- <pre>{{ user | json }}</pre> -->
