import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

export const linkUserEmailPreference = gql`
  mutation LinkUserEmailPreference($body: jsonb) {
    link_user_preference(args: {body: $body, type: "ClientStatusUpdate"}) {
      preference {
        id
        body
        type
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EmailSettingsService {
  constructor(private apollo: Apollo) {
  }

  linkUserEmailPreference(body): Observable<any> {
    const mutation = {
      body
    };
    return this.apollo
      .mutate({
        mutation: linkUserEmailPreference,
        variables: mutation,
      })
      .pipe(pluck('data'));
  }
}
