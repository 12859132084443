<mat-vertical-stepper #stepper *ngIf="theme">
  <mat-step [stepControl]="typeFormGroup">
    <form [formGroup]="typeFormGroup">
      <ng-template matStepLabel>Choose transaction type</ng-template>
      <div class="form-group">
        <mat-form-field class="item">
          <mat-label>Details</mat-label>
          <input
            matInput
            formControlName="location"
            placeholder="Details"
          />
        </mat-form-field>
        <mat-form-field class="item">
          <mat-select
            placeholder="Bill Type"
            formControlName="type"
            (selectionChange)="setTypeName($event)"
            required
          >
            <mat-option
              *ngFor="let txType of transactionTypes"
              [value]="txType.id.toString()"
              >{{ txType.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <button mat-button color="primary" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="detailsFormGroup">
    <form [formGroup]="detailsFormGroup">
      <ng-template matStepLabel>Fill out transaction details</ng-template>
      <div class="form-group">
        <mat-form-field class="item">
          <input
            matInput
            placeholder="Description"
            formControlName="description"
          />
        </mat-form-field>
        <mat-form-field class="item-date">
          <input
            matInput
            formControlName="date_of_service"
            [matDatepicker]="dateOfService"
            placeholder="Date of Service"
            [readonly]="false"
            dateMask
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dateOfService"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfService></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="item-date">
          <input
            matInput
            formControlName="date_posted"
            [matDatepicker]="datePosted"
            placeholder="Date Posted"
            [readonly]="false"
            dateMask
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datePosted"
          ></mat-datepicker-toggle>
          <mat-datepicker #datePosted></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="item-120px">
          <input matInput placeholder="Bill" formControlName="bill" />
          <span matSuffix>$ &nbsp;</span>
          <mat-error
            *ngIf="
              detailsFormGroup.get('bill').invalid &&
              !detailsFormGroup.get('bill').pristine &&
              detailsFormGroup.get('bill').touched
            "
            >Please enter an amount.</mat-error
          >
        </mat-form-field>
      </div>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button color="primary" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="billFormGroup">
    <form [formGroup]="billFormGroup">
      <ng-template matStepLabel>Select Bill Document</ng-template>
      <mat-form-field *ngIf="bill" class="item-filename">
        <span matPrefix><mat-icon>picture_as_pdf</mat-icon></span>
        <span matSuffix
          ><mat-icon class="clear-button" (click)="clearBillFile()"
            >clear</mat-icon
          ></span
        >
        <input
          matInput
          placeholder="File Name"
          [value]="getBillFileName()"
          readonly
        />
      </mat-form-field>

      <section *ngIf="!bill" class="mat-typography margin-top">
        <div class="dropzone">
          <app-drop-zone
            dropZoneTextSize="100%"
            [allowMultipleFiles]="false"
            [allowedFileTypes]="['pdf']"
            [dragEnterColor]="theme.accent"
            [dragEnterTextColor]="theme.primaryText"
            [dragLeaveColor]="theme.primary"
            [dragLeaveTextColor]="theme.accentText"
            (validFiles)="billFilesValid($event)"
            (invalidFiles)="filesInvalid($event)"
          >
          </app-drop-zone>
        </div>
      </section>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button color="primary" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="noteFormGroup">
    <form [formGroup]="noteFormGroup">
      <ng-template matStepLabel>Select Note Document</ng-template>
      <mat-form-field *ngIf="note" class="item-filename">
        <span matPrefix><mat-icon>picture_as_pdf</mat-icon></span>
        <span matSuffix
          ><mat-icon class="clear-button" (click)="clearNoteFile()"
            >clear</mat-icon
          ></span
        >
        <input
          matInput
          placeholder="File Name"
          [value]="getNoteFileName()"
          readonly
        />
      </mat-form-field>

      <section *ngIf="!note" class="mat-typography margin-top">
        <div class="dropzone">
          <app-drop-zone
            dropZoneTextSize="100%"
            [allowMultipleFiles]="false"
            [allowedFileTypes]="['pdf']"
            [dragEnterColor]="theme.accent"
            [dragEnterTextColor]="theme.primaryText"
            [dragLeaveColor]="theme.primary"
            [dragLeaveTextColor]="theme.accentText"
            (validFiles)="noteFilesValid($event)"
            (invalidFiles)="filesInvalid($event)"
          >
          </app-drop-zone>
        </div>
      </section>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button color="primary" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <p>Add a Transaction.</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button color="warn" (click)="stepper.reset()">Reset</button>
      <button
        mat-button
        color="primary"
        matStepperPrevious
        (click)="done()"
        [disabled]="
          typeFormGroup.invalid ||
          detailsFormGroup.invalid ||
          billFormGroup.invalid ||
          noteFormGroup.invalid
        "
      >
        Add
      </button>
    </div>
  </mat-step>
</mat-vertical-stepper>
<button mat-stroked-button type="button" (click)="cancel.emit()">CANCEL</button>
