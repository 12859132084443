import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface UpdateProfileNag {
  title: string;
  body: string;
}

@Component({
  selector: 'app-update-profile-nag',
  template: `
    <h3  mat-dialog-title><strong>{{ data.title }}</strong></h3>
    <mat-dialog-content>
      {{ data.body }}
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button type="button" (click)="cancel()">
        Remind me later
      </button>
      <button mat-button type="button" (click)="ok()">
        Take me to my profile
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        max-width: 400px;
        white-space: pre-wrap;
      }
      mat-dialog-actions {
        display: flex;
        justify-content: flex-end;
      }
      [mat-button] {
        margin: 2px;
      }
    `,
  ],
})
export class UpdateProfileNagComponent {
  constructor(
    private ref: MatDialogRef<UpdateProfileNagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateProfileNag
  ) {}

  cancel() {
    this.ref.close(false);
  }

  ok() {
    this.ref.close(true);
  }
}
