import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthFacade } from '../../+state';
import { RouterFacade } from '../../../router/+state';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-magic-login',
  template: ``,
})
export class MagicLoginComponent {
  myForm: FormGroup;

  constructor(public auth: AuthFacade, private router: RouterFacade) {
    this.router.queryParams$.pipe(first()).subscribe((params) => {
      if (params && params.t) {
        this.auth.magicAuthenticate(params.t);
      } else {
        this.router.go({ path: ['/login'] });
      }
    });
  }
}
