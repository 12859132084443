import { createAction, props } from '@ngrx/store';

export const caseUpdateAlerts = createAction(
  '[Email Settings] Case Update Alerts',
  props<{ input: any }>()
);

export const caseUpdateAlertsSuccess = createAction(
  '[Email Settings] Case Update Alerts Success',
  props<{ mutation: any }>()
);

export const caseUpdateAlertsError = createAction(
  '[Email Settings] Case Update Alerts Error',
  props<{ error: any }>()
);


