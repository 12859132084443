import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CoreFacade } from '@core/+state';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Screen } from '@core/models';
import { MatSelectChange } from '@angular/material/select';
import { ConfigurationService } from '@configuration/services/configuration.service';
import {convertUTCStringToLocalDate} from '@core/utils';

@Component({
  selector: 'app-transaction-bill-details',
  templateUrl: './transaction-bill-details.component.html',
  styleUrls: ['./transaction-bill-details.component.scss'],
})
export class TransactionBillDetailsComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() screen: Screen;
  @Input() isDetails: boolean;
  @Input() isEdit: boolean;
  @Input() isNew: boolean;
  @Input() downloadTransaction: boolean;
  @Input() createTransaction: boolean;
  @Input() deleteTransaction: boolean;
  @Input() transaction: any;
  @Input() transactionDocuments: any;
  @Input() clientId: string;
  @Input() transactionTypes: any;
  @Input() displayedColumns: string[];

  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() downloadDocument: EventEmitter<any> = new EventEmitter();
  @Output() streamDocument: EventEmitter<any> = new EventEmitter();
  @Output() addDocument: EventEmitter<any> = new EventEmitter();
  @Output() deleteDocument: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  transactionForm: FormGroup;
  billDocumentExists = false;
  noteDocumentExists = false;

  dataSource = new MatTableDataSource<any>();

  selectedDocument = null;

  regNumber = '^[0-9]+(\.[0-9]{1,2})?$';

  constructor(
    private fb: FormBuilder,
    private configurationService: ConfigurationService,
    public core: CoreFacade
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Populate transaction form on the first instance of changes.transaction
    if (
      changes.transaction &&
      !changes.transaction.previousValue &&
      this.transactionForm
    ) {
      // tslint:disable-next-line:variable-name
      const _transaction = this.transformTransaction(this.transaction);
      this.transactionForm.patchValue(_transaction);
      // this.transactionForm.patchValue({ ...this.transaction, });
    }

    // Populate transaction documents only when it changes
    if (changes.transactionDocuments && this.transactionDocuments) {
      this.dataSource.data = this.transactionDocuments;

      // Track if the Bill Docs already exist.
      this.billDocumentExists =
        this.transactionDocuments &&
        this.transactionDocuments.length > 0 &&
        this.transactionDocuments.find(
          (d: any) => d.transactionType === 'transactionBill'
        );

      this.noteDocumentExists =
        this.transactionDocuments &&
        this.transactionDocuments.length > 0 &&
        this.transactionDocuments.find(
          (d: any) => d.transactionType === 'transactionNote'
        );
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (row, property) => {
      switch (property) {
        case 'displayName': {
          return row.display_name;
        }
        case 'docType': {
          return this.getDocumentTypeDisplay(row);
        }
        case 'fileSize': {
          return row.file_size;
        }
        default:
          return row[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  createForm() {
    this.transactionForm = this.fb.group({
      id: '',
      bill: [0, Validators.pattern(this.regNumber)],
      date_of_service: '',
      date_posted: '',
      description: '',
      location: '',
      // timestamp: [new Date().toISOString(), Validators.required],
      updated_at: [new Date().toISOString(), Validators.required],
      type: ['', Validators.required],
      type_name: '',
    });
  }

  // Get the clientId from the current transaction but if it
  // doesn't exist return the @Input() clientId
  getClientId() {
    const id =
      this.transaction &&
      this.transaction.client_transaction_bills &&
      this.transaction.client_transaction_bills.length === 1
        ? this.transaction.client_transaction_bills[0].client_id
        : null;
    return id ? id : this.clientId;
  }

  get transactionId() {
    return this.transactionForm.get('id').value;
  }

  onDateOfService(val: Date) {
    this.transactionForm.get('date_of_service').setValue(val);
  }

  onDatePosted(val: Date) {
    this.transactionForm.get('date_posted').setValue(val);
  }

  getDownloadCount(doc: any): number {
    return doc &&
      doc.download_histories_aggregate &&
      doc.download_histories_aggregate.aggregate.count
      ? doc.download_histories_aggregate.aggregate.count
      : null;
  }

  getDownloadCountToolTip(doc: any): string {
    let rc = null;
    const count = this.getDownloadCount(doc);
    if (count) {
      rc = `Downloaded ${count} ${count > 1 ? 'times' : 'time'}`;
    }
    return rc;
  }

  documentIcon(doc: any): string {
    let icon = 'insert_drive_file';
    if (typeof doc.file_type !== 'undefined') {
      if (doc.file_type.includes('pdf')) {
        icon = 'picture_as_pdf';
      } else if (doc.file_type.includes('image')) {
        icon = 'photo';
      }
    }
    return icon;
  }

  getDocumentTypeDisplay(row: any): string {
    let found = null;
    if (this.transactionTypes) {
      found = this.transactionTypes.find(
        (t) => t.id.toString() === row.type.toString()
      );
    }
    return found ? found.name : '';
  }

  getTotalSize() {
    return (
      this.transactionDocuments &&
      this.transactionDocuments.length > 0 &&
      this.transactionDocuments
        .map((row) => row.file_size)
        .filter((amount) => !isNaN(amount))
        .reduce((acc, value) => acc + value, 0)
    );
  }

  onAddDocument(type: string) {
    if (this.getClientId() && this.transactionId) {
      this.addDocument.emit({
        clientId: this.getClientId(),
        transactionId: this.transactionId,
        transactionType: type,
      });
    }
  }

  /**
   * Keep the type and type_name fields in sync
   *
   * @param changedValue: the id of the type
   */
  setTypeName(changedValue: MatSelectChange) {
    this.transactionForm
      .get('type_name')
      .setValue(
        this.configurationService.getTypeName(
          changedValue.value,
          this.transactionTypes
        )
      );
  }

  // transform transaction object
  transformTransaction(transaction) {
    // tslint:disable-next-line:variable-name
    const _transaction = {...transaction};
    _transaction.date_posted = convertUTCStringToLocalDate(transaction.date_posted, '-');
    _transaction.date_of_service = convertUTCStringToLocalDate(transaction.date_of_service, '-');
    return _transaction;
  }
}
