import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { PhoneNumbersEffects } from './+state/phone-numbers.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([PhoneNumbersEffects])],
})
export class PhoneNumbersModule {}
