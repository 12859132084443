import { Pipe, PipeTransform } from '@angular/core';
import { formatFileSize } from '../../utils/file-format';
@Pipe({
  name: 'fileSize',
})
export class FormatFileSizePipe implements PipeTransform {
  transform(sizeInBytes: number, longForm?): string {
    return formatFileSize(sizeInBytes, longForm);
  }
}
