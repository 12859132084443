import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './profile.actions';

export interface State {
  profile: any;
}

export const initialState: State = {
  profile: null,
};

const profileReducer = createReducer(
  initialState,
  on(fromActions.loadProfileSuccess, (state, { user }) => {
    return {
      ...state,
      profile: user,
    };
  }),
  on(fromActions.loadProfileError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(fromActions.clearState, (state) => ({
    ...state,
    profile: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return profileReducer(state, action);
}
