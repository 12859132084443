import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-organizations-popup',
  templateUrl: './organizations-popup.component.html',
  styleUrls: ['./organizations-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsPopupComponent implements OnInit {
  @Input() selectedItem: any;
  @Input() options: any;

  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();

  myControl = new FormControl();
  filteredOptions: Observable<any>;

  ngOnInit() {
    this.myControl.setValue(this.selectedItem.name);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): any {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  onSelectionChanged(option: any) {
    this.myControl.setValue(option.name);
    this.selectionChanged.emit(option);
  }
}
