<div *ngIf="screen">
  <div class="header">
    <mat-form-field>
      <mat-icon matSuffix>search</mat-icon
      ><input
        matInput
        [formControl]="searchControl"
        (keyup)="applyFilter($event.target.value)"
        placeholder="Organization Name"
        aria-label="Search"
        autocomplete="off"
      />
    </mat-form-field>
  </div>
  <mat-card>
    <div class="organizations-header">
      <button
        *ngIf="createOrganization"
        mat-mini-fab
        aria-label="Add Organization"
        (click)="create.emit()"
        matTooltip="Add Organization"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <mat-table
      [dataSource]="dataSource"
      matSort
      matSortDisableClear
      [matSortActive]="organizationsListTableSort.active"
      [matSortDirection]="organizationsListTableSort.direction"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [ngClass]="{ 'hide-column': screen.device === 'handset' }"
        >
          Work Phone Numbers
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [ngClass]="{ 'hide-column': screen.device === 'handset' }"
        >
          {{ row.phone_numbers | phoneNumbers }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell
          *matHeaderCellDef
          [ngStyle]="{
            'min-width.px':
              ((editOrganization ? 1 : 0) +
                (detailsOrganization ? 1 : 0) +
                (deleteOrganization ? 1 : 0)) *
              40
          }"
        >
          Actions
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [ngStyle]="{
            'min-width.px':
              ((editOrganization ? 1 : 0) +
                (detailsOrganization ? 1 : 0)) *
              40
          }"
        >
<!--          + (deleteOrganization ? 1 : 0)-->
          <button
            *ngIf="detailsOrganization"
            mat-icon-button
            color="primary"
            (click)="details.emit(row)"
            matTooltip="Details"
            [matTooltipShowDelay]="750"
          >
            <mat-icon>details</mat-icon>
          </button>
          <button
            *ngIf="editOrganization"
            mat-icon-button
            color="primary"
            (click)="edit.emit(row)"
            matTooltip="Edit"
            [matTooltipShowDelay]="750"
          >
            <mat-icon>edit</mat-icon>
          </button>
<!--          <button-->
<!--            *ngIf="deleteOrganization"-->
<!--            [disabled]="-->
<!--              row.phone_numbers_aggregate.aggregate.count > 0 ||-->
<!--              row.addresses_aggregate.aggregate.count > 0 ||-->
<!--              row.shared_clients_aggregate.aggregate.count > 0-->
<!--                ? true-->
<!--                : false-->
<!--            "-->
<!--            mat-icon-button-->
<!--            color="warn"-->
<!--            (click)="delete.emit(row)"-->
<!--            matTooltip="Delete"-->
<!--            [matTooltipShowDelay]="750"-->
<!--          >-->
<!--            <mat-icon>delete</mat-icon>-->
<!--          </button>-->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
          selected: selectedOrganization && selectedOrganization.id === row.id
        }"
        (click)="selectItem.emit(row)"
        matRipple
      >
      </mat-row>
    </mat-table>
    <mat-paginator
      [showFirstLastButtons]="true"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      [pageSize]="pageSize"
    ></mat-paginator>
  </mat-card>
</div>
