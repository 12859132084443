<div class="header">
  <mat-form-field>
    <mat-icon matSuffix>search</mat-icon
    ><input
      matInput
      (keyup)="applyFilter($event.target.value)"
      placeholder="Name"
      aria-label="Search"
      autocomplete="off"
    />
  </mat-form-field>
</div>

<ng-container *ngIf="screen">
  <mat-table
    [dataSource]="dataSource"
    matSort
    matSortDisableClear
    [matSortActive]="usersListTableSort.active"
    [matSortDirection]="usersListTableSort.direction"
  >
    <ng-container matColumnDef="enabled">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Active
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.preventDefault()"
          [checked]="row.enabled"
          matTooltip="Enabled"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Email
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.username }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        First Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        {{ row.first_name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Last Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        {{ row.last_name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.last_name }}, {{ row.first_name }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Phone
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        {{ row.phoneNumber | phoneDisplay }}
      </mat-cell>
    </ng-container>

    <!-- Provider -->
    <ng-container matColumnDef="provider">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Provider
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.provider_users | organizationNames }}
      </mat-cell>
    </ng-container>

    <!-- Organizations -->
    <ng-container matColumnDef="organizations">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Organization
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.organization_users | organizationNames }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tier">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Tier
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        {{ row.tier.length > 0 ? row.tier[0].group.description : 'Unknown' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="permissions">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Permissions
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait,
          overridden: row.user_configurations_aggregate.aggregate.count === 1
        }"
      >
        {{
          row.user_configurations_aggregate.aggregate.count > 0
            ? 'Overridden'
            : 'Default'
        }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastLogin">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Last Login
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        {{ row.last_login | date: 'MM/dd/yyyy hh:mm aaa' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell
        *matHeaderCellDef
        [ngStyle]="{
          'min-width.px':
            ((editUser ? 1 : 0) +
              (detailsUser ? 1 : 0) +
              (contactUser ? 1 : 0)) *
            40
        }"
      >
        Actions
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngStyle]="{
          'min-width.px':
            ((editUser ? 1 : 0) +
              (detailsUser ? 1 : 0) +
              (contactUser ? 1 : 0)) *
            40
        }"
      >
        <a
          *ngIf="detailsUser"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="User Details"
          [matTooltipShowDelay]="750"
          (click)="details.emit(row)"
        >
          <mat-icon>details</mat-icon>
        </a>
        <a
          *ngIf="editUser"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="Edit"
          [matTooltipShowDelay]="750"
          (click)="edit.emit(row)"
        >
          <mat-icon>edit</mat-icon>
        </a>
        <a
          *ngIf="contactUser"
          type="button"
          mat-icon-button
          color="accent"
          matTooltip="Contact"
          [matTooltipShowDelay]="750"
          (click)="contact.emit(row)"
        >
          <mat-icon>email</mat-icon>
        </a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ selected: selectedUser && selectedUser.id === row.id }"
      (click)="selectItem.emit(row)"
      matRipple
    >
    </mat-row>
  </mat-table>
  <mat-paginator
    [showFirstLastButtons]="true"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [pageSize]="pageSize"
  ></mat-paginator>
</ng-container>
