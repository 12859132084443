<form class="form">
  <mat-form-field class="full-width">
    <input
      type="text"
      placeholder="Display Name"
      aria-label="Case Contact"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
      (focus)="$event.target.select()"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="selectedItem.name"
        (onSelectionChange)="onSelectionChanged(option)"
      >
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
