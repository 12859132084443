<h1 class="mat-h1">Organization Information</h1>
<div class="form-container">
  <form [formGroup]="organizationForm">
    <mat-card>
      <mat-card-title> Name Information </mat-card-title>
      <mat-card-content class="flex-container">
        <mat-form-field class="item">
          <input
            matInput
            placeholder="Organization Name"
            formControlName="name"
            [readonly]="isReadOnly"
            required
          />
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </form>

  <!-- Addresses -->
  <form [formGroup]="addresses">
    <mat-card>
      <mat-card-title>Addresses</mat-card-title>
      <mat-card-content>
        <div formArrayName="addresses">
          <div
            *ngFor="let item of addressesForm.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="flex-container-nowrap">
              <a
                *ngIf="!isReadOnly"
                mat-icon-button
                class="icon-button"
                color="warn"
                (click)="onDeleteAddressItem(i)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </a>
              <div class="addresses">
                <app-address-form
                  [isReadOnly]="isReadOnly"
                  [addressForm]="item"
                  [states]="states"
                ></app-address-form>
              </div>
            </div>
          </div>
          <a
            *ngIf="!isReadOnly"
            mat-icon-button
            class="icon-button"
            color="primary"
            (click)="onAddAddressItem()"
          >
            <mat-icon>add_circle_outline</mat-icon>
          </a>
        </div>
      </mat-card-content>
    </mat-card>
  </form>

  <!-- Phone Numbers -->
  <form [formGroup]="phoneNumbers">
    <mat-card>
      <mat-card-title>Phone Numbers</mat-card-title>
      <mat-card-content>
        <div formArrayName="phoneNumbers">
          <div
            *ngFor="let item of phoneNumbersForm.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="flex-container-nowrap">
              <a
                *ngIf="!isReadOnly"
                mat-icon-button
                class="icon-button"
                color="warn"
                (click)="onDeletePhoneNumberItem(i)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </a>
              <div class="phoneNumbers">
                <app-phone-form
                  [isReadOnly]="isReadOnly"
                  [phoneNumbersForm]="item"
                  [phoneTypes]="phoneTypes"
                ></app-phone-form>
              </div>
            </div>
          </div>
        </div>
        <a
          *ngIf="!isReadOnly"
          mat-icon-button
          class="icon-button"
          color="primary"
          (click)="onAddPhoneNumberItem()"
        >
          <mat-icon>add_circle_outline</mat-icon>
        </a>
      </mat-card-content>
    </mat-card>
  </form>
</div>

<button
  *ngIf="!isReadOnly && !isNew"
  mat-stroked-button
  type="button"
  color="primary"
  (click)="onSave()"
  routerLink="/organizations"
  [disabled]="
    !organizationForm.valid || !addressesForm.valid || !phoneNumbersForm.valid
  "
>
  SAVE
</button>
<button
  *ngIf="!isReadOnly && isNew"
  mat-stroked-button
  type="button"
  color="primary"
  (click)="onCreate()"
  [disabled]="
    !organizationForm.valid || !addressesForm.valid || !phoneNumbersForm.valid
  "
>
  CREATE
</button>
<button
  *ngIf="!isReadOnly"
  mat-stroked-button
  type="button"
  (click)="cancel.emit()"
>
  CANCEL
</button>
<button
  *ngIf="isReadOnly"
  mat-stroked-button
  type="button"
  (click)="cancel.emit()"
>
  BACK
</button>

<button class="delete"
  *ngIf="!isReadOnly && !isNew"
  type="button"
  
  mat-stroked-button
  color="warn"
  (click)="onDelete()"
>
  <mat-icon>delete</mat-icon>
  DELETE
</button>

<!-- <pre>{{ organization | json }}</pre> -->

<!-- <pre>Valid:{{ organizationForm.valid }} Dirty:{{ organizationForm.dirty }} <br />{{ organizationForm.value | json }}</pre> -->

<!-- <pre>Valid:{{ addressesForm.valid}} Dirty:{{ addressesForm.dirty }} <br>{{ addresses.value | json }}</pre> -->

<!-- <pre>Valid: {{ phoneNumbersForm.valid }} Dirty: {{ phoneNumbersForm.dirty }} <br />{{ phoneNumbers.value | json }}</pre> -->
