import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import { CoreFacade } from '@core/+state';
import { Theme } from '@core/models';

@Component({
  selector: 'app-theme-picker',
  templateUrl: './theme-picker.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ThemePickerContainerComponent {
  constructor(public core: CoreFacade) {}

  setTheme(theme: Theme) {
    this.core.setTheme(theme);
  }
}
