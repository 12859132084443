import gql from 'graphql-tag';

/**
 * Fragments
 */
export const CLIENT_FRAGMENT = gql`
  fragment clientFragment on clients {
    id
    first_name
    last_name
    middle_initial
    gender
    email
    date_of_birth
    date_of_injury
    client_balance {
      balance
    }
  }
`;
