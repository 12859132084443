import { createAction, props } from '@ngrx/store';

export const setAuthState = createAction(
  '[Auth] Set User Information',
  props<{ idToken: any }>()
);

export const getUserInformation = createAction(
  '[Auth] Get User Information',
  props<{ id: string }>()
);
export const getUserInformationSuccess = createAction(
  '[Auth] Get User Information Success',
  props<{ userInformation: any }>()
);

export const checkEulaSuccess = createAction('[Auth] Check EULA Success');

export const getUserInformationError = createAction(
  '[Auth] Get User Information Error',
  props<{ error: any }>()
);

export const login = createAction('[Auth] Login');
export const authenticate = createAction(
  '[Auth] Authenticate',
  props<{ username: string; password: string }>()
);
export const authenticateSuccess = createAction(
  '[Auth] Authenticate Success',
  props<{ token: string }>()
);
export const authenticateError = createAction(
  '[Auth] Authenticate Error',
  props<{ error: any }>()
);

export const magicAuthenticate = createAction(
  '[Auth] Magic Authenticate',
  props<{ token: string }>()
);

export const magicAuthenticateSuccess = createAction(
  '[Auth] Magic Authenticate Success',
  props<{ token: string }>()
);

export const magicAuthenticateError = createAction(
  '[Auth] Magic Authenticate Error',
  props<{ error: any }>()
);

export const sendMagicLink = createAction(
  '[Auth] Send Magic Link',
  props<{ username: string }>()
);
export const sendMagicLinkSuccess = createAction(
  '[Auth] Send Magic Link Success',
  props<{ username: string }>()
);
export const sendMagicLinkError = createAction(
  '[Auth] Send Magic Link Error',
  props<{ error: any }>()
);

export const firebaseSignInSuccess = createAction(
  '[Auth] Firebase Sign In Success'
);
export const firebaseSignInError = createAction(
  '[Auth] Firebase Sign In Error',
  props<{ error: any }>()
);

export const requestPasswordReset = createAction(
  '[Auth] Request Password Reset',
  props<{ username: string }>()
);
export const requestPasswordResetSuccess = createAction(
  '[Auth] Request Password Reset Success',
  props<{ data: any }>()
);
export const requestPasswordResetError = createAction(
  '[Auth] Request Password Reset Error',
  props<{ error: any }>()
);

export const passwordReset = createAction(
  '[Auth] Password Reset',
  props<{ body: any }>()
);
export const passwordResetSuccess = createAction(
  '[Auth] Password Reset Success',
  props<{ data: any }>()
);
export const passwordResetError = createAction(
  '[Auth] Password Reset Error',
  props<{ error: any }>()
);

export const changePassword = createAction(
  '[Auth] Change Password',
  props<{ body: any }>()
);
export const changePasswordSuccess = createAction(
  '[Auth] Change Password Success'
);
export const changePasswordError = createAction(
  '[Auth] Change Password Error',
  props<{ error: any }>()
);

export const logout = createAction('[Auth] Logout');
export const logoutConfirmed = createAction('[Auth] Logout Confirmed');
export const logoutCancelled = createAction('[Auth] Logout Cancelled');
export const logoutSuccess = createAction('[Auth] Logout Success');
export const logoutError = createAction(
  '[Auth] Logout Error',
  props<{ error: any }>()
);

export const updateLastSeen = createAction(
  '[Auth] Update Last Seen',
  props<{ id: any }>()
);
export const updateLastSeenSuccess = createAction(
  '[Auth] Update Last Seen Success'
);
export const updateLastSeenError = createAction(
  '[Auth] Update Last Seen Error',
  props<{ error: any }>()
);

export const providerUser = createAction(
    '[Auth] Provider User',
    props<{ id: any }>()
);
export const providerUserSuccess = createAction(
    '[Auth] Provider User Success',
    props<{ providers: any }>()
);
export const providerUserError = createAction(
    '[Auth] Provider User Error',
    props<{ error: any }>()
);
export const setProvider = createAction(
    '[Auth] Set Provider',
    props<{ provider: any }>()
);

export const clearState = createAction('[Auth] Clear State');
