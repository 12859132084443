import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'dateValidator'
})
export class DateValidatorPipe extends DatePipe implements PipeTransform {

    transform(value: any, format: string = 'MMM dd, yyyy'): any {
        const date = this.dateValidator(value);
        return super.transform(date, format);
    }

    dateValidator(value) {
        const date = new Date(value);
        if (date.toString() !== 'Invalid Date' && value !== '0001-01-01') {
            return value;
        } else {
            return null;
        }
    }

}
