import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './core.reducer';
import { selectConfigurationByType } from '../../configuration/+state';

export const selectLayoutState = createFeatureSelector<fromReducer.State>(
  'core'
);
export const selectNavElements = createSelector(
  selectConfigurationByType('NavigationLinks'),
  (configuration) => configuration && configuration.body
);

export const selectThemeName = createSelector(
  selectLayoutState,
  (state) => state.themeName
);

export const selectIsThemeDark = createSelector(
  selectLayoutState,
  (state) => state.isDark
);

export const selectLogoLoaded = createSelector(
  selectLayoutState,
  (state) => state.logoLoaded
);

export const selectSearchOrganization = createSelector(
  selectLayoutState,
  (state) => state.searchOrganization
);

export const selectThemes = createSelector(
  selectConfigurationByType('Themes'),
  (configuration) => configuration && configuration.body
);

export const selectTheme = createSelector(
  selectThemeName,
  selectThemes,
  (themeName, themes) =>
    themeName && themes && themes.find((theme) => theme.name === themeName)
);

export const selectProfileUpdateReminder = createSelector(
  selectLayoutState,
  (state) => state.profileUpdateReminder
);
