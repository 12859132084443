<h1 class="mat-h1">Document Information</h1>
<div class="form-container">
  <form [formGroup]="documentForm">
    <mat-card>
      <mat-card-title> File Information </mat-card-title>
      <mat-card-content class="flex-container">
        <mat-form-field class="item">
          <input
            matInput
            placeholder="Document Name"
            formControlName="display_name"
            [readonly]="isDetails"
            required
          />
        </mat-form-field>
        <mat-form-field class="item" *ngIf="isEdit">
          <mat-select
            placeholder="Document Type"
            formControlName="type"
            (selectionChange)="setTypeName($event)"
            required
          >
            <mat-option *ngFor="let docType of docTypes" [value]="docType.id">{{
              docType.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <a
          *ngIf="downloadFile"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="Preview"
          (click)="stream.emit(documentForm.value)"
        >
          <mat-icon>open_in_new</mat-icon>
        </a>
        <a
          *ngIf="downloadFile"
          type="button"
          mat-icon-button
          color="accent"
          matTooltip="Download"
          (click)="download.emit(documentForm.value)"
        >
          <mat-icon>cloud_download</mat-icon>
        </a>
        <a
          *ngIf="deleteDocument && isEdit"
          type="button"
          mat-icon-button
          color="warn"
          (click)="delete.emit(document)"
          matTooltip="Delete"
          ><mat-icon>delete</mat-icon>
        </a>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

<button
  *ngIf="isEdit"
  mat-stroked-button
  type="button"
  color="primary"
  (click)="update.emit(documentForm.value)"
  routerLink="/clients"
  [disabled]="!documentForm.valid"
>
  SAVE
</button>
<button
  *ngIf="isDetails"
  mat-stroked-button
  type="button"
  (click)="cancel.emit()"
>
  BACK
</button>

<!-- <pre>isDetails: {{ isDetails }}</pre> -->
<!-- <pre>isEdit: {{ isEdit }}</pre> -->
<!-- <pre>ClientId: {{ clientId }}</pre> -->
<!-- <pre>Document: {{ document | json }}</pre> -->
<!-- <pre>Form: {{ documentForm.value | json }}</pre> -->
