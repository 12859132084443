import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {FeedbackComponent} from '@core/containers';

@Component({
  selector: 'app-init-login-prompt',
  templateUrl: './init-login-prompt.component.html',
  styleUrls: ['./init-login-prompt.component.scss']
})
export class InitLoginPromptComponent implements OnInit {

  form: FormGroup;
  maxLength = 1000;

  constructor(
    public dialogRef: MatDialogRef<FeedbackComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phoneNumber: [''],
    });
  }

  get user() {
    return {
      id: '',
      first_name: this.form.get('first_name').value,
      last_name: this.form.get('last_name').value
    };
  }

  get phoneNumber() {
    const phoneNumber =
      this.form.get('phoneNumber').value ?
        {
          id: '',
          number: this.form.get('phoneNumber').value,
          type: 'work'
        } : null;
    return {
      user_id: '',
      phoneNumber
    };
  }

  cancel() {
    this.dialogRef.close(false);
  }

  send() {
    const info = {user: this.user, phoneNumber: this.phoneNumber};
    this.dialogRef.close(info);
  }

}
