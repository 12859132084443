import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-client-case-contact',
  templateUrl: './client-case-contact.component.html',
  styleUrls: ['./client-case-contact.component.scss']
})
export class ClientCaseContactComponent implements OnInit {

  @Input() selectedItem: any;
  @Input() options: any;

  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();

  myControl = new FormControl();
  filteredOptions: Observable<any>;

  ngOnInit() {
    this.myControl.setValue(this.selectedItem.name);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): any {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  onSelectionChanged(option: any) {
    this.myControl.setValue(option.name);
    this.selectionChanged.emit(option);
  }

}
