import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, tap, filter } from 'rxjs/operators';
import { AuthFacade } from '@auth/+state';
import { CoreFacade } from '@core/+state';
import { SnackBarInfo } from '@core/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(
    private http: HttpClient,
    private auth: AuthFacade,
    private core: CoreFacade
  ) {}

  emergency(message: string, additional?: any): void {
    this.showSnackbarError(message);

    this.auth.usernameUID$.pipe(first()).subscribe((user) => {
      this.core.log(
        this.createLogBody(
          user,
          message,
          'EMERGENCY',
          this.getLabels(additional),
          additional
        )
      );
    });
  }

  // This log event doesn't open a snackbar message.
  // Intended for things like user navigated to page-not-found.
  alert(message: string, additional?: any): void {
    this.auth.usernameUID$.pipe(first()).subscribe((user) => {
      this.core.log(
        this.createLogBody(
          user,
          message,
          'ALERT',
          this.getLabels(additional),
          additional
        )
      );
    });
  }

  critical(message: string, additional?: any): void {
    this.showSnackbarError(message);

    this.auth.usernameUID$.pipe(first()).subscribe((user) => {
      this.core.log(
        this.createLogBody(
          user,
          message,
          'CRITICAL',
          this.getLabels(additional),
          additional
        )
      );
    });
  }

  error(message: string, additional?: any): void {
    this.showSnackbarError(message);
    this.auth.usernameUID$.pipe(first()).subscribe((user) => {
      this.core.log(
        this.createLogBody(
          user,
          message,
          'ERROR',
          this.getLabels(additional),
          additional
        )
      );
    });
  }

  warning(message: string, additional?: any): void {
    this.auth.usernameUID$.pipe(first()).subscribe((user) => {
      this.core.log(
        this.createLogBody(
          user,
          message,
          'WARNING',
          this.getLabels(additional),
          additional
        )
      );
    });
  }

  notice(message: string, additional?: any): void {
    this.showSnackbar(message);
    this.auth.usernameUID$.pipe(first()).subscribe((user) => {
      this.core.log(
        this.createLogBody(
          user,
          message,
          'NOTICE',
          this.getLabels(additional),
          additional
        )
      );
    });
  }

  noticeWithUsername(
    username: string,
    message: string,
    additional?: any
  ): void {
    this.showSnackbar(message);
    const user = { username, uid: null };
    this.core.log(this.createLogBody(user, message, 'INFO', null, additional));
  }

  info(message: string, additional?: any): void {
    this.auth.usernameUID$.pipe(first()).subscribe((user) => {
      this.core.log(
        this.createLogBody(user, message, 'INFO', null, additional)
      );
    });
  }

  infoWithUsername(username: string, message: string, additional?: any): void {
    const user = { username, uid: null };
    this.core.log(this.createLogBody(user, message, 'INFO', null, additional));
  }

  debug(message: string, additional?: any): void {
    this.auth.usernameUID$.pipe(first()).subscribe((user) => {
      this.core.log(
        this.createLogBody(user, message, 'DEBUG', null, additional)
      );
    });
  }

  private createLogBody(
    user: any,
    message: string,
    severity: string,
    labels: string,
    additional?: any
  ): any {
    const payload = {
      logName: `sharescape/${environment.environment}`,
      payload: {
        username: user.username,
        user_id: user.uid,
        message,
        labels,
        agent: navigator.userAgent,
        additional: JSON.stringify(additional),
      },
      severity,
    };
    return payload;
  }

  private showSnackbar(message: string) {
    const snackBarInfo: SnackBarInfo = {
      message,
      duration: 5000,
      action: 'OK',
    };
    return this.core.showSnackbar(snackBarInfo);
  }

  private showSnackbarError(message: string) {
    const snackBarInfo: SnackBarInfo = {
      message,
      duration: 0,
      action: 'CLOSE',
    };
    return this.core.showSnackbar(snackBarInfo);
  }

  logToConsole(message) {
    switch (message.severity) {
      case 'DEBUG':
        console.log(
          `%c[${this.getDateString()}] %c${message.payload.username} (${
            message.payload.user_id
          }) ${message.payload.message}`,
          'color: gray',
          'color: gray'
        );
        break;
      case 'INFO':
        console.log(
          `%c[${this.getDateString()}] %c${message.payload.message}`,
          'color: gray',
          'color: white'
        );
        break;
      case 'NOTICE':
        console.log(
          `%c[${this.getDateString()}] %c${message.payload.message}`,
          'color: gray',
          'color: yellow'
        );
        break;
      default:
        console.error(`[${this.getDateString()}] ${message.payload.message}`);
        break;
    }
  }

  getDateString(): string {
    return `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
  }

  log(body: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v1/log`, body).pipe(
      filter(() => environment.logToConsole),
      tap(() => this.logToConsole(body))
    );
  }

  getLabels(additional: any): string {
    const id =
      additional && additional.error && additional.error.id
        ? additional.error.id
        : '<null>';
    const code =
      additional && additional.error && additional.error.code
        ? additional.error.code
        : '<null>';
    return JSON.stringify({ id, code });
  }
}
