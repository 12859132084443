<h1 class="mat-h1">Transaction Information</h1>
<div class="form-container">
  <form [formGroup]="transactionForm">
    <mat-card>
      <mat-card-title> Transaction Details </mat-card-title>
      <mat-card-content class="flex-container">
        <mat-form-field class="item">
          <input
            matInput
            placeholder="Location"
            formControlName="location"
            [readonly]="isDetails"
          />
        </mat-form-field>
        <mat-form-field *ngIf="!isDetails" class="item">
          <mat-select
            placeholder="Bill Type"
            formControlName="type"
            required
            (selectionChange)="setTypeName($event)"
          >
            <mat-option
              *ngFor="let txType of transactionTypes"
              [value]="txType.id.toString()"
              [disabled]="isDetails"
              >{{ txType.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isDetails" class="item">
          <input
            matInput
            placeholder="Bill Type"
            formControlName="type_name"
            [readonly]="isDetails"
          />
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <a
          *ngIf="deleteTransaction"
          type="button"
          mat-icon-button
          color="warn"
          matTooltip="Delete"
          (click)="delete.emit(transaction)"
        >
          <mat-icon>delete</mat-icon>
        </a></mat-card-actions
      >
    </mat-card>
    <mat-card>
      <mat-card-title>Financial Information </mat-card-title>
      <mat-card-content class="form-container">
        <mat-form-field class="item">
          <input
            matInput
            placeholder="Description"
            formControlName="description"
            [readonly]="isDetails"
          />
        </mat-form-field>
        <div class="flex-container">
          <mat-form-field class="item-date">
            <input
              matInput
              formControlName="date_of_service"
              [matDatepicker]="dateOfService"
              placeholder="Date of Service"
              [readonly]="isDetails"
              dateMask
            />
            <mat-datepicker-toggle
              *ngIf="!isDetails"
              matSuffix
              [for]="dateOfService"
            ></mat-datepicker-toggle>
            <mat-datepicker #dateOfService></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="item-date">
            <input
              matInput
              formControlName="date_posted"
              [matDatepicker]="datePosted"
              placeholder="Date Posted"
              [readonly]="isDetails"
              dateMask
            />
            <mat-datepicker-toggle
              *ngIf="!isDetails"
              matSuffix
              [for]="datePosted"
            ></mat-datepicker-toggle>
            <mat-datepicker #datePosted></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="item-amount">
            <input
              matInput
              placeholder="Bill"
              formControlName="bill"
              [readonly]="isDetails"
            />
            <span matSuffix>$ &nbsp;</span>
            <mat-error
              *ngIf="
                transactionForm.get('bill').invalid &&
                !transactionForm.get('bill').pristine &&
                transactionForm.get('bill').touched
              "
              >Please enter an amount.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title class="documents-header"
        >Documents
        <button
          *ngIf="
            (createTransaction && !transactionDocuments) ||
            (transactionDocuments && transactionDocuments.length < 2)
          "
          mat-mini-fab
          class="add-client-object"
          [matMenuTriggerFor]="menuTransaction"
          aria-label="Add Transaction"
        >
          <mat-icon>cloud_upload</mat-icon>
        </button>
      </mat-card-title>
      <mat-card-content>
        <mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="transactionType"
          matSortDirection="asc"
          matSortDisableClear
        >
          <!-- File Type Icon Column -->
          <ng-container matColumnDef="fileType">
            <mat-header-cell
              *matHeaderCellDef
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
            ></mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
            >
              <mat-icon
                [matBadge]="getDownloadCount(row)"
                [matTooltip]="getDownloadCountToolTip(row)"
                >{{ documentIcon(row) }}</mat-icon
              >
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
            ></mat-footer-cell>
          </ng-container>

          <!-- File Transaction Type -->
          <ng-container matColumnDef="transactionType">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Document Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row"
              >{{
                row.transactionType === 'transactionBill'
                  ? 'Bill Document'
                  : 'Note Document'
              }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- File Display Name Column -->
          <ng-container matColumnDef="displayName">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
              >File Name
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
              >{{ row.display_name }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
            ></mat-footer-cell>
          </ng-container>

          <!-- Document Type Column -->
          <ng-container matColumnDef="docType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Document Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ getDocumentTypeDisplay(row) }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
            ></mat-footer-cell>
          </ng-container>

          <!-- File Size Column -->
          <ng-container matColumnDef="fileSize">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
              >File Size</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
              >{{ row.file_size | fileSize }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
              >{{ getTotalSize() | fileSize }}</mat-footer-cell
            >
          </ng-container>

          <!-- Created At Column -->
          <ng-container matColumnDef="createdAt">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
              >Date</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
              >{{ row.created_at | date: 'MM/dd/yyyy hh:mm a' }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
              style="justify-content: flex-end"
              >Total:
            </mat-footer-cell>
          </ng-container>

          <!-- Updated At Column -->
          <ng-container matColumnDef="updatedAt">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              [ngClass]="{ 'hide-column': screen.device === 'handset' }"
              >Modified</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              [ngClass]="{ 'hide-column': screen.device === 'handset' }"
              >{{ row.updated_at | date: 'MM/dd/yyyy hh:mm a' }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
            ></mat-footer-cell>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell
              *matHeaderCellDef
              [ngStyle]="{
                'min-width.px': 120
              }"
            >
              Actions
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [ngStyle]="{
                'min-width.px': 120
              }"
            >
              <a
                *ngIf="downloadTransaction"
                type="button"
                mat-icon-button
                color="accent"
                matTooltip="Preview"
                (click)="streamDocument.emit(row)"
              >
                <mat-icon>
                  {{
                    row.transactionType === 'transactionBill'
                      ? 'monetization_on'
                      : 'description'
                  }}</mat-icon
                >
              </a>
              <a
                *ngIf="downloadTransaction"
                type="button"
                mat-icon-button
                color="primary"
                matTooltip="Download"
                (click)="downloadDocument.emit(row)"
              >
                <mat-icon>cloud_download</mat-icon>
              </a>
              <a
                *ngIf="deleteTransaction"
                type="button"
                mat-icon-button
                color="warn"
                matTooltip="Delete"
                (click)="deleteDocument.emit(row)"
              >
                <mat-icon>delete</mat-icon>
              </a>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              [ngStyle]="{
                'min-width.px': 80
              }"
              [ngClass]="{
                'hide-column': screen.device === 'handset' && screen.portrait
              }"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
              selected: selectedDocument && selectedDocument.id === row.id
            }"
          >
          </mat-row>
          <mat-footer-row
            *matFooterRowDef="displayedColumns"
            [ngClass]="{
              'hide-column': screen.device === 'handset' && screen.portrait
            }"
          ></mat-footer-row>
        </mat-table>
        <mat-paginator
          showFirstLastButtons="true"
          [pageSizeOptions]="[2]"
          [pageSize]="2"
        ></mat-paginator>
      </mat-card-content>

      <mat-menu #menuTransaction="matMenu">
        <button
          *ngIf="!billDocumentExists"
          mat-menu-item
          (click)="onAddDocument('transactionBill')"
        >
          <mat-icon>local_atm</mat-icon>
          <span>Upload Bill PDF</span>
        </button>

        <button
          *ngIf="!noteDocumentExists"
          mat-menu-item
          (click)="onAddDocument('transactionNote')"
        >
          <mat-icon>payment</mat-icon>
          <span>Upload Note PDF</span>
        </button>
      </mat-menu>
    </mat-card>
  </form>
</div>

<button
  *ngIf="isEdit"
  mat-stroked-button
  type="button"
  color="primary"
  (click)="update.emit(transactionForm.value)"
  routerLink="/clients"
  [disabled]="!transactionForm.valid"
>
  SAVE
</button>
<button
  *ngIf="!isDetails"
  mat-stroked-button
  type="button"
  (click)="cancel.emit()"
>
  CANCEL
</button>
<button
  *ngIf="isDetails"
  mat-stroked-button
  type="button"
  (click)="cancel.emit()"
>
  BACK
</button>

<!-- <br />Client ID: {{ clientId }} <br />Transaction ID: {{ transactionId }} -->
<!-- <h2>Documents</h2> -->
<!-- <pre>{{ transactionDocuments | json }}</pre> -->

<!-- <h2>Transaction Form</h2> -->
<!-- Dirty:{{ transactionForm.dirty }} Valid:{{ transactionForm.valid }} -->
<!-- <pre>{{ transactionForm.value | json }}</pre> -->

<!-- <h2>Transaction</h2> -->
<!-- <pre>{{ transaction | json }}</pre> -->

<!-- <h2>Bill Documents</h2> -->
<!-- Docs: <pre>{{ transactionDocuments | json }}</pre> -->
<!-- Bill: <pre>{{ billDocumentExists | json }}</pre> <br /> -->
<!-- Note: <pre>{{ noteDocumentExists | json }}</pre> <br /> -->
