import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthFacade } from '../../+state';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
})
export class RequestPasswordResetComponent {
  constructor(public auth: AuthFacade) {}

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
  });

  get username() {
    return this.form.get('username').value;
  }

  onResetPassword(username: string) {
    this.auth.requestPasswordReset(username);
  }
}
