import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from './router/router.module';
import { StoreModule } from '@ngrx/store';
import { reducers, AppFacade } from './+state';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AppEffects } from './+state/app.effects';


import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { PreferencesModule } from './preferences/preferences.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { GraphQLModule } from './graphql/graphql.module';
import { environment } from 'src/environments/environment';
import {ProfileModule} from '@profile/profile.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
      name: environment.production
        ? 'ShareScape'
        : `${environment.environment} ShareScape`,
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot(),
    AuthModule,
    CoreModule,
    ConfigurationModule,
    PreferencesModule,
    RouterModule.forRoot(),
    GraphQLModule,
    ProfileModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private app: AppFacade) {
    this.app.setEnvironment(environment.environment);
  }
}
