<div class="container">
  <mat-card>
    <mat-card-content>
      <div class="title">
        <img src="../../../../favicon.ico" width="35px" height="35px"/>
        <span>
        ShareScape
        </span>
      </div>
      <div class="content">
        You should be receiving an email with a one-time code shortly!
      </div>
      <div class="footer">
        <button type="submit" mat-flat-button
                color="primary" (click)="done()">
          Done
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
