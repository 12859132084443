import { RouterStateSerializer } from '@ngrx/router-store';
import {
  RouterStateSnapshot,
  Params,
  ActivatedRouteSnapshot,
  NavigationExtras
} from '@angular/router';

export interface RouterUrl {
  path: any[];
  query?: object;
  extras?: NavigationExtras;
}

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

/** Create a custom serializer that will grab specified fields
 *  from the Router tree to persist them in the Store.
 */
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    // Get the Params off routerState.root
    let route: ActivatedRouteSnapshot = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    const { params } = route;

    const { url } = routerState;
    const { queryParams } = routerState.root;

    return { url, params, queryParams };
  }
}
