import {
  Component,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnChanges,
  ChangeDetectionStrategy,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Screen } from '@core/models';
import { ConstantPool } from '@angular/compiler';

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionListComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  // Actions
  @Input() editTransaction = false;
  @Input() detailsTransaction = false;
  @Input() deleteTransaction = false;
  @Input() downloadFile = false;

  @Input() screen: Screen;
  @Input() displayedColumns: Array<string>;
  @Input() transactions: Array<any>;
  @Input() pageSize: number;
  @Input() transactionsListTableSort: Sort = null;
  @Input() selectedTransaction: any;
  @Input() selectedFiles: Set<string>;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() details: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
  @Output() download: EventEmitter<any> = new EventEmitter();
  @Output() stream: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$: Subject<boolean> = new Subject<boolean>();

  dataSource = new MatTableDataSource<any>();
  selection: SelectionModel<string> = new SelectionModel<string>(true, []);

  balance = 0;
  filesCount = 0;

  ngOnChanges() {
    if (this.transactions) {
      // Manufacture amount so the template doesn't have to calculate them.
      const modifiedTransactions = this.transactions.map((t) => ({
        ...t,
        amount: this.displayAmount(t),
      }));
      // Calculate the client balance every time the array of transactions
      // changes.
      this.balance = this.transactions.reduce(
        (acc, tx) =>
          tx.__typename === 'transaction_bills'
            ? acc + tx.bill
            : acc - tx.payment,
        0
      );

      this.dataSource.data = modifiedTransactions;
    }

    if (this.selectedFiles.size > 0) {
      this.selectedFiles.forEach((file) => this.selection.select(file));
    } else {
      this.selection.clear();
    }
  }

  ngAfterViewInit() {
    // Configure sortingDataAccessor because
    this.dataSource.sortingDataAccessor = (row, property) => {
      switch (property) {
        case 'dateOfService': {
          return new Date(row.date_of_service);
        }
        case 'datePosted': {
          return new Date(row.date_posted);
        }
        case 'amount': {
          return this.displayAmount(row);
        }
        default:
          return row[property];
      }
    };

    this.dataSource.paginator = this.paginator;
    this.paginator.page
      .pipe(
        takeUntil(this.destroy$),
        map((val) => {
          if (val.pageSize !== this.pageSize) {
            this.pageSizeChanged.emit(val.pageSize);
          }
        })
      )
      .subscribe();
    this.dataSource.sort = this.sort;
  }

  getTransactionDocs(tx: any): any {
    return tx &&
      tx.transaction_bill_documents &&
      tx.transaction_bill_documents.length > 0
      ? tx.transaction_bill_documents
      : [];
  }

  documentIcon(tx: any): string {
    const files = this.getTransactionDocs(tx);
    if (files.length > 0) {
      return (
        'filter_' + (files.length <= 9 ? files.length.toString() : '9_plus')
      );
    } else {
      return null;
    }
  }

  getFileId(tx: any, txType: string): string {
    return this.getTransactionDocs(tx)
      .filter((d) => d.type === txType)
      .map((file: any) =>
        file && file.document && file.document.id ? file.document.id : null
      );
  }

  getDocument(tx: any, txType: string): any {
    let txDocument = null;
    this.getTransactionDocs(tx).map((doc: any) => {
      if (doc && doc.type === txType) {
        txDocument = doc.document;
      }
    });
    return txDocument;
  }

  getDownloadCount(tx: any): any {
    return this.getTransactionDocs(tx).map((file: any) =>
      file &&
      file.document &&
      file.document.download_histories_aggregate &&
      file.document.download_histories_aggregate.aggregate &&
      file.document.download_histories_aggregate.aggregate.count
        ? file.document.download_histories_aggregate.aggregate.count
        : 0
    );
  }

  getDownloadBadge(tx): string {
    const counts = this.getDownloadCount(tx);
    const count = counts.reduce((acc, value) => acc + value, 0);
    return count > 0 ? counts : null;
  }

  getDownloadCountToolTip(tx: any): string {
    let rc = '';
    const files = this.getTransactionDocs(tx);
    files.map((file) => {
      switch (file.type) {
        case 'transactionBill':
          rc =
            rc +
            `Bill downloaded ${file.document.download_histories_aggregate.aggregate.count} times `;
          break;
        case 'transactionNote':
          rc =
            rc +
            `Note downloaded ${file.document.download_histories_aggregate.aggregate.count} times `;
          break;
      }
    });
    return rc;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  allDocumentsTotal(): number {
    let totalDocuments = 0;
    this.dataSource.data.forEach((row) => {
      if (
        row &&
        row.transaction_bill_documents &&
        row.transaction_bill_documents.length > 0
      ) {
        totalDocuments = totalDocuments + row.transaction_bill_documents.length;
      }
    });
    return totalDocuments;
  }

  areAllSelected() {
    return this.allDocumentsTotal() === this.selection.selected.length;
  }

  isAnySelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  masterToggle() {
    if (this.areAllSelected()) {
      this.dataSource.data.forEach((row) => {
        if (row.transaction_bill_documents) {
          row.transaction_bill_documents.map((d: any) =>
            this.deSelectId(d.document.id)
          );
        }
      });
    } else {
      this.dataSource.data.forEach((row) => {
        if (row.transaction_bill_documents) {
          row.transaction_bill_documents.map((d: any) =>
            this.selectId(d.document.id)
          );
        }
      });
    }
  }

  toggleBoth(row: any) {
    const both = this.areBothSelected(row);
    if (row && row.transaction_bill_documents) {
      row.transaction_bill_documents.map((d: any) => {
        if (both) {
          this.deSelectId(d.document.id);
        } else {
          this.selectId(d.document.id);
        }
      });
    }
  }

  toggleItem(row: any, txType: string) {
    if (row && row.transaction_bill_documents) {
      row.transaction_bill_documents
        .filter((t) => t.type === txType)
        .map((d: any) => {
          if (this.selectedFiles.has(d.document.id)) {
            this.deSelectId(d.document.id);
          } else {
            this.selectId(d.document.id);
          }
        });
    }
  }

  areBothSelected(row): boolean {
    if (
      row &&
      row.transaction_bill_documents &&
      row.transaction_bill_documents.length > 0
    ) {
      let rc = true;
      row.transaction_bill_documents.map((d: any) => {
        if (!this.selectedFiles.has(d.document.id)) {
          rc = false;
        }
      });
      return rc;
    }
    return false;
  }

  // Check if only one of the two row checkboxes are checked.
  // Note: handle situation where only one checkbox exists.
  isOneSelected(row: any): boolean {
    let rc = false;
    let hasBill = false;
    let billSelected = false;
    let hasNote = false;
    let noteSelected = false;
    if (
      row &&
      row.transaction_bill_documents &&
      row.transaction_bill_documents.length > 0
    ) {
      row.transaction_bill_documents.map((d: any) => {
        if (d.type === 'transactionBill') {
          hasBill = true;
          if (this.selectedFiles.has(d.document.id)) {
            billSelected = true;
          }
        } else if (d.type === 'transactionNote') {
          hasNote = true;
          if (this.selectedFiles.has(d.document.id)) {
            noteSelected = true;
          }
        }
      });
    }

    if (hasBill && hasNote) {
      rc = (billSelected && !noteSelected) || (!billSelected && noteSelected);
    }
    return rc;
  }

  isSelected(row: any, txType: string): boolean {
    const doc = this.getDocument(row, txType);
    return this.selectedFiles.has(doc.id);
  }

  selectId(id: string) {
    this.selection.select(id);
    this.selectedFiles.add(id);
  }

  deSelectId(id: string) {
    this.selection.deselect(id);
    this.selectedFiles.delete(id);
  }

  displayAmount(transaction: any) {
    if (transaction.bill > 0) {
      return transaction.bill;
    } else if (transaction.payment > 0) {
      return -transaction.payment;
    } else {
      return 0;
    }
  }

  getDownloadTooltip(row: any): string {
    const bill =
      row &&
      row.transaction_bill_documents.find(
        (d: any) => d.document.type === 'transactionBill'
      );
    const note =
      row &&
      row.transaction_bill_documents.find(
        (d: any) => d.document.type === 'transactionNote'
      );
    if (bill && note) {
      return 'Download Bill & Note';
    } else if (bill) {
      return 'Download Bill';
    } else if (note) {
      return 'Download Note';
    } else {
      return null;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
