import {
  Component,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Screen } from '@core/models';

@Component({
  selector: 'app-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsListComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

  destroy$: Subject<boolean> = new Subject<boolean>();

  // Actions
  @Input() createOrganization = false;
  @Input() editOrganization = false;
  @Input() detailsOrganization = false;
  @Input() deleteOrganization = false;

  @Input() screen: Screen;
  @Input() displayedColumns = [];
  @Input() organizations: any[] = [];
  @Input() selectedOrganization: any = null;
  @Input() searchStr: string;
  @Input() pageSize: number;
  @Input() organizationsListTableSort: Sort = null;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() details: EventEmitter<any> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() setSearch: EventEmitter<string> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
  @Output() tableSort: EventEmitter<Sort> = new EventEmitter();

  dataSource = new MatTableDataSource<any>();
  searchControl: FormControl = new FormControl();

  ngOnChanges() {
    if (this.organizations) {
      this.dataSource.data = this.organizations;
    }

    if (this.searchStr) {
      this.searchControl.setValue(this.searchStr);
      this.applyFilter(this.searchStr);
    }
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'phoneNumber':
          return item.phone_numbers && item.phone_numbers.length > 0
            ? item.phone_numbers[0].phone_number.number
            : null;
        default:
          return item[property];
      }
    };

    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.sortChange
        .pipe(
          takeUntil(this.destroy$),
          map((sortInformation) => this.tableSort.emit(sortInformation))
        )
        .subscribe();
    }
    this.dataSource.paginator = this.paginator;
    this.paginator.page
      .pipe(
        takeUntil(this.destroy$),
        map((val) => {
          if (val.pageSize !== this.pageSize) {
            this.pageSizeChanged.emit(val.pageSize);
          }
        })
      )
      .subscribe();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.setSearch.emit(filterValue);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
