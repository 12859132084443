import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { USER_FRAGMENT } from '@users/fragments';
import { ORGANIZATION_FRAGMENT } from '@organizations/fragments';
import { PHONE_NUMBER_FRAGMENT } from '@phone-numbers/fragments';
/**
 * Queries
 */
export const getProfile = gql`
  query getProfile($id: uuid!) {
    users_by_pk(id: $id) {
      ...userFragment
      organization_users {
        organization {
          ...organizationFragment
        }
      }
      user_phone_numbers {
        phone_number {
          ...phoneNumberFragment
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${ORGANIZATION_FRAGMENT}
  ${PHONE_NUMBER_FRAGMENT}
`;

/**
 * Mutations
 */
export const updateProfile = gql`
  mutation updateProfile($id: uuid!, $changes: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $changes) {
      id
      first_name
      last_name
    }
  }
`;
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apollo: Apollo) {}

  getProfile(id: string): Observable<any> {
    return this.apollo
      .query({
        query: getProfile,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        pluck('data', 'users_by_pk')
        // tap((response: any) => console.log(response))
      );
  }

  update(input: any): Observable<any> {
    // Manufacture update mutation object
    // const changes = {
    //   first_name: input.first_name,
    //   last_name: input.last_name,
    // };
    return this.apollo.mutate({
      mutation: updateProfile,
      variables: { id: input.id, changes: input },
    });
    // .pipe(tap((data) => console.log(`Mutation Update: `, data)));
  }
}
