<mat-vertical-stepper #stepper *ngIf="theme">
  <mat-step [stepControl]="documentFormGroup">
    <form [formGroup]="documentFormGroup">
      <ng-template matStepLabel>Choose document type</ng-template>
      <div class="form-group">
        <mat-form-field class="item">
          <mat-select
            placeholder="Document Type"
            formControlName="type"
            (selectionChange)="setTypeName($event)"
            required
          >
            <mat-option *ngFor="let docType of docTypes" [value]="docType.id">{{
              docType.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <button mat-button color="primary" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="fileFormGroup">
    <form [formGroup]="fileFormGroup">
      <ng-template matStepLabel>Select Document</ng-template>
      <mat-form-field *ngIf="docFile" class="item-filename">
        <span matPrefix><mat-icon>picture_as_pdf</mat-icon></span>
        <span matSuffix
          ><mat-icon class="clear-button" (click)="clearDocumentFile()"
            >clear</mat-icon
          ></span
        >
        <input
          matInput
          placeholder="File Name"
          [value]="getDocumentFileName()"
        />
      </mat-form-field>

      <section *ngIf="!docFile" class="mat-typography margin-top">
        <div class="dropzone">
          <app-drop-zone
            dropZoneTextSize="100%"
            [allowMultipleFiles]="false"
            [allowedFileTypes]="['pdf']"
            [dragEnterColor]="theme.accent"
            [dragEnterTextColor]="theme.primaryText"
            [dragLeaveColor]="theme.primary"
            [dragLeaveTextColor]="theme.accentText"
            (validFiles)="onFilesValid($event)"
            (invalidFiles)="onFilesInvalid($event)"
          >
          </app-drop-zone>
        </div>
      </section>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button color="primary" matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <p>Upload Document</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button color="warn" (click)="stepper.reset()">Reset</button>
      <button
        mat-button
        color="primary"
        matStepperPrevious
        (click)="done()"
        [disabled]="documentFormGroup.invalid || fileFormGroup.invalid"
      >
        Upload
      </button>
    </div>
  </mat-step>
</mat-vertical-stepper>
<button mat-stroked-button type="button" (click)="cancel.emit()">CANCEL</button>

<!-- <br />Client ID: {{ clientId }} <br /> -->
<!-- <h2>Document Form</h2> -->
<!-- <pre>{{ documentFormGroup.value | json }}</pre> -->
<!-- <h2>File Form</h2> -->
<!-- <pre>{{ fileFormGroup.value | json }}</pre> -->
