import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Theme } from '@core/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoggerService } from '@core/services';
import { MatSelectChange } from '@angular/material/select';
import { ConfigurationService } from '@configuration/services/configuration.service';

@Component({
  selector: 'app-document-new',
  templateUrl: './document-new.component.html',
  styleUrls: ['./document-new.component.scss'],
})
export class DocumentNewComponent implements OnInit {
  @Input() clientId: string;
  @Input() theme: Theme;
  @Input() docTypes: any;

  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  documentFormGroup: FormGroup;
  fileFormGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private configurationService: ConfigurationService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.documentFormGroup = this.fb.group({
      type: ['', Validators.required],
      type_name: '',
    });
    this.fileFormGroup = this.fb.group({
      file: ['', Validators.required],
    });
  }

  get docFile() {
    return this.fileFormGroup.get('file').value;
  }

  get docType() {
    return this.documentFormGroup.get('type').value;
  }

  get docTypeName() {
    return this.documentFormGroup.get('type_name').value;
  }

  getDocumentFileName(): string {
    return this.docFile && this.docFile.name;
  }

  clearDocumentFile() {
    this.fileFormGroup.get('file').setValue(null);
  }

  onFilesValid(files: File[]) {
    if (files.length === 1) {
      this.fileFormGroup.get('file').setValue(files[0]);
    }
  }

  onFilesInvalid(files: File[]) {
    if (files.length > 0) {
      const types = new Set();
      const info = files.map((file) => {
        types.add(
          file.type.length > 0 ? file.type.toLocaleUpperCase() : 'UNKNOWN'
        );
        return {
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
        };
      });
      const message = `Invalid file ${
        Array.from(types).length === 1 ? 'type' : 'types'
      }: ${Array.from(types).join(', ')}.`;
      this.logger.error(message, info);
    }
  }

  done() {
    const payload = {
      file: this.docFile,
      clientId: this.clientId,
      documentType: this.docType,
      documentTypeName: this.docTypeName,
    };
    this.create.emit(payload);
  }

  /**
   * Keep the type and type_name fields in sync
   *
   * @param changedValue: the id of the type
   */
  setTypeName(changedValue: MatSelectChange) {
    this.documentFormGroup
      .get('type_name')
      .setValue(
        this.configurationService.getTypeName(changedValue.value, this.docTypes)
      );
  }
}
