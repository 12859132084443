import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eula-verbiage',
  templateUrl: './eula-verbiage.component.html',
  styleUrls: ['./eula-verbiage.component.scss'],
})
export class EulaVerbiageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
