import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Theme } from '@core/models';
import { MatSelectChange } from '@angular/material/select';
import { ConfigurationService } from '@configuration/services/configuration.service';

@Component({
  selector: 'app-document-details-ui',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentDetailsComponent implements OnChanges {
  @Input() deleteDocument = false;
  @Input() downloadFile = false;
  @Input() document: any;
  @Input() clientId: string;
  @Input() selectedDocument: any;
  @Input() docTypes: Array<any>;
  @Input() theme: Theme;
  @Input() allowedFileTypes = ['pdf'];
  @Input() isDetails: boolean;
  @Input() isEdit: boolean;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<Document> = new EventEmitter();
  @Output() download: EventEmitter<Document> = new EventEmitter();
  @Output() stream: EventEmitter<Document> = new EventEmitter();

  documentForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private configurationService: ConfigurationService
  ) {
    this.createForm();
  }

  ngOnChanges() {
    if (this.document) {
      this.documentForm.patchValue(this.document);
    }
  }

  createForm() {
    this.documentForm = this.fb.group({
      id: '',
      display_name: ['', Validators.required],
      type: ['', Validators.required],
      type_name: '',
      file_size: '',
      file_type: '',
    });
  }

  get documentType() {
    return this.documentForm.get('type').value;
  }

  get fileSize() {
    return this.documentForm.get('file_size').value;
  }

  get fileType() {
    return this.documentForm.get('file_type').value;
  }

  /**
   * Keep the type and type_name fields in sync
   *
   * @param changedValue: the id of the type
   */
  setTypeName(changedValue: MatSelectChange) {
    this.documentForm
      .get('type_name')
      .setValue(
        this.configurationService.getTypeName(changedValue.value, this.docTypes)
      );
  }
}
