import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackBarInfo } from '../../models';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  getConfig(snackBar: SnackBarInfo): MatSnackBarConfig {
    const horizontal: MatSnackBarHorizontalPosition = snackBar.horizontalPosition
      ? (snackBar.horizontalPosition as MatSnackBarHorizontalPosition)
      : 'left';
    const vertical: MatSnackBarVerticalPosition = snackBar.verticalPosition
      ? (snackBar.verticalPosition as MatSnackBarVerticalPosition)
      : 'bottom';
    const config: MatSnackBarConfig = {
      duration: snackBar.duration,
      panelClass: snackBar.style,
      horizontalPosition: horizontal,
      verticalPosition: vertical,
      data: {
        message: snackBar.message,
      },
    };
    return config;
  }

  showSnackBar(snackBar: SnackBarInfo) {
    this.snackBar.open(
      snackBar.message,
      snackBar.action,
      this.getConfig(snackBar)
    );
  }
}
