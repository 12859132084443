<form [formGroup]="addressForm">
  <mat-form-field class="item-street">
    <input
      matInput
      placeholder="Address 1"
      formControlName="street_address_1"
      [readonly]="isReadOnly"
      required
    />
    <mat-error
      *ngIf="
        !addressForm.get('street_address_1').valid &&
        addressForm.get('street_address_1').touched
      "
      >Street Address is required.</mat-error
    >
  </mat-form-field>
  <mat-form-field class="item-street">
    <input
      matInput
      placeholder="Address 2"
      formControlName="street_address_2"
      [readonly]="isReadOnly"
    />
  </mat-form-field>
  <mat-form-field class="item-city">
    <input
      matInput
      placeholder="City"
      formControlName="city"
      [readonly]="isReadOnly"
      required
    />
    <mat-error
      *ngIf="!addressForm.get('city').valid && addressForm.get('city').touched"
      >City is required.</mat-error
    >
  </mat-form-field>
  <mat-form-field class="item-state">
    <mat-select placeholder="State" formControlName="state" required>
      <mat-option
        *ngFor="let state of states"
        [value]="state.value"
        [disabled]="isReadOnly"
        >{{ state.description }}</mat-option
      >
    </mat-select>
    <mat-error
      *ngIf="
        !addressForm.get('state').valid && addressForm.get('state').touched
      "
      >State is required.</mat-error
    >
  </mat-form-field>
  <mat-form-field class="item-zip">
    <input
      matInput
      required
      placeholder="Zip Code"
      formControlName="zip"
      [readonly]="isReadOnly"
    />
    <mat-error
      *ngIf="!addressForm.get('zip').valid && addressForm.get('zip').touched"
      >Zip code is required.</mat-error
    >
  </mat-form-field>
</form>
