import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import * as fromActions from './profile.actions';
import * as fromSelectors from './profile.selectors';

@Injectable({ providedIn: 'root' })
export class ProfileFacade {
  profile$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectProfile)
  );

  constructor(private store: Store<any>) {}

  loadProfile() {
    this.store.dispatch(fromActions.loadProfile());
  }

  updateProfile(input: any) {
    this.store.dispatch(fromActions.updateProfile({ input }));
  }
}
