import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneFormComponent {
  @Input() isReadOnly: boolean;
  @Input() phoneNumbersForm: FormGroup;
  @Input() phoneTypes: any;
}
