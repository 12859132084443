import { ActionReducerMap } from '@ngrx/store';
import * as fromReducer from './app.reducer';

export * from './app.facade';

export interface State {
  app: fromReducer.State;
}

export const reducers: ActionReducerMap<State> = {
  app: fromReducer.reducer,
};
