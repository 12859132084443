import { Injectable, OnDestroy } from '@angular/core';
import {Subject, Observable, from, combineLatest} from 'rxjs';
import {takeUntil, tap, map, withLatestFrom} from 'rxjs/operators';
import { AppFacade } from '@app/+state';
import { AuthFacade } from '@auth/+state';
import { RouterFacade } from '@router/+state';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardProviderService
  implements CanActivate, CanActivateChild, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private auth: AuthFacade,
    private router: RouterFacade,
    private app: AppFacade
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([this.auth.idToken$, this.auth.roles$]).pipe(
      takeUntil(this.destroy$),
      tap(([token, groups]) => {
        if (token) {
          if (groups.length > 0){
            const roles = [...groups];
            let provider = '';
            if (roles.includes('provider_admin')) {
              provider = 'provider_admin';
            }
            if (roles.includes('user_provider')) {
              provider = 'user_provider';
            }
            if (roles.includes('site_admin')) {
              provider = 'user_provider';
            }
            if (provider === '') {
              this.router.go({path: ['page-not-found']});
            }
          }
        } else {
          // Not logged in
          this.app.setRedirectURL(state.url);
          this.router.go({ path: ['login'] });
        }
      }),
      map((user) => !!user)
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([this.auth.idToken$, this.auth.roles$]).pipe(
      takeUntil(this.destroy$),
      tap(([token, groups]) => {
        if (token) {
          if (groups.length > 0){
            const roles = [...groups];
            let provider = '';
            if (roles.includes('provider_admin')) {
              provider = 'provider_admin';
            }
            if (roles.includes('user_provider')) {
              provider = 'user_provider';
            }
            if (roles.includes('site_admin')) {
              provider = 'site_admin';
            }
            if (provider === '') {
              this.router.go({path: ['page-not-found']});
            }
          }
        } else {
          // Not logged in
          this.app.setRedirectURL(state.url);
          this.router.go({ path: ['login'] });
        }
      }),
      map((user) => !!user)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
